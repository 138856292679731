import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.function.name";
import "core-js/modules/es6.array.index-of";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.function.name";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.function.name";

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React from 'react';
import { linearScale } from 'styled-system-scale';
import { getMetadata, getRichText, notEmpty, propPairsEq } from 'helpers';
import { useProjectCategoriesData } from 'src/hooks';
import { Box, Grid, Text, ImageContainer, Image, AspectRatio } from 'system';
import { BoundedBox, CaptionImage, CategoryBreadCrumb, HTMLContent, Heading, Subheading } from 'src/components';

var MetadataItem = function MetadataItem(_ref) {
  var name = _ref.name,
      textHTML = _ref.textHTML,
      props = _objectWithoutPropertiesLoose(_ref, ["name", "textHTML"]);

  return React.createElement(Box, props, name && React.createElement(Subheading, {
    as: "dt",
    color: "gray.70",
    mbScale: [0, null, 't-']
  }, name), textHTML && React.createElement(HTMLContent, {
    as: "dd",
    html: textHTML
  }));
};

export var PageBodyProjectIntro = function PageBodyProjectIntro(_ref2) {
  var _projectCategory$data, _projectCategory$data2;

  var categoryUID = _ref2.categoryUID,
      name = _ref2.name,
      description = _ref2.description,
      parentName = _ref2.parentName,
      parentHref = _ref2.parentHref,
      featuredImageFluid = _ref2.featuredImageFluid,
      featuredImageURL = _ref2.featuredImageURL,
      featuredImageAlt = _ref2.featuredImageAlt,
      introImageFluid = _ref2.introImageFluid,
      introImageURL = _ref2.introImageURL,
      introImageAlt = _ref2.introImageAlt,
      introImageCaptionHTML = _ref2.introImageCaptionHTML,
      locationHTML = _ref2.locationHTML,
      servicesHTML = _ref2.servicesHTML,
      awardsHTML = _ref2.awardsHTML,
      props = _objectWithoutPropertiesLoose(_ref2, ["categoryUID", "name", "description", "parentName", "parentHref", "featuredImageFluid", "featuredImageURL", "featuredImageAlt", "introImageFluid", "introImageURL", "introImageAlt", "introImageCaptionHTML", "locationHTML", "servicesHTML", "awardsHTML"]);

  var projectCategory = useProjectCategoriesData(categoryUID);
  var projectCategoryColor = (projectCategory === null || projectCategory === void 0 ? void 0 : projectCategory.color) || 'gray.70';
  return React.createElement(BoundedBox, Object.assign({
    as: "section",
    pt: 0
  }, props), React.createElement(ImageContainer, {
    mbScale: ['l+', null, null, 0],
    mxScale: "-xl",
    width: "auto",
    overflow: "hidden",
    bg: "gray.10"
  }, React.createElement(AspectRatio, {
    x: 1,
    y: 1,
    height: "80vh",
    maxHeight: linearScale('30rem', '70rem', {
      count: 5
    })
  }, (featuredImageFluid || featuredImageURL) && React.createElement(Image, {
    fluid: featuredImageFluid,
    src: featuredImageURL,
    alt: featuredImageAlt
  }))), React.createElement(Grid, {
    gridTemplateColumns: ['1fr', null, 'repeat(8, 1fr)'],
    gridColumnGapScale: "l"
  }, projectCategory && React.createElement(React.Fragment, null, React.createElement(Box, {
    display: ['none', null, null, 'block'],
    bg: projectCategoryColor,
    height: "45px",
    gridColumn: "1 / span 1",
    mlScale: "-xl"
  }), React.createElement(Box, {
    display: ['none', null, null, 'block'],
    gridColumn: "2 / -1"
  }), React.createElement(Box, {
    borderLeft: ['20px solid', '27.5px solid', '35px solid', 0],
    borderLeftColor: [projectCategoryColor, projectCategoryColor, projectCategoryColor],
    mlScale: ['-xl', null, null, 0],
    plScale: ['m', null, null, 0],
    gridColumn: [null, null, '1 / -1', '2 / -1'],
    mbScale: "xxl"
  }, React.createElement(CategoryBreadCrumb, {
    categoryName: projectCategory === null || projectCategory === void 0 ? void 0 : (_projectCategory$data = projectCategory.data) === null || _projectCategory$data === void 0 ? void 0 : (_projectCategory$data2 = _projectCategory$data.title) === null || _projectCategory$data2 === void 0 ? void 0 : _projectCategory$data2.text,
    categoryHref: projectCategory === null || projectCategory === void 0 ? void 0 : projectCategory.url,
    parentName: parentName,
    parentHref: parentHref,
    color: projectCategoryColor
  }))), name && React.createElement(Heading, {
    mbScale: "m",
    gridColumn: [null, null, '1 / -2', '2 / span 5']
  }, name), description && React.createElement(Text, {
    as: "p",
    fontSizeScale: "m",
    fontWeight: "light",
    mbScale: "xxl",
    gridColumn: [null, null, '1 / -2', '2 / span 5']
  }, description), (introImageFluid || introImageURL) && React.createElement(CaptionImage, {
    fluid: introImageFluid,
    src: introImageURL,
    alt: introImageAlt,
    captionHTML: introImageCaptionHTML,
    mbScale: ['xxl', null, 0],
    gridColumn: [null, null, '1 / span 6', '2 / span 5']
  }), React.createElement(Grid, {
    as: "dl",
    gridRowGapScale: "m",
    alignSelf: "start",
    gridColumn: [null, null, '7 / -1', '7 / -1']
  }, locationHTML && React.createElement(MetadataItem, {
    name: "Location",
    textHTML: locationHTML
  }), servicesHTML && React.createElement(MetadataItem, {
    name: "Services",
    textHTML: servicesHTML
  }), awardsHTML && React.createElement(MetadataItem, {
    name: "Awards",
    textHTML: awardsHTML
  }))));
};

PageBodyProjectIntro.mapDataToProps = function (_ref3) {
  var _metadata$items, _metadata$items$, _metadata$items$$proj, _metadata$items$$proj2, _page$data, _page$data$title, _metadata$primary, _metadata$primary$des, _page$data2, _page$data2$parent, _page$data2$parent$do, _page$data2$parent$do2, _page$data2$parent$do3, _page$data3, _page$data3$parent, _page$data3$parent$do, _metadata$primary2, _metadata$primary2$fe, _metadata$primary3, _metadata$primary3$fe, _metadata$primary4, _metadata$primary4$fe, _metadata$primary5, _metadata$primary5$in, _metadata$primary6, _metadata$primary6$in, _metadata$primary7, _metadata$primary7$in, _metadata$primary8, _metadata$primary9, _metadata$primary10, _metadata$primary11;

  var meta = _ref3.meta,
      context = _ref3.context,
      nextContext = _ref3.nextContext;
  var page = meta === null || meta === void 0 ? void 0 : meta.page;
  var metadata = getMetadata('PrismicPageMainProject', page);
  return {
    nextSharesBg: propPairsEq('bg', context, nextContext),
    categoryUID: metadata === null || metadata === void 0 ? void 0 : (_metadata$items = metadata.items) === null || _metadata$items === void 0 ? void 0 : (_metadata$items$ = _metadata$items[0]) === null || _metadata$items$ === void 0 ? void 0 : (_metadata$items$$proj = _metadata$items$.project_category) === null || _metadata$items$$proj === void 0 ? void 0 : (_metadata$items$$proj2 = _metadata$items$$proj.document) === null || _metadata$items$$proj2 === void 0 ? void 0 : _metadata$items$$proj2.uid,
    name: page === null || page === void 0 ? void 0 : (_page$data = page.data) === null || _page$data === void 0 ? void 0 : (_page$data$title = _page$data.title) === null || _page$data$title === void 0 ? void 0 : _page$data$title.text,
    description: metadata === null || metadata === void 0 ? void 0 : (_metadata$primary = metadata.primary) === null || _metadata$primary === void 0 ? void 0 : (_metadata$primary$des = _metadata$primary.description) === null || _metadata$primary$des === void 0 ? void 0 : _metadata$primary$des.text,
    parentName: page === null || page === void 0 ? void 0 : (_page$data2 = page.data) === null || _page$data2 === void 0 ? void 0 : (_page$data2$parent = _page$data2.parent) === null || _page$data2$parent === void 0 ? void 0 : (_page$data2$parent$do = _page$data2$parent.document) === null || _page$data2$parent$do === void 0 ? void 0 : (_page$data2$parent$do2 = _page$data2$parent$do.data) === null || _page$data2$parent$do2 === void 0 ? void 0 : (_page$data2$parent$do3 = _page$data2$parent$do2.title) === null || _page$data2$parent$do3 === void 0 ? void 0 : _page$data2$parent$do3.text,
    parentHref: page === null || page === void 0 ? void 0 : (_page$data3 = page.data) === null || _page$data3 === void 0 ? void 0 : (_page$data3$parent = _page$data3.parent) === null || _page$data3$parent === void 0 ? void 0 : (_page$data3$parent$do = _page$data3$parent.document) === null || _page$data3$parent$do === void 0 ? void 0 : _page$data3$parent$do.url,
    featuredImageFluid: metadata === null || metadata === void 0 ? void 0 : (_metadata$primary2 = metadata.primary) === null || _metadata$primary2 === void 0 ? void 0 : (_metadata$primary2$fe = _metadata$primary2.featured_image) === null || _metadata$primary2$fe === void 0 ? void 0 : _metadata$primary2$fe.fluid,
    featuredImageURL: metadata === null || metadata === void 0 ? void 0 : (_metadata$primary3 = metadata.primary) === null || _metadata$primary3 === void 0 ? void 0 : (_metadata$primary3$fe = _metadata$primary3.featured_image) === null || _metadata$primary3$fe === void 0 ? void 0 : _metadata$primary3$fe.url,
    featuredImageAlt: notEmpty(metadata === null || metadata === void 0 ? void 0 : (_metadata$primary4 = metadata.primary) === null || _metadata$primary4 === void 0 ? void 0 : (_metadata$primary4$fe = _metadata$primary4.featured_image) === null || _metadata$primary4$fe === void 0 ? void 0 : _metadata$primary4$fe.alt),
    introImageFluid: metadata === null || metadata === void 0 ? void 0 : (_metadata$primary5 = metadata.primary) === null || _metadata$primary5 === void 0 ? void 0 : (_metadata$primary5$in = _metadata$primary5.intro_image) === null || _metadata$primary5$in === void 0 ? void 0 : _metadata$primary5$in.fluid,
    introImageURL: metadata === null || metadata === void 0 ? void 0 : (_metadata$primary6 = metadata.primary) === null || _metadata$primary6 === void 0 ? void 0 : (_metadata$primary6$in = _metadata$primary6.intro_image) === null || _metadata$primary6$in === void 0 ? void 0 : _metadata$primary6$in.url,
    introImageAlt: notEmpty(metadata === null || metadata === void 0 ? void 0 : (_metadata$primary7 = metadata.primary) === null || _metadata$primary7 === void 0 ? void 0 : (_metadata$primary7$in = _metadata$primary7.intro_image) === null || _metadata$primary7$in === void 0 ? void 0 : _metadata$primary7$in.alt),
    introImageCaptionHTML: getRichText(metadata === null || metadata === void 0 ? void 0 : (_metadata$primary8 = metadata.primary) === null || _metadata$primary8 === void 0 ? void 0 : _metadata$primary8.intro_image_caption),
    locationHTML: getRichText(metadata === null || metadata === void 0 ? void 0 : (_metadata$primary9 = metadata.primary) === null || _metadata$primary9 === void 0 ? void 0 : _metadata$primary9.location),
    servicesHTML: getRichText(metadata === null || metadata === void 0 ? void 0 : (_metadata$primary10 = metadata.primary) === null || _metadata$primary10 === void 0 ? void 0 : _metadata$primary10.services),
    awardsHTML: getRichText(metadata === null || metadata === void 0 ? void 0 : (_metadata$primary11 = metadata.primary) === null || _metadata$primary11 === void 0 ? void 0 : _metadata$primary11.awards)
  };
};

PageBodyProjectIntro.mapDataToContext = function () {
  return {
    bg: 'white',
    pullsDownHeader: true
  };
}; // DOCS


PageBodyProjectIntro.docs = {
  name: 'Project Intro',
  description: 'Large project image and introductory text.',
  preset: true,
  examples: [{
    name: 'Example Intro',
    render: function render() {
      return React.createElement(PageBodyProjectIntro, {
        categoryUID: "residential",
        name: "Flower Avenue",
        description: "PVA was the design architect for this 3,300 SF, Scandinavian-inspired home that features an open floor plan with indoor/outdoor connective spaces. The house and the detached garage with a guest suite located above are configured to create a central courtyard space. The interior is modern and light with lots of windows, while the exterior is dark.",
        featuredImageURL: "https://pvadev.cdn.prismic.io/pvadev/541704ba04a3f58caa4a37dd325d793d351dbd1d_0-flower-avenue.jpg",
        introImageURL: "https://pvadev.cdn.prismic.io/pvadev/2bddb6c5563f967d8cbecec6c04d14ac98257324_1-flower-avenue.jpg",
        locationHTML: "<p>Venice, California</p>"
      });
    }
  }]
};
export var query = "201298491";