import "core-js/modules/es6.array.map";
import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.regexp.search";
import "core-js/modules/es6.array.index-of";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.regexp.search";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.regexp.search";
import _styled from "styled-components";

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import staticQueryData from "../../public/static/d/2367936974.json";
import React, { useRef, useMemo, useLayoutEffect } from 'react';
import { useFlexSearch } from 'react-use-flexsearch';
import { Grid, Text, Link } from 'system';
import { BoundedBox, ProjectCard, Heading, Button, Subheading, Anchor } from 'src/components';
import { useLoadMorePagination } from 'src/hooks';

var _StyledLink = _styled(Link).withConfig({
  displayName: "PageBodySearchResults___StyledLink",
  componentId: "sc-1bhh4ba-0"
})(["&:hover,&:focus{color:", ";}"], function (p) {
  return p.theme.colors.blue[40];
});

export var PageBodySearchResults = function PageBodySearchResults(_ref) {
  var query = _ref.query,
      props = _objectWithoutPropertiesLoose(_ref, ["query"]);

  var containerRef = useRef();
  var hasInitializedRef = useRef(false);
  var queryData = staticQueryData.data;
  var memoizedPageStore = useMemo(function () {
    return JSON.parse(queryData.localSearchPages.store);
  }, [queryData.localSearchPages.store]);
  var memoizedProjectStore = useMemo(function () {
    return JSON.parse(queryData.localSearchProjects.store);
  }, [queryData.localSearchProjects.store]);
  var pageResults = useFlexSearch(query, queryData.localSearchPages.index, memoizedPageStore);
  var projectResults = useFlexSearch(query, queryData.localSearchProjects.index, memoizedProjectStore);

  var _useLoadMorePaginatio = useLoadMorePagination({
    collection: projectResults,
    perPage: 8
  }),
      paginatedProjects = _useLoadMorePaginatio[0],
      nextProjectPage = _useLoadMorePaginatio[1],
      canLoadMoreProjects = _useLoadMorePaginatio[2];

  var _useLoadMorePaginatio2 = useLoadMorePagination({
    collection: pageResults,
    perPage: 8
  }),
      paginatedPages = _useLoadMorePaginatio2[0],
      nextPage = _useLoadMorePaginatio2[1],
      canLoadMorePages = _useLoadMorePaginatio2[2];

  var hasProjectResults = projectResults.length > 0;
  var hasPageResults = pageResults.length > 0;
  var totalResultsCount = projectResults.length + pageResults.length;
  var hasResults = totalResultsCount > 0;
  useLayoutEffect(function () {
    var container = containerRef.current;
    var hasInitialized = hasInitializedRef.current;
    var currPage = new URLSearchParams(location.search).get('page');
    if (!container || hasInitialized || !paginatedProjects.length || !currPage) return;
    hasInitializedRef.current = true;
    container.scrollIntoView({
      block: 'end'
    });
  }, [paginatedProjects]);
  if (!query) return React.createElement(BoundedBox, Object.assign({
    minHeight: "25vw"
  }, props), React.createElement(Heading, {
    fontSizeScale: "l+",
    mbScale: "t"
  }, "Enter a search term above."), React.createElement(Text, {
    as: "p"
  }, "Results will appear here."));
  if (!hasResults) return React.createElement(BoundedBox, Object.assign({
    minHeight: "25vw"
  }, props), React.createElement(Subheading, {
    mbScale: "m",
    color: "gray.70"
  }, "Showing ", totalResultsCount, " results for ", query), React.createElement(Heading, {
    fontSizeScale: "l+",
    mbScale: "t"
  }, "Sorry, no results matched your search terms."), React.createElement(Text, {
    as: "p"
  }, "Try a different query or ", React.createElement(Anchor, {
    href: "/"
  }, "go to the home page"), "."));
  return React.createElement(BoundedBox, Object.assign({
    minHeight: "25vw"
  }, props), React.createElement(Subheading, {
    mbScale: "m",
    color: "gray.70"
  }, "Showing ", totalResultsCount, " result", totalResultsCount === 1 ? '' : 's', ' ', "for ", query), React.createElement(Grid, {
    gridTemplateColumns: "repeat(8, 1fr)",
    gridGapScale: "m",
    ref: containerRef
  }, hasProjectResults && React.createElement(React.Fragment, null, React.createElement(Heading, {
    as: "h2",
    gridColumn: "1 / -1",
    color: "blue.40",
    fontSizeScale: "l+",
    textStyle: "lowercase",
    fontWeight: "light"
  }, "Projects"), paginatedProjects.map(function (p) {
    var _p$image;

    return React.createElement(ProjectCard, {
      gridColumn: ['1 / -1', 'span 4', null, 'span 2'],
      key: p.id,
      name: p.title,
      categoryUID: p.categoryUID,
      href: p.url,
      location: p.location,
      imageURL: p.image.url,
      imageAlt: p.image.alt,
      imageFluid: (_p$image = p.image) === null || _p$image === void 0 ? void 0 : _p$image.fluid,
      variant: "small"
    });
  })), canLoadMoreProjects && React.createElement(Button, {
    gridColumn: "1 / -1",
    onClick: function onClick() {
      nextProjectPage();
      hasInitializedRef.current = true;
    },
    justifySelf: "start",
    arrowDirection: "down"
  }, "Load More"), paginatedPages.length !== 0 && React.createElement(React.Fragment, null, React.createElement(Heading, {
    as: "h2",
    gridColumn: "1 / -1",
    color: "blue.40",
    mtScale: paginatedProjects.length && 'm',
    fontSizeScale: "l+",
    textStyle: "lowercase",
    fontWeight: "light"
  }, hasProjectResults && 'More ', "Results"), paginatedPages.map(function (p) {
    return React.createElement(_StyledLink, {
      bg: "gray.90",
      key: p.id,
      pxScale: "m",
      pyScale: "m-",
      gridColumn: ['1 / -1', 'span 4', null, 'span 2'],
      href: p.url,
      transitionProperty: "color",
      outline: "0"
    }, React.createElement(Subheading, {
      color: "green.30",
      mbScale: "t"
    }, p.type.toLowerCase() === 'news' ? 'news' : 'page'), React.createElement(Heading, {
      color: "inherit",
      fontSizeScale: "m",
      fontWeight: "light"
    }, p.title));
  })), canLoadMorePages && React.createElement(Button, {
    gridColumn: "1 / -1",
    onClick: nextPage,
    justifySelf: "start"
  }, "Load More")));
};

PageBodySearchResults.mapDataToProps = function (_ref2) {
  var data = _ref2.data;
  return {
    query: data === null || data === void 0 ? void 0 : data.query
  };
};

PageBodySearchResults.mapDataToContext = function () {
  bg: 'white';
};