import "core-js/modules/es6.array.for-each";
import "core-js/modules/es6.array.map";
import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.regexp.search";
import "core-js/modules/es6.array.index-of";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.regexp.search";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.regexp.search";

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React, { useRef, useLayoutEffect } from 'react';
import { getMetadata, propPairsEq } from 'helpers';
import { useLoadMorePagination, useNewsData } from 'src/hooks';
import { Grid } from 'system';
import { BoundedBox, NewsCard, Button } from 'src/components';
export var PageBodyLatestNewsPosts = function PageBodyLatestNewsPosts(_ref) {
  var _paginatedNodes$map;

  var categoryUID = _ref.categoryUID,
      excludeUIDs = _ref.excludeUIDs,
      props = _objectWithoutPropertiesLoose(_ref, ["categoryUID", "excludeUIDs"]);

  var containerRef = useRef();
  var hasInitializedRef = useRef(false);
  var nodes = useNewsData(categoryUID, {
    excludeUIDs: excludeUIDs
  });

  var _useLoadMorePaginatio = useLoadMorePagination({
    collection: nodes,
    perPage: 8
  }),
      paginatedNodes = _useLoadMorePaginatio[0],
      loadMore = _useLoadMorePaginatio[1],
      canLoadMore = _useLoadMorePaginatio[2];

  useLayoutEffect(function () {
    var container = containerRef.current;
    var hasInitialized = hasInitializedRef.current;
    var currPage = new URLSearchParams(location.search).get('page');
    if (!container || hasInitialized || !(paginatedNodes === null || paginatedNodes === void 0 ? void 0 : paginatedNodes.length) || !currPage) return;
    hasInitializedRef.current = true;
    container.scrollIntoView({
      block: 'end'
    });
  }, [paginatedNodes]);
  return React.createElement(BoundedBox, Object.assign({
    as: "section"
  }, props), React.createElement(Grid, {
    ref: containerRef,
    gridTemplateColumns: ['1fr', 'repeat(2, 1fr)', 'repeat(4, 1fr)'],
    gridGapScale: "l"
  }, paginatedNodes === null || paginatedNodes === void 0 ? void 0 : (_paginatedNodes$map = paginatedNodes.map) === null || _paginatedNodes$map === void 0 ? void 0 : _paginatedNodes$map.call(paginatedNodes, function (node) {
    var _metadata$primary, _metadata$primary$pre, _metadata$primary2, _metadata$primary2$fe, _metadata$primary3, _metadata$primary3$pr, _metadata$primary4, _metadata$primary4$fe, _metadata$primary5, _metadata$primary5$pr, _metadata$primary6, _metadata$primary6$fe, _node$data, _node$data$title, _metadata$primary7, _metadata$primary7$ne, _metadata$primary7$ne2, _metadata$primary8;

    var metadata = getMetadata('PrismicPageMainNews', node);
    var imageFluid = (metadata === null || metadata === void 0 ? void 0 : (_metadata$primary = metadata.primary) === null || _metadata$primary === void 0 ? void 0 : (_metadata$primary$pre = _metadata$primary.preview_image) === null || _metadata$primary$pre === void 0 ? void 0 : _metadata$primary$pre.fluid) || (metadata === null || metadata === void 0 ? void 0 : (_metadata$primary2 = metadata.primary) === null || _metadata$primary2 === void 0 ? void 0 : (_metadata$primary2$fe = _metadata$primary2.featured_image) === null || _metadata$primary2$fe === void 0 ? void 0 : _metadata$primary2$fe.fluid);
    var imageURL = (metadata === null || metadata === void 0 ? void 0 : (_metadata$primary3 = metadata.primary) === null || _metadata$primary3 === void 0 ? void 0 : (_metadata$primary3$pr = _metadata$primary3.preview_image) === null || _metadata$primary3$pr === void 0 ? void 0 : _metadata$primary3$pr.url) || (metadata === null || metadata === void 0 ? void 0 : (_metadata$primary4 = metadata.primary) === null || _metadata$primary4 === void 0 ? void 0 : (_metadata$primary4$fe = _metadata$primary4.featured_image) === null || _metadata$primary4$fe === void 0 ? void 0 : _metadata$primary4$fe.url);
    var imageAlt = (metadata === null || metadata === void 0 ? void 0 : (_metadata$primary5 = metadata.primary) === null || _metadata$primary5 === void 0 ? void 0 : (_metadata$primary5$pr = _metadata$primary5.preview_image) === null || _metadata$primary5$pr === void 0 ? void 0 : _metadata$primary5$pr.alt) || (metadata === null || metadata === void 0 ? void 0 : (_metadata$primary6 = metadata.primary) === null || _metadata$primary6 === void 0 ? void 0 : (_metadata$primary6$fe = _metadata$primary6.featured_image) === null || _metadata$primary6$fe === void 0 ? void 0 : _metadata$primary6$fe.alt);
    return React.createElement(NewsCard, {
      title: node === null || node === void 0 ? void 0 : (_node$data = node.data) === null || _node$data === void 0 ? void 0 : (_node$data$title = _node$data.title) === null || _node$data$title === void 0 ? void 0 : _node$data$title.text,
      href: node === null || node === void 0 ? void 0 : node.url,
      categoryUID: metadata === null || metadata === void 0 ? void 0 : (_metadata$primary7 = metadata.primary) === null || _metadata$primary7 === void 0 ? void 0 : (_metadata$primary7$ne = _metadata$primary7.news_category) === null || _metadata$primary7$ne === void 0 ? void 0 : (_metadata$primary7$ne2 = _metadata$primary7$ne.document) === null || _metadata$primary7$ne2 === void 0 ? void 0 : _metadata$primary7$ne2.uid,
      date: metadata === null || metadata === void 0 ? void 0 : (_metadata$primary8 = metadata.primary) === null || _metadata$primary8 === void 0 ? void 0 : _metadata$primary8.date,
      imageFluid: imageFluid,
      imageURL: imageURL,
      imageAlt: imageAlt
    });
  })), canLoadMore && React.createElement(Button, {
    mtScale: "l",
    onClick: function onClick() {
      hasInitializedRef.current = true;
      loadMore();
    },
    arrowDirection: "down"
  }, "Load More"));
};

PageBodyLatestNewsPosts.mapDataToProps = function (_ref2) {
  var _meta$page, _meta$page$data, _meta$page$data$body, _data$primary, _data$primary$news_ca;

  var data = _ref2.data,
      meta = _ref2.meta,
      context = _ref2.context,
      nextContext = _ref2.nextContext;
  var excludeUIDs = []; // Exclude any project displayed on the page via project card slices.

  meta === null || meta === void 0 ? void 0 : (_meta$page = meta.page) === null || _meta$page === void 0 ? void 0 : (_meta$page$data = _meta$page.data) === null || _meta$page$data === void 0 ? void 0 : (_meta$page$data$body = _meta$page$data.body) === null || _meta$page$data$body === void 0 ? void 0 : _meta$page$data$body.forEach(function (slice) {
    var _slice$primary, _slice$primary$news_p, _slice$primary$news_p2;

    switch (slice === null || slice === void 0 ? void 0 : slice.__typename) {
      case 'PrismicPageBodyFeaturedNewsPost':
        excludeUIDs.push(slice === null || slice === void 0 ? void 0 : (_slice$primary = slice.primary) === null || _slice$primary === void 0 ? void 0 : (_slice$primary$news_p = _slice$primary.news_post) === null || _slice$primary$news_p === void 0 ? void 0 : (_slice$primary$news_p2 = _slice$primary$news_p.document) === null || _slice$primary$news_p2 === void 0 ? void 0 : _slice$primary$news_p2.uid);
        break;
    }
  });
  return {
    nextSharesBg: propPairsEq('bg', context, nextContext),
    categoryUID: data === null || data === void 0 ? void 0 : (_data$primary = data.primary) === null || _data$primary === void 0 ? void 0 : (_data$primary$news_ca = _data$primary.news_category) === null || _data$primary$news_ca === void 0 ? void 0 : _data$primary$news_ca.uid,
    excludeUIDs: excludeUIDs
  };
};

PageBodyLatestNewsPosts.mapDataToContext = function () {
  return {
    bg: 'white'
  };
};

PageBodyLatestNewsPosts.docs = {
  name: 'Latest News Posts',
  description: 'News post with a large image and introduction to the article.',
  preset: true,
  examples: [{
    name: 'Default',
    render: function render() {
      return React.createElement(PageBodyLatestNewsPosts, null);
    }
  }]
};