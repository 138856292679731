import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.array.index-of";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React from 'react';
import { getRichText, getMetadata } from 'helpers';
import { Grid } from 'system';
import { BoundedBox, BrandedCallout, FeaturedNewsCard } from 'src/components';
export var PageBodyCalloutWithNews = function PageBodyCalloutWithNews(_ref) {
  var colorScheme = _ref.colorScheme,
      calloutTextHTML = _ref.calloutTextHTML,
      calloutButtonText = _ref.calloutButtonText,
      calloutLink = _ref.calloutLink,
      calloutTarget = _ref.calloutTarget,
      featuredNewsTitle = _ref.featuredNewsTitle,
      featuredNewsImageURL = _ref.featuredNewsImageURL,
      featuredNewsImageAlt = _ref.featuredNewsImageAlt,
      featuredNewsImageFluid = _ref.featuredNewsImageFluid,
      featuredNewsHref = _ref.featuredNewsHref,
      featuredNewsCategoryUID = _ref.featuredNewsCategoryUID,
      featuredNewsDate = _ref.featuredNewsDate,
      props = _objectWithoutPropertiesLoose(_ref, ["colorScheme", "calloutTextHTML", "calloutButtonText", "calloutLink", "calloutTarget", "featuredNewsTitle", "featuredNewsImageURL", "featuredNewsImageAlt", "featuredNewsImageFluid", "featuredNewsHref", "featuredNewsCategoryUID", "featuredNewsDate"]);

  return React.createElement(BoundedBox, Object.assign({
    as: "section"
  }, props), React.createElement(Grid, {
    gridTemplateColumns: ['1fr', null, '1fr 1fr'],
    gridColumnGapScale: "m",
    gridRowGapScale: "m"
  }, React.createElement(BrandedCallout, {
    textHTML: calloutTextHTML,
    buttonHref: calloutLink,
    buttonTarget: calloutTarget,
    buttonText: calloutButtonText,
    textWidth: [1, 1, 1, 1, 7 / 8],
    variant: colorScheme
  }), React.createElement(FeaturedNewsCard, {
    href: featuredNewsHref,
    imageURL: featuredNewsImageURL,
    imageAlt: featuredNewsImageAlt,
    imageFluid: featuredNewsImageFluid,
    title: featuredNewsTitle,
    categoryUID: featuredNewsCategoryUID,
    date: featuredNewsDate
  })));
};

PageBodyCalloutWithNews.mapDataToProps = function (_ref2) {
  var _primary$featured_new, _featuredNewsMetadata, _featuredNewsMetadata2, _primary$callout_butt, _primary$callout_butt2, _primary$callout_butt3, _featuredNewsDoc$data, _featuredNewsDoc$data2, _featuredNewsMetadata3, _featuredNewsMetadata4, _featuredNewsMetadata5, _featuredNewsMetadata6, _featuredNewsMetadata7, _featuredNewsMetadata8, _featuredNewsMetadata9;

  var data = _ref2.data;
  var primary = data === null || data === void 0 ? void 0 : data.primary;
  var featuredNewsDoc = primary === null || primary === void 0 ? void 0 : (_primary$featured_new = primary.featured_news) === null || _primary$featured_new === void 0 ? void 0 : _primary$featured_new.document;
  var featuredNewsMetadata = getMetadata('PrismicPageMainNews', featuredNewsDoc);
  var featuredNewsCategoryDoc = featuredNewsMetadata === null || featuredNewsMetadata === void 0 ? void 0 : (_featuredNewsMetadata = featuredNewsMetadata.primary) === null || _featuredNewsMetadata === void 0 ? void 0 : (_featuredNewsMetadata2 = _featuredNewsMetadata.news_category) === null || _featuredNewsMetadata2 === void 0 ? void 0 : _featuredNewsMetadata2.document;
  return {
    colorScheme: primary === null || primary === void 0 ? void 0 : primary.color_scheme.toLowerCase(),
    calloutTextHTML: getRichText(primary === null || primary === void 0 ? void 0 : primary.callout_text),
    calloutButtonText: primary === null || primary === void 0 ? void 0 : (_primary$callout_butt = primary.callout_button_text) === null || _primary$callout_butt === void 0 ? void 0 : _primary$callout_butt.text,
    calloutLink: primary === null || primary === void 0 ? void 0 : (_primary$callout_butt2 = primary.callout_button_link) === null || _primary$callout_butt2 === void 0 ? void 0 : _primary$callout_butt2.url,
    calloutTarget: primary === null || primary === void 0 ? void 0 : (_primary$callout_butt3 = primary.callout_button_link) === null || _primary$callout_butt3 === void 0 ? void 0 : _primary$callout_butt3.target,
    featuredNewsTitle: featuredNewsDoc === null || featuredNewsDoc === void 0 ? void 0 : (_featuredNewsDoc$data = featuredNewsDoc.data) === null || _featuredNewsDoc$data === void 0 ? void 0 : (_featuredNewsDoc$data2 = _featuredNewsDoc$data.title) === null || _featuredNewsDoc$data2 === void 0 ? void 0 : _featuredNewsDoc$data2.text,
    featuredNewsImageURL: featuredNewsMetadata === null || featuredNewsMetadata === void 0 ? void 0 : (_featuredNewsMetadata3 = featuredNewsMetadata.primary) === null || _featuredNewsMetadata3 === void 0 ? void 0 : (_featuredNewsMetadata4 = _featuredNewsMetadata3.featured_image) === null || _featuredNewsMetadata4 === void 0 ? void 0 : _featuredNewsMetadata4.url,
    featuredNewsImageAlt: featuredNewsMetadata === null || featuredNewsMetadata === void 0 ? void 0 : (_featuredNewsMetadata5 = featuredNewsMetadata.primary) === null || _featuredNewsMetadata5 === void 0 ? void 0 : (_featuredNewsMetadata6 = _featuredNewsMetadata5.featured_image) === null || _featuredNewsMetadata6 === void 0 ? void 0 : _featuredNewsMetadata6.alt,
    featuredNewsImageFluid: featuredNewsMetadata === null || featuredNewsMetadata === void 0 ? void 0 : (_featuredNewsMetadata7 = featuredNewsMetadata.primary) === null || _featuredNewsMetadata7 === void 0 ? void 0 : (_featuredNewsMetadata8 = _featuredNewsMetadata7.featured_image) === null || _featuredNewsMetadata8 === void 0 ? void 0 : _featuredNewsMetadata8.fluid,
    featuredNewsHref: featuredNewsDoc === null || featuredNewsDoc === void 0 ? void 0 : featuredNewsDoc.url,
    featuredNewsCategoryUID: featuredNewsCategoryDoc === null || featuredNewsCategoryDoc === void 0 ? void 0 : featuredNewsCategoryDoc.uid,
    featuredNewsDate: featuredNewsMetadata === null || featuredNewsMetadata === void 0 ? void 0 : (_featuredNewsMetadata9 = featuredNewsMetadata.primary) === null || _featuredNewsMetadata9 === void 0 ? void 0 : _featuredNewsMetadata9.date
  };
};

PageBodyCalloutWithNews.mapDataToContext = function () {
  return {
    bg: 'white'
  };
};

PageBodyCalloutWithNews.docs = {
  name: 'Callout with News',
  examples: [{
    name: 'Light color scheme',
    render: function render() {
      return React.createElement(PageBodyCalloutWithNews, {
        colorScheme: "light",
        calloutButtonText: "See our work",
        calloutLink: true,
        featuredNewsTitle: "PVA wins Hawaii Energy Award for Excellence and honorable mention at the AIA Honolulu Awards Gala",
        featuredNewsHref: true,
        featuredNewsCategory: "Company Updates",
        featuredNewsDate: "September 2019",
        featuredNewsImageURL: "https://pvadev.cdn.prismic.io/pvadev/3bfcc5a0d7915f76cfa895ea780fa68b8dd8f30c_news-award.jpg",
        calloutTextHTML: "<h1>Over 25 years of experience as one of Hawaii's most highly regarding architecture firms.</h1>"
      });
    }
  }, {
    name: 'Dark color scheme',
    render: function render() {
      return React.createElement(PageBodyCalloutWithNews, {
        colorScheme: "dark",
        calloutButtonText: "See our work",
        calloutLink: true,
        featuredNewsTitle: "PVA wins Hawaii Energy Award for Excellence and honorable mention at the AIA Honolulu Awards Gala",
        featuredNewsHref: true,
        featuredNewsCategory: "Company Updates",
        featuredNewsDate: "September 2019",
        featuredNewsImageURL: "https://pvadev.cdn.prismic.io/pvadev/3bfcc5a0d7915f76cfa895ea780fa68b8dd8f30c_news-award.jpg",
        calloutTextHTML: "<h1>Over 25 years of experience as one of Hawaii's most highly regarding architecture firms.</h1>"
      });
    }
  }]
};
export var query = "3414076249";