import "core-js/modules/es6.array.map";
import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.array.index-of";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React from 'react';
import { getMetadata, notEmpty, propPairsEq } from 'helpers';
import { Grid, Box } from 'system';
import { BoundedBox, Heading, ProjectCard } from 'src/components';
export var PageBodyProjectCards = function PageBodyProjectCards(_ref) {
  var listName = _ref.listName,
      children = _ref.children,
      props = _objectWithoutPropertiesLoose(_ref, ["listName", "children"]);

  return React.createElement(BoundedBox, Object.assign({
    as: "section"
  }, props), React.createElement(Grid, {
    gridTemplateColumns: ['1fr', null, null, 'repeat(8, 1fr)'],
    gridAutoFlow: "row dense",
    gridGapScale: "l",
    borderTop: "1px solid black",
    ptScale: "m"
  }, React.createElement(Box, {
    gridColumn: [null, null, null, 'span 2']
  }, React.createElement(Heading, {
    fontSizeScale: "m+",
    fontWeight: "light"
  }, listName)), React.createElement(Box, {
    gridColumn: [null, null, null, '3 / span 6']
  }, React.createElement(Grid, {
    gridGapScale: "l",
    gridTemplateColumns: children.length % 2 === 0 || children.length === 1 ? ['1fr', 'repeat(2, 1fr)'] : ['1fr', 'repeat(3, 1fr)']
  }, children))));
};
PageBodyProjectCards.Project = ProjectCard;

PageBodyProjectCards.mapDataToProps = function (_ref2) {
  var _data$primary, _data$primary$list_na, _data$items, _data$items$map;

  var data = _ref2.data,
      context = _ref2.context,
      nextContext = _ref2.nextContext;
  return {
    nextSharesBg: propPairsEq('bg', context, nextContext),
    listName: data === null || data === void 0 ? void 0 : (_data$primary = data.primary) === null || _data$primary === void 0 ? void 0 : (_data$primary$list_na = _data$primary.list_name) === null || _data$primary$list_na === void 0 ? void 0 : _data$primary$list_na.text,
    children: data === null || data === void 0 ? void 0 : (_data$items = data.items) === null || _data$items === void 0 ? void 0 : (_data$items$map = _data$items.map) === null || _data$items$map === void 0 ? void 0 : _data$items$map.call(_data$items, function (item) {
      var _item$project, _projectDoc$data, _projectDoc$data$titl, _metadata$items, _metadata$items$, _metadata$items$$proj, _metadata$items$$proj2, _metadata$primary, _metadata$primary$fea, _metadata$primary2, _metadata$primary2$fe, _metadata$primary3, _metadata$primary3$fe, _metadata$primary4, _metadata$primary4$lo, _projectDoc$data2, _projectDoc$data2$tit;

      var projectDoc = item === null || item === void 0 ? void 0 : (_item$project = item.project) === null || _item$project === void 0 ? void 0 : _item$project.document;
      var metadata = getMetadata('PrismicPageMainProject', projectDoc);
      return React.createElement(PageBodyProjectCards.Project, {
        key: projectDoc === null || projectDoc === void 0 ? void 0 : (_projectDoc$data = projectDoc.data) === null || _projectDoc$data === void 0 ? void 0 : (_projectDoc$data$titl = _projectDoc$data.title) === null || _projectDoc$data$titl === void 0 ? void 0 : _projectDoc$data$titl.text,
        variant: "wide",
        categoryUID: metadata === null || metadata === void 0 ? void 0 : (_metadata$items = metadata.items) === null || _metadata$items === void 0 ? void 0 : (_metadata$items$ = _metadata$items[0]) === null || _metadata$items$ === void 0 ? void 0 : (_metadata$items$$proj = _metadata$items$.project_category) === null || _metadata$items$$proj === void 0 ? void 0 : (_metadata$items$$proj2 = _metadata$items$$proj.document) === null || _metadata$items$$proj2 === void 0 ? void 0 : _metadata$items$$proj2.uid,
        href: projectDoc === null || projectDoc === void 0 ? void 0 : projectDoc.url,
        imageAlt: notEmpty(metadata === null || metadata === void 0 ? void 0 : (_metadata$primary = metadata.primary) === null || _metadata$primary === void 0 ? void 0 : (_metadata$primary$fea = _metadata$primary.featured_image) === null || _metadata$primary$fea === void 0 ? void 0 : _metadata$primary$fea.alt),
        imageFluid: metadata === null || metadata === void 0 ? void 0 : (_metadata$primary2 = metadata.primary) === null || _metadata$primary2 === void 0 ? void 0 : (_metadata$primary2$fe = _metadata$primary2.featured_image) === null || _metadata$primary2$fe === void 0 ? void 0 : _metadata$primary2$fe.fluid,
        imageURL: metadata === null || metadata === void 0 ? void 0 : (_metadata$primary3 = metadata.primary) === null || _metadata$primary3 === void 0 ? void 0 : (_metadata$primary3$fe = _metadata$primary3.featured_image) === null || _metadata$primary3$fe === void 0 ? void 0 : _metadata$primary3$fe.url,
        location: metadata === null || metadata === void 0 ? void 0 : (_metadata$primary4 = metadata.primary) === null || _metadata$primary4 === void 0 ? void 0 : (_metadata$primary4$lo = _metadata$primary4.location) === null || _metadata$primary4$lo === void 0 ? void 0 : _metadata$primary4$lo.text,
        name: projectDoc === null || projectDoc === void 0 ? void 0 : (_projectDoc$data2 = projectDoc.data) === null || _projectDoc$data2 === void 0 ? void 0 : (_projectDoc$data2$tit = _projectDoc$data2.title) === null || _projectDoc$data2$tit === void 0 ? void 0 : _projectDoc$data2$tit.text
      });
    })
  };
};

PageBodyProjectCards.mapDataToContext = function () {
  return {
    bg: 'white'
  };
};

PageBodyProjectCards.docs = {
  name: 'Project Cards',
  examples: [{
    name: 'With two projects',
    render: function render() {
      return React.createElement(PageBodyProjectCards, {
        listName: "Featured Projects"
      }, React.createElement(PageBodyProjectCards.Project, {
        variant: "wide",
        categoryUID: "office-healthcare",
        href: true,
        imageURL: "https://pvadev.cdn.prismic.io/pvadev/e1d47f8f8ffe39330c19d3a01ccc34b4c59bb4b4_0-bank-of-hawaii.jpg",
        location: "Honolulu, Hawaii",
        name: "Bank of Hawaii"
      }), React.createElement(PageBodyProjectCards.Project, {
        variant: "wide",
        categoryUID: "hospitality-leisure",
        href: true,
        imageURL: "https://pvadev.cdn.prismic.io/pvadev/dae0d171c7d1f15f5d309c504fec3488b8907178_0-kapolei-golf-course-clubhouse-renovation.jpg",
        location: "Kapolei, Hawaii",
        name: "Kapolei Golf Course Clubhouse Renovation"
      }));
    }
  }]
};
export var fragment = "1883137578";