import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.array.index-of";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React from 'react';
import { camelCase } from 'tiny-compose-fns';
import { getRichText, notEmpty, propPairsEq } from 'helpers';
import { BoundedBox, BrandedCallout } from 'src/components';
export var PageBodyBrandedCallout = function PageBodyBrandedCallout(_ref) {
  var textHTML = _ref.textHTML,
      variant = _ref.variant,
      buttonText = _ref.buttonText,
      buttonHref = _ref.buttonHref,
      buttonTarget = _ref.buttonTarget,
      backgroundImageUrl = _ref.backgroundImageUrl,
      backgroundImageFluid = _ref.backgroundImageFluid,
      props = _objectWithoutPropertiesLoose(_ref, ["textHTML", "variant", "buttonText", "buttonHref", "buttonTarget", "backgroundImageUrl", "backgroundImageFluid"]);

  return React.createElement(BoundedBox, Object.assign({
    as: "section"
  }, props), React.createElement(BrandedCallout, {
    textHTML: textHTML,
    variant: variant,
    buttonText: buttonText,
    buttonHref: buttonHref,
    buttonTarget: buttonTarget,
    backgroundImageUrl: backgroundImageUrl,
    backgroundImageFluid: backgroundImageFluid
  }));
};

PageBodyBrandedCallout.mapDataToProps = function (_ref2) {
  var _data$primary, _data$primary2, _data$primary3, _data$primary4, _data$primary5, _data$primary6, _data$primary6$backgr, _data$primary7, _data$primary7$backgr, _data$primary8, _data$primary8$backgr;

  var data = _ref2.data,
      context = _ref2.context,
      nextContext = _ref2.nextContext;
  return {
    nextSharesBg: propPairsEq('bg', context, nextContext),
    textHTML: getRichText(data === null || data === void 0 ? void 0 : (_data$primary = data.primary) === null || _data$primary === void 0 ? void 0 : _data$primary.text),
    variant: notEmpty(camelCase(data === null || data === void 0 ? void 0 : (_data$primary2 = data.primary) === null || _data$primary2 === void 0 ? void 0 : _data$primary2.color_scheme)),
    buttonText: data === null || data === void 0 ? void 0 : (_data$primary3 = data.primary) === null || _data$primary3 === void 0 ? void 0 : _data$primary3.button_text.text,
    buttonHref: data === null || data === void 0 ? void 0 : (_data$primary4 = data.primary) === null || _data$primary4 === void 0 ? void 0 : _data$primary4.button_link.url,
    buttonTarget: data === null || data === void 0 ? void 0 : (_data$primary5 = data.primary) === null || _data$primary5 === void 0 ? void 0 : _data$primary5.button_link.target,
    backgroundImageUrl: data === null || data === void 0 ? void 0 : (_data$primary6 = data.primary) === null || _data$primary6 === void 0 ? void 0 : (_data$primary6$backgr = _data$primary6.background_image) === null || _data$primary6$backgr === void 0 ? void 0 : _data$primary6$backgr.url,
    backgroundImageAlt: data === null || data === void 0 ? void 0 : (_data$primary7 = data.primary) === null || _data$primary7 === void 0 ? void 0 : (_data$primary7$backgr = _data$primary7.background_image) === null || _data$primary7$backgr === void 0 ? void 0 : _data$primary7$backgr.alt,
    backgroundImageFluid: data === null || data === void 0 ? void 0 : (_data$primary8 = data.primary) === null || _data$primary8 === void 0 ? void 0 : (_data$primary8$backgr = _data$primary8.background_image) === null || _data$primary8$backgr === void 0 ? void 0 : _data$primary8$backgr.fluid
  };
};

PageBodyBrandedCallout.mapDataToContext = function () {
  return {
    bg: 'white'
  };
};

export var fragment = "1588887641";
PageBodyBrandedCallout.docs = {
  name: 'Branded Callout',
  examples: [{
    name: 'Dark w/ no BG',
    render: function render() {
      return React.createElement(PageBodyBrandedCallout, {
        variant: "dark",
        textHTML: "<h1>Our team of architects and interior designers is both collaborative and visionary.</h1>",
        buttonHref: true,
        buttonText: "Our approach"
      });
    }
  }, {
    name: 'Dark w/ BG photo',
    render: function render() {
      return React.createElement(PageBodyBrandedCallout, {
        variant: "dark",
        textHTML: "<h1>Our team of architects and interior designers is both collaborative and visionary.</h1>",
        buttonHref: true,
        buttonText: "Our approach",
        backgroundImageUrl: "https://images.prismic.io/pvadev%2F5c23e9a7-9174-4f81-b139-dd2032fff0ac_office.jpg?auto=compress,format"
      });
    }
  }, {
    name: 'Light',
    render: function render() {
      return React.createElement(PageBodyBrandedCallout, {
        variant: "light",
        textHTML: "<h1>Our team of architects and interior designers is both collaborative and visionary.</h1>",
        buttonHref: true,
        buttonText: "Our approach"
      });
    }
  }]
};