import "core-js/modules/es6.array.map";
import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.array.index-of";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";
import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React, { useState, useEffect } from 'react';
import { linearScale } from 'styled-system-scale';
import { useGesture } from 'react-use-gesture';
import { a, useSpring, useTransition, config } from 'react-spring';
import { themeGet } from '@styled-system/theme-get';
import { getMetadata, propPairsEq } from 'helpers';
import { useInterval } from 'hooks';
import { camelCase } from 'tiny-compose-fns';
import { useScrollToBottom } from 'src/hooks';
import { Box, Button, Flex, Image, ImageContainer, Link, SVG } from 'system';
import { BoundedBox, Heading, ScrollDownButton } from 'src/components';
import { ReactComponent as AssetIconArrowRightSVG } from 'src/assets/icon-arrow-right.svg';
var colorMap = {
  orange: 'orange.50',
  green: 'green.30',
  blue: 'blue.40',
  yellow: 'yellow.40',
  pink: 'pink.40'
};

var _StyledBox = _styled(Box).withConfig({
  displayName: "PageBodyHomeHero___StyledBox",
  componentId: "sc-3wa7q0-0"
})({
  whiteSpace: 'nowrap'
});

var _StyledBox2 = _styled(Box).withConfig({
  displayName: "PageBodyHomeHero___StyledBox2",
  componentId: "sc-3wa7q0-1"
})(["", ""], function (p) {
  return {
    backgroundImage: "linear-gradient(\n            to right, \n            " + themeGet("colors." + p.bgColor)(p) + "00,\n            " + themeGet("colors." + p.bgColor)(p) + "\n          )"
  };
});

var ColorCategory = React.memo(function (_ref) {
  var children = _ref.children,
      categoryColor = _ref.categoryColor,
      totalNumItems = _ref.totalNumItems,
      currIndex = _ref.currIndex,
      index = _ref.index,
      onClick = _ref.onClick,
      props = _objectWithoutPropertiesLoose(_ref, ["children", "categoryColor", "totalNumItems", "currIndex", "index", "onClick"]);

  var bg = colorMap[camelCase(categoryColor)];
  var isStart = currIndex === null;
  var isActive = currIndex === index;

  var handleFocus = function handleFocus(isFocused) {
    if (isStart) set({
      width: isFocused ? '120%' : '100%'
    });else if (isActive) set({
      width: '50%'
    });else if (isFocused) set({
      width: 50 / totalNumItems + 8 + '%'
    });else set({
      width: 50 / totalNumItems + 4 + '%'
    });
  };

  var bind = useGesture({
    onHover: function onHover(_ref2) {
      var hovering = _ref2.hovering;
      return handleFocus(hovering);
    },
    onDrag: function onDrag(_ref3) {
      var down = _ref3.down;
      return handleFocus(down);
    }
  });

  var _useSpring = useSpring(function () {
    return {
      width: '100%'
    };
  }),
      style = _useSpring[0],
      set = _useSpring[1];

  useEffect(function () {
    if (isActive) set({
      width: '50%'
    });else if (!isStart) set({
      width: 50 / (totalNumItems - 1) + '%'
    });
  }, [set, isActive, isStart, totalNumItems]);
  return React.createElement(Button, Object.assign({
    as: a(Button),
    style: style,
    color: "white",
    pyScale: "t",
    plScale: "xl",
    bg: bg,
    outline: "none",
    overflow: "hidden",
    position: "relative",
    fontWeight: "light",
    isStart: isStart,
    isActive: isActive,
    totalNumItems: totalNumItems,
    flexGrow: 1,
    onClick: onClick
  }, bind(), props), React.createElement(_StyledBox, {
    opacity: [isStart || !isActive ? 0 : 1, 1],
    transitionProperty: "opacity, transform",
    transitionTimingFunction: "ease",
    transitionDuration: ".4s",
    transform: [isActive ? 'translateX(0px)' : 'translateX(-50px)', 'translateX(0px)']
  }, children), React.createElement(_StyledBox2, {
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    width: "3rem",
    bgColor: bg
  }));
}, function (prevProps, nextProps) {
  return prevProps.currIndex === nextProps.currIndex;
});

var _StyledBox3 = _styled(Box).withConfig({
  displayName: "PageBodyHomeHero___StyledBox3",
  componentId: "sc-3wa7q0-2"
})({
  pointerEvents: 'none'
});

var CategoryImage = function CategoryImage(_ref4) {
  var src = _ref4.src,
      fluid = _ref4.fluid,
      alt = _ref4.alt,
      projectTitle = _ref4.projectTitle,
      projectHref = _ref4.projectHref,
      props = _objectWithoutPropertiesLoose(_ref4, ["src", "fluid", "alt", "projectTitle", "projectHref"]);

  var _useState = useState(false),
      isFocused = _useState[0],
      setIsFocused = _useState[1];

  var handleFocus = function handleFocus(isFocused) {
    return setIsFocused(function () {
      return Boolean(isFocused);
    });
  };

  var bind = useGesture({
    onHover: function onHover(_ref5) {
      var hovering = _ref5.hovering;
      return handleFocus(hovering);
    },
    onDrag: function onDrag(_ref6) {
      var down = _ref6.down;
      return handleFocus(down);
    }
  });
  return React.createElement(Box, Object.assign({
    height: "100%",
    bg: "gray.10"
  }, props), React.createElement(ImageContainer, {
    height: "100%"
  }, (fluid || src) && React.createElement(Image, {
    src: src,
    fluid: fluid,
    alt: alt,
    loading: "eager"
  })), projectTitle && projectHref && React.createElement(BoundedBox, {
    position: "absolute",
    bottom: 0,
    left: 0,
    width: 7 / 8,
    zIndex: 1
  }, React.createElement(Link, Object.assign({
    href: projectHref,
    onBlur: function onBlur() {
      return handleFocus(false);
    },
    onFocus: function onFocus() {
      return handleFocus(true);
    },
    outline: 0
  }, bind()), React.createElement(Flex, {
    display: "inline-flex",
    alignItems: "center"
  }, React.createElement(Heading, {
    color: "white",
    fontWeight: "light",
    fontSizeScale: "l",
    textShadow: "0 2px 4px rgba(0, 0, 0, 0.75)"
  }, projectTitle, React.createElement(SVG, {
    display: "inline-block",
    flexShrink: "0",
    svg: AssetIconArrowRightSVG,
    x: 21,
    y: 23,
    color: "white",
    width: linearScale('15px', '30px', {
      count: 5
    }),
    mlScale: "t",
    transitionProperty: "transform",
    transform: isFocused ? "translate3d(20%, 10%, 0)" : "translate3d(0, 10%, 0)"
  }))))), React.createElement(_StyledBox3, {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    height: "10rem",
    backgroundImage: "linear-gradient(to top, rgba(0, 0, 0, .45) 0%, rgba(0, 0, 0, .15) 50%, rgba(0, 0, 0, 0) 100%)"
  }));
};

export var PageBodyHomeHero = function PageBodyHomeHero(_ref7) {
  var categories = _ref7.categories,
      images = _ref7.images,
      props = _objectWithoutPropertiesLoose(_ref7, ["categories", "images"]);

  var _useState2 = useState(0),
      index = _useState2[0],
      setIndex = _useState2[1];

  var transitions = useTransition(index, function (image) {
    return image;
  }, {
    from: {
      opacity: 0,
      transform: 'translate3d(300px, 0, 0)'
    },
    enter: {
      opacity: 1,
      transform: 'translate3d(0px, 0, 0)'
    },
    leave: {
      opacity: 1,
      transform: 'translate3d(0px, 0 , 0)'
    },
    config: config.slow
  });

  var _useInterval = useInterval(function () {
    if (index === null) setIndex(0);else setIndex(function (index) {
      return (index + 1) % 5;
    });
  }, 5500),
      stopAnimation = _useInterval.stop;

  var setIndexAndCancelInterval = function setIndexAndCancelInterval(i) {
    stopAnimation();
    setIndex(i);
  };

  var _useScrollToBottom = useScrollToBottom(),
      ref = _useScrollToBottom[0],
      scrollToBottom = _useScrollToBottom[1];

  return React.createElement(BoundedBox, Object.assign({
    ref: ref,
    as: "section",
    ml: 0,
    mr: 0,
    px: 0,
    ptScale: 0
  }, props), React.createElement(Box, {
    position: "relative",
    height: "90vh",
    maxHeight: linearScale('35rem', '80rem', {
      count: 5
    }),
    width: "100%",
    bg: "black",
    overflow: "hidden"
  }, transitions.map(function (_ref8) {
    var item = _ref8.item,
        style = _ref8.props,
        key = _ref8.key;
    var image = images[item];
    return ((image === null || image === void 0 ? void 0 : image.imageURL) || (image === null || image === void 0 ? void 0 : image.imageFluid)) && React.createElement(Box, {
      as: a.div,
      key: key,
      style: style,
      position: "absolute",
      top: 0,
      right: 0,
      left: 0,
      bottom: 0
    }, React.createElement(CategoryImage, {
      src: image === null || image === void 0 ? void 0 : image.imageURL,
      alt: image === null || image === void 0 ? void 0 : image.imageAlt,
      fluid: image === null || image === void 0 ? void 0 : image.imageFluid,
      projectTitle: image === null || image === void 0 ? void 0 : image.projectTitle,
      projectHref: image === null || image === void 0 ? void 0 : image.projectHref
    }, index));
  }), React.createElement(BoundedBox, {
    display: ['none', 'block'],
    position: "absolute",
    bottom: 0,
    right: 0
  }, React.createElement(ScrollDownButton, {
    color: "white",
    onClick: scrollToBottom
  }))), React.createElement(Flex, null, categories.map(function (category, i) {
    return React.createElement(ColorCategory, {
      key: category.title,
      categoryColor: category.color,
      totalNumItems: categories.length,
      currIndex: index,
      index: i,
      onClick: function onClick() {
        return setIndexAndCancelInterval(i);
      }
    }, category.title);
  })));
};

PageBodyHomeHero.mapDataToProps = function (_ref9) {
  var _metadata$items, _metadata$items2;

  var meta = _ref9.meta,
      context = _ref9.context,
      nextContext = _ref9.nextContext;
  var metadata = getMetadata('PrismicPageMainHome', meta === null || meta === void 0 ? void 0 : meta.page);
  return {
    nextSharesBg: propPairsEq('bg', context, nextContext),
    categories: metadata === null || metadata === void 0 ? void 0 : (_metadata$items = metadata.items) === null || _metadata$items === void 0 ? void 0 : _metadata$items.map(function (item) {
      var _item$category, _doc$data, _doc$data2, _doc$data2$title;

      var doc = item === null || item === void 0 ? void 0 : (_item$category = item.category) === null || _item$category === void 0 ? void 0 : _item$category.document;
      return {
        uid: doc === null || doc === void 0 ? void 0 : doc.uid,
        color: doc === null || doc === void 0 ? void 0 : (_doc$data = doc.data) === null || _doc$data === void 0 ? void 0 : _doc$data.color,
        title: doc === null || doc === void 0 ? void 0 : (_doc$data2 = doc.data) === null || _doc$data2 === void 0 ? void 0 : (_doc$data2$title = _doc$data2.title) === null || _doc$data2$title === void 0 ? void 0 : _doc$data2$title.text
      };
    }),
    images: metadata === null || metadata === void 0 ? void 0 : (_metadata$items2 = metadata.items) === null || _metadata$items2 === void 0 ? void 0 : _metadata$items2.map(function (item) {
      var _item$image, _item$image2, _item$image3, _item$project, _item$project$documen, _item$project$documen2, _item$project$documen3, _item$project2, _item$project2$docume;

      return {
        imageAlt: item === null || item === void 0 ? void 0 : (_item$image = item.image) === null || _item$image === void 0 ? void 0 : _item$image.alt,
        imageURL: item === null || item === void 0 ? void 0 : (_item$image2 = item.image) === null || _item$image2 === void 0 ? void 0 : _item$image2.url,
        imageFluid: item === null || item === void 0 ? void 0 : (_item$image3 = item.image) === null || _item$image3 === void 0 ? void 0 : _item$image3.fluid,
        projectTitle: item === null || item === void 0 ? void 0 : (_item$project = item.project) === null || _item$project === void 0 ? void 0 : (_item$project$documen = _item$project.document) === null || _item$project$documen === void 0 ? void 0 : (_item$project$documen2 = _item$project$documen.data) === null || _item$project$documen2 === void 0 ? void 0 : (_item$project$documen3 = _item$project$documen2.title) === null || _item$project$documen3 === void 0 ? void 0 : _item$project$documen3.text,
        projectHref: item === null || item === void 0 ? void 0 : (_item$project2 = item.project) === null || _item$project2 === void 0 ? void 0 : (_item$project2$docume = _item$project2.document) === null || _item$project2$docume === void 0 ? void 0 : _item$project2$docume.url
      };
    })
  };
};

PageBodyHomeHero.mapDataToContext = function () {
  return {
    pullsDownHeader: true
  };
};

PageBodyHomeHero.docs = {
  name: 'Home Hero',
  description: 'Large interactive project carousel.',
  preset: true,
  examples: [{
    name: 'Home Hero',
    render: function render() {
      return React.createElement(PageBodyHomeHero, {
        nextSharesBg: [true],
        categories: [{
          title: 'Hospitality + Leisure',
          color: 'orange'
        }, {
          title: 'Interior Design',
          color: 'green'
        }, {
          title: 'Office + Healthcare',
          color: 'blue'
        }, {
          title: 'Residential',
          color: 'yellow'
        }, {
          title: 'Retail + Restaurants',
          color: 'pink'
        }],
        images: [{
          imageURL: 'https://pvadev.cdn.prismic.io/pvadev/dae0d171c7d1f15f5d309c504fec3488b8907178_0-kapolei-golf-course-clubhouse-renovation.jpg',
          projectTitle: 'Kapolei Golf Course Clubhouse Renovation',
          projectHref: true
        }, {
          imageURL: 'https://pvadev.cdn.prismic.io/pvadev/b3c42a97c8851479c463ae8791e5111ada0b4f08_1-makiki-modern-makeover.jpg',
          projectTitle: 'Makiki Modern Makeover',
          projectHref: true
        }, {
          imageURL: 'https://pvadev.cdn.prismic.io/pvadev/8e1f5edf16a17fcd62994d464cc761970eaf4ebd_0-bank-of-hawaii-at-pearlridge.jpg',
          projectTitle: 'Bank of Hawaii at Pearlridge',
          projectHref: true
        }, {
          imageURL: 'https://pvadev.cdn.prismic.io/pvadev/3046499be401eb6a3e849581939ab07c9d21d36e_0-manoa-retreat.jpg',
          projectTitle: 'Manoa Retreat',
          projectHref: true
        }, {
          imageURL: 'https://pvadev.cdn.prismic.io/pvadev/62369a1947659de26225f0ba5cbf58c35ed47cb9_0-sushi-izakaya-dining--shinn.jpg',
          projectTitle: 'Sushi Izakaya Dining: Shinn',
          projectHref: true
        }],
        primaryImageURL: "https://pvadev.cdn.prismic.io/pvadev/dba11a47caa75670e5d319fac411d112d5823ada_0-mokulua-hillside-renovation.jpg"
      });
    }
  }]
};
export var query = "1878973830";