import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.array.index-of";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React from 'react';
import { a } from 'react-spring';
import { getMetadata } from 'helpers';
import { Flex, Text, Link } from 'system';
import { BoundedBox, Heading, Button } from 'src/components';
export var PageBody404 = function PageBody404(_ref) {
  var largeText = _ref.largeText,
      text = _ref.text,
      buttonText = _ref.buttonText,
      buttonHref = _ref.buttonHref,
      props = _objectWithoutPropertiesLoose(_ref, ["largeText", "text", "buttonText", "buttonHref"]);

  return React.createElement(BoundedBox, Object.assign({
    flexGrow: "1"
  }, props), React.createElement(Flex, {
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    height: "100%"
  }, React.createElement(Heading, {
    fontSizeScale: "h",
    color: "green.30",
    fontFamily: "sansExtended",
    letterSpacing: "12px",
    textStyle: "lowercase"
  }, largeText), React.createElement(Text, {
    fontSizeScale: "m-",
    myScale: "l",
    textAlign: "center",
    fontWeight: "light"
  }, text), React.createElement(Button, {
    as: a(Link),
    mtScale: "t",
    href: buttonHref
  }, buttonText)));
};

PageBody404.mapDataToProps = function (_ref2) {
  var _metadata$primary, _metadata$primary$lar, _metadata$primary2, _metadata$primary2$te, _metadata$primary3, _metadata$primary3$bu, _metadata$primary4, _metadata$primary4$bu;

  var meta = _ref2.meta;
  var metadata = getMetadata('PrismicPageMain404', meta === null || meta === void 0 ? void 0 : meta.page);
  return {
    largeText: metadata === null || metadata === void 0 ? void 0 : (_metadata$primary = metadata.primary) === null || _metadata$primary === void 0 ? void 0 : (_metadata$primary$lar = _metadata$primary.large_text) === null || _metadata$primary$lar === void 0 ? void 0 : _metadata$primary$lar.text,
    text: metadata === null || metadata === void 0 ? void 0 : (_metadata$primary2 = metadata.primary) === null || _metadata$primary2 === void 0 ? void 0 : (_metadata$primary2$te = _metadata$primary2.text) === null || _metadata$primary2$te === void 0 ? void 0 : _metadata$primary2$te.text,
    buttonText: metadata === null || metadata === void 0 ? void 0 : (_metadata$primary3 = metadata.primary) === null || _metadata$primary3 === void 0 ? void 0 : (_metadata$primary3$bu = _metadata$primary3.button_text) === null || _metadata$primary3$bu === void 0 ? void 0 : _metadata$primary3$bu.text,
    buttonHref: metadata === null || metadata === void 0 ? void 0 : (_metadata$primary4 = metadata.primary) === null || _metadata$primary4 === void 0 ? void 0 : (_metadata$primary4$bu = _metadata$primary4.button_link) === null || _metadata$primary4$bu === void 0 ? void 0 : _metadata$primary4$bu.url
  };
};

export var query = "3742222989";