import "core-js/modules/es6.function.name";
import "core-js/modules/es6.string.link";
import "core-js/modules/es6.array.map";
import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.array.index-of";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.function.name";
import "core-js/modules/es6.string.link";
import "core-js/modules/es6.object.assign";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.function.name";
import "core-js/modules/es6.string.link";
import "core-js/modules/es6.object.assign";
import _styled from "styled-components";

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React from 'react';
import ConditionalWrap from 'conditional-wrap';
import { themeGet } from '@styled-system/theme-get';
import { propPairsEq } from 'helpers';
import { Grid, Flex, Link } from 'system';
import { BoundedBox, Heading, NavigationItem } from 'src/components';

var _StyledLink = _styled(Link).withConfig({
  displayName: "PageBodyHorizontalNavigation___StyledLink",
  componentId: "sc-8o2slz-0"
})(["white-space:nowrap;&:hover,&:focus{color:", ";}"], function (p) {
  return p._css;
});

export var PageBodyHorizontalNavigation = function PageBodyHorizontalNavigation(_ref) {
  var children = _ref.children,
      title = _ref.title,
      rootHref = _ref.rootHref,
      rootTarget = _ref.rootTarget,
      props = _objectWithoutPropertiesLoose(_ref, ["children", "title", "rootHref", "rootTarget"]);

  return React.createElement(BoundedBox, Object.assign({
    as: "section",
    bg: "gray.90",
    ptScale: "s+",
    pbScale: "m-"
  }, props), React.createElement(Grid, {
    gridGapScale: "xl",
    gridTemplateColumns: ['1fr 1fr', null, 'auto 1fr'],
    alignItems: "baseline"
  }, React.createElement(Heading, {
    textStyle: "lowercase",
    color: "blue.40"
  }, React.createElement(ConditionalWrap, {
    condition: rootHref,
    wrap: function wrap(children) {
      return React.createElement(_StyledLink, {
        href: rootHref,
        target: rootTarget,
        transitionProperty: "color",
        _css: themeGet('colors.blue.40')
      }, children);
    }
  }, title)), React.createElement(Flex, {
    as: "ul",
    flexWrap: "wrap",
    mbScale: "-t-",
    mrScale: [0, '-l']
  }, children)));
};
PageBodyHorizontalNavigation.Item = NavigationItem;

PageBodyHorizontalNavigation.mapDataToProps = function (_ref2) {
  var _data$primary, _data$primary$navigat, _navigationDoc$data, _navigationDoc$data2, _navigationDoc$data2$, _navigationDoc$data3, _navigationDoc$data3$, _navigationDoc$data4, _navigationDoc$data4$, _items$map;

  var data = _ref2.data,
      context = _ref2.context,
      nextContext = _ref2.nextContext;
  var navigationDoc = data === null || data === void 0 ? void 0 : (_data$primary = data.primary) === null || _data$primary === void 0 ? void 0 : (_data$primary$navigat = _data$primary.navigation) === null || _data$primary$navigat === void 0 ? void 0 : _data$primary$navigat.document;
  var items = navigationDoc === null || navigationDoc === void 0 ? void 0 : (_navigationDoc$data = navigationDoc.data) === null || _navigationDoc$data === void 0 ? void 0 : _navigationDoc$data.primary;
  return {
    nextSharesBg: propPairsEq('bg', context, nextContext),
    title: navigationDoc === null || navigationDoc === void 0 ? void 0 : (_navigationDoc$data2 = navigationDoc.data) === null || _navigationDoc$data2 === void 0 ? void 0 : (_navigationDoc$data2$ = _navigationDoc$data2.title) === null || _navigationDoc$data2$ === void 0 ? void 0 : _navigationDoc$data2$.text,
    rootHref: navigationDoc === null || navigationDoc === void 0 ? void 0 : (_navigationDoc$data3 = navigationDoc.data) === null || _navigationDoc$data3 === void 0 ? void 0 : (_navigationDoc$data3$ = _navigationDoc$data3.root) === null || _navigationDoc$data3$ === void 0 ? void 0 : _navigationDoc$data3$.url,
    rootTarget: navigationDoc === null || navigationDoc === void 0 ? void 0 : (_navigationDoc$data4 = navigationDoc.data) === null || _navigationDoc$data4 === void 0 ? void 0 : (_navigationDoc$data4$ = _navigationDoc$data4.root) === null || _navigationDoc$data4$ === void 0 ? void 0 : _navigationDoc$data4$.target,
    children: items === null || items === void 0 ? void 0 : (_items$map = items.map) === null || _items$map === void 0 ? void 0 : _items$map.call(items, function (item) {
      var _item$primary, _item$primary$link, _item$primary2, _item$primary2$link, _item$primary3;

      return React.createElement(PageBodyHorizontalNavigation.Item, {
        key: item === null || item === void 0 ? void 0 : item.id,
        href: item === null || item === void 0 ? void 0 : (_item$primary = item.primary) === null || _item$primary === void 0 ? void 0 : (_item$primary$link = _item$primary.link) === null || _item$primary$link === void 0 ? void 0 : _item$primary$link.url,
        target: item === null || item === void 0 ? void 0 : (_item$primary2 = item.primary) === null || _item$primary2 === void 0 ? void 0 : (_item$primary2$link = _item$primary2.link) === null || _item$primary2$link === void 0 ? void 0 : _item$primary2$link.target
      }, item === null || item === void 0 ? void 0 : (_item$primary3 = item.primary) === null || _item$primary3 === void 0 ? void 0 : _item$primary3.name);
    })
  };
};

PageBodyHorizontalNavigation.mapDataToContext = function () {
  return {
    bg: 'gray.90'
  };
};

PageBodyHorizontalNavigation.docs = {
  name: 'Horizontal Navigation',
  description: 'Navigation links that appear at the top of a page.',
  preset: true,
  examples: [{
    name: 'Navigation',
    render: function render() {
      return React.createElement(PageBodyHorizontalNavigation, {
        title: "design",
        rootHref: "/",
        nextSharesBg: [false]
      }, React.createElement(PageBodyHorizontalNavigation.Item, {
        href: "/"
      }, "Hospitality + Leisure"), React.createElement(PageBodyHorizontalNavigation.Item, {
        href: "/"
      }, "Interior Design"), React.createElement(PageBodyHorizontalNavigation.Item, {
        href: "/"
      }, "Office + Healthcare"), React.createElement(PageBodyHorizontalNavigation.Item, {
        href: "/"
      }, "Residential"), React.createElement(PageBodyHorizontalNavigation.Item, {
        href: "/"
      }, "Retail + Restaurants"));
    }
  }]
};
export var fragment = "3789823327";