import "core-js/modules/es6.array.fill";
import "core-js/modules/es6.array.filter";
import "core-js/modules/es6.array.for-each";
import "core-js/modules/es6.array.map";
import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.regexp.search";
import "core-js/modules/es6.array.index-of";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.array.fill";
import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.regexp.search";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.array.fill";
import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.regexp.search";

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React, { useRef, useLayoutEffect } from 'react';
import { notEmpty, getMetadata, propPairsEq } from 'helpers';
import { useLoadMorePagination, useProjects } from 'src/hooks';
import { Grid } from 'system';
import { BoundedBox, ProjectCard, Button } from 'src/components';
export var PageBodyAllSmallProjects = function PageBodyAllSmallProjects(_ref) {
  var _projects$map;

  var projectCategoryUID = _ref.projectCategoryUID,
      useLoadMore = _ref.useLoadMore,
      excludeUIDs = _ref.excludeUIDs,
      props = _objectWithoutPropertiesLoose(_ref, ["projectCategoryUID", "useLoadMore", "excludeUIDs"]);

  var nodes = useProjects(projectCategoryUID, {
    excludeUIDs: excludeUIDs
  });
  var containerRef = useRef();
  var hasInitializedRef = useRef(false);

  var _useLoadMorePaginatio = useLoadMorePagination({
    collection: nodes,
    initialPage: 0,
    perPage: 8,
    enabled: useLoadMore
  }),
      paginatedNodes = _useLoadMorePaginatio[0],
      loadMore = _useLoadMorePaginatio[1],
      canLoadMore = _useLoadMorePaginatio[2];

  var projects = paginatedNodes || nodes;
  useLayoutEffect(function () {
    var container = containerRef.current;
    var hasInitialized = hasInitializedRef.current;
    var currPage = new URLSearchParams(location.search).get('page');
    if (!container || hasInitialized || !(paginatedNodes === null || paginatedNodes === void 0 ? void 0 : paginatedNodes.length) || !currPage) return;
    hasInitializedRef.current = true;
    container.scrollIntoView({
      block: 'end'
    });
  }, [paginatedNodes]);
  return React.createElement(BoundedBox, Object.assign({
    as: "section"
  }, props), React.createElement(Grid, {
    ref: containerRef,
    gridTemplateColumns: ['1fr', 'repeat(2, 1fr)', 'repeat(4, 1fr)'],
    gridGapScale: "l"
  }, projects === null || projects === void 0 ? void 0 : (_projects$map = projects.map) === null || _projects$map === void 0 ? void 0 : _projects$map.call(projects, function (project) {
    var _metadata$items, _metadata$items$, _metadata$items$$proj, _metadata$items$$proj2, _metadata$primary, _metadata$primary$fea, _metadata$primary2, _metadata$primary2$fe, _metadata$primary3, _metadata$primary3$fe, _metadata$primary4, _metadata$primary4$lo, _project$data, _project$data$title;

    var metadata = getMetadata('PrismicPageMainProject', project);
    return React.createElement(ProjectCard, {
      variant: "small",
      key: project === null || project === void 0 ? void 0 : project.uid,
      categoryUID: metadata === null || metadata === void 0 ? void 0 : (_metadata$items = metadata.items) === null || _metadata$items === void 0 ? void 0 : (_metadata$items$ = _metadata$items[0]) === null || _metadata$items$ === void 0 ? void 0 : (_metadata$items$$proj = _metadata$items$.project_category) === null || _metadata$items$$proj === void 0 ? void 0 : (_metadata$items$$proj2 = _metadata$items$$proj.document) === null || _metadata$items$$proj2 === void 0 ? void 0 : _metadata$items$$proj2.uid,
      href: project === null || project === void 0 ? void 0 : project.url,
      imageAlt: notEmpty(metadata === null || metadata === void 0 ? void 0 : (_metadata$primary = metadata.primary) === null || _metadata$primary === void 0 ? void 0 : (_metadata$primary$fea = _metadata$primary.featured_image) === null || _metadata$primary$fea === void 0 ? void 0 : _metadata$primary$fea.alt),
      imageFluid: metadata === null || metadata === void 0 ? void 0 : (_metadata$primary2 = metadata.primary) === null || _metadata$primary2 === void 0 ? void 0 : (_metadata$primary2$fe = _metadata$primary2.featured_image) === null || _metadata$primary2$fe === void 0 ? void 0 : _metadata$primary2$fe.fluid,
      imageURL: metadata === null || metadata === void 0 ? void 0 : (_metadata$primary3 = metadata.primary) === null || _metadata$primary3 === void 0 ? void 0 : (_metadata$primary3$fe = _metadata$primary3.featured_image) === null || _metadata$primary3$fe === void 0 ? void 0 : _metadata$primary3$fe.url,
      location: metadata === null || metadata === void 0 ? void 0 : (_metadata$primary4 = metadata.primary) === null || _metadata$primary4 === void 0 ? void 0 : (_metadata$primary4$lo = _metadata$primary4.location) === null || _metadata$primary4$lo === void 0 ? void 0 : _metadata$primary4$lo.text,
      name: project === null || project === void 0 ? void 0 : (_project$data = project.data) === null || _project$data === void 0 ? void 0 : (_project$data$title = _project$data.title) === null || _project$data$title === void 0 ? void 0 : _project$data$title.text
    });
  })), canLoadMore && React.createElement(Button, {
    mtScale: "l",
    onClick: function onClick() {
      hasInitializedRef.current = true;
      loadMore();
    },
    arrowDirection: "down"
  }, "Load More"));
};

PageBodyAllSmallProjects.mapDataToProps = function (_ref2) {
  var _meta$page, _meta$page$data, _meta$page$data$body, _data$primary, _data$primary$project, _data$primary2;

  var data = _ref2.data,
      meta = _ref2.meta,
      context = _ref2.context,
      nextContext = _ref2.nextContext;
  var excludeUIDs = []; // Exclude any project displayed on the page via project card slices.

  meta === null || meta === void 0 ? void 0 : (_meta$page = meta.page) === null || _meta$page === void 0 ? void 0 : (_meta$page$data = _meta$page.data) === null || _meta$page$data === void 0 ? void 0 : (_meta$page$data$body = _meta$page$data.body) === null || _meta$page$data$body === void 0 ? void 0 : _meta$page$data$body.forEach(function (slice) {
    var _slice$primary3, _slice$primary3$large, _slice$primary3$large2;

    switch (slice === null || slice === void 0 ? void 0 : slice.__typename) {
      case 'PrismicPageBodySmallProjects':
        Array(4).fill().map(function (_, i) {
          var _slice$primary, _slice$primary$, _slice$primary$$docum;

          return slice === null || slice === void 0 ? void 0 : (_slice$primary = slice.primary) === null || _slice$primary === void 0 ? void 0 : (_slice$primary$ = _slice$primary["project_" + (i + 1)]) === null || _slice$primary$ === void 0 ? void 0 : (_slice$primary$$docum = _slice$primary$.document) === null || _slice$primary$$docum === void 0 ? void 0 : _slice$primary$$docum.uid;
        }).filter(Boolean).forEach(function (uid) {
          return excludeUIDs.push(uid);
        });
        break;

      case 'PrismicPageBodyMixedSizedProjects':
        Array(4).fill().map(function (_, i) {
          var _slice$primary2, _slice$primary2$, _slice$primary2$$docu;

          return slice === null || slice === void 0 ? void 0 : (_slice$primary2 = slice.primary) === null || _slice$primary2 === void 0 ? void 0 : (_slice$primary2$ = _slice$primary2["small_project_" + (i + 1)]) === null || _slice$primary2$ === void 0 ? void 0 : (_slice$primary2$$docu = _slice$primary2$.document) === null || _slice$primary2$$docu === void 0 ? void 0 : _slice$primary2$$docu.uid;
        }).filter(Boolean).forEach(function (uid) {
          return excludeUIDs.push(uid);
        });
        excludeUIDs.push(slice === null || slice === void 0 ? void 0 : (_slice$primary3 = slice.primary) === null || _slice$primary3 === void 0 ? void 0 : (_slice$primary3$large = _slice$primary3.large_project_1) === null || _slice$primary3$large === void 0 ? void 0 : (_slice$primary3$large2 = _slice$primary3$large.document) === null || _slice$primary3$large2 === void 0 ? void 0 : _slice$primary3$large2.uid);
        break;

      case 'PrismicPageBodyFeaturedProjects':
        Array(2).fill().map(function (_, i) {
          var _slice$primary4, _slice$primary4$, _slice$primary4$$docu;

          return slice === null || slice === void 0 ? void 0 : (_slice$primary4 = slice.primary) === null || _slice$primary4 === void 0 ? void 0 : (_slice$primary4$ = _slice$primary4["project_" + (i + 1)]) === null || _slice$primary4$ === void 0 ? void 0 : (_slice$primary4$$docu = _slice$primary4$.document) === null || _slice$primary4$$docu === void 0 ? void 0 : _slice$primary4$$docu.uid;
        }).filter(Boolean).forEach(function (uid) {
          return excludeUIDs.push(uid);
        });
        break;
    }
  });
  return {
    nextSharesBg: propPairsEq('bg', context, nextContext),
    projectCategoryUID: data === null || data === void 0 ? void 0 : (_data$primary = data.primary) === null || _data$primary === void 0 ? void 0 : (_data$primary$project = _data$primary.project_category) === null || _data$primary$project === void 0 ? void 0 : _data$primary$project.uid,
    useLoadMore: data === null || data === void 0 ? void 0 : (_data$primary2 = data.primary) === null || _data$primary2 === void 0 ? void 0 : _data$primary2.use_load_more,
    excludeUIDs: excludeUIDs
  };
};

PageBodyAllSmallProjects.mapDataToContext = function () {
  return {
    bg: 'white'
  };
};