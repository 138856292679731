import "core-js/modules/es6.array.map";
import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.array.index-of";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React from 'react';
import { a } from 'react-spring';
import { getRichText, getMetadata, notEmpty } from 'helpers';
import kebabCase from 'lodash.kebabcase';
import { Grid, Box, ImageContainer, Image, AspectRatio, Link } from 'system';
import { BoundedBox, Heading, Button, Subheading, ProjectCard, HTMLContent } from 'src/components';
var variations = {
  left: {
    featuredImageGridColumns: [null, '1 / -1', '5 / span 4', '4 / span 5'],
    textGridColumns: [null, '2 / span 4', '1 / span 4', '1 / span 3'],
    featuredProjectGridColumns: [null, '1 / -1', null, '4 / span 5']
  },
  right: {
    featuredImageGridColumns: [null, '1 / -1', 'span 4', '1 / span 5'],
    textGridColumns: [null, '2 / span 4', 'span 4', '6 / span 3'],
    featuredProjectGridColumns: [null, '1 / -1', null, '1 / span 5']
  }
};
export var PageBodyService = function PageBodyService(_ref) {
  var textAlign = _ref.textAlign,
      title = _ref.title,
      description = _ref.description,
      buttonText = _ref.buttonText,
      buttonLink = _ref.buttonLink,
      featuredImageURL = _ref.featuredImageURL,
      featuredImageAlt = _ref.featuredImageAlt,
      featuredImageFluid = _ref.featuredImageFluid,
      children = _ref.children,
      spacerHR = _ref.spacerHR,
      props = _objectWithoutPropertiesLoose(_ref, ["textAlign", "title", "description", "buttonText", "buttonLink", "featuredImageURL", "featuredImageAlt", "featuredImageFluid", "children", "spacerHR"]);

  var variant = variations[textAlign];
  return React.createElement(BoundedBox, Object.assign({
    as: "section",
    id: kebabCase(title)
  }, props), React.createElement(Grid, {
    gridTemplateColumns: ['1fr', 'repeat(6, 1fr)', 'repeat(8, 1fr)'],
    gridAutoFlow: "row dense",
    gridGapScale: "l"
  }, React.createElement(Box, {
    gridColumn: variant.featuredImageGridColumns
  }, (featuredImageURL || featuredImageFluid) && React.createElement(ImageContainer, {
    bg: "gray.10"
  }, React.createElement(AspectRatio, {
    x: [16, 15, 16, null, 15],
    y: [9, 6, 9, null, 8]
  }, React.createElement(Image, {
    src: featuredImageURL,
    fluid: featuredImageFluid,
    alt: featuredImageAlt
  }))), React.createElement(Box, {
    display: ['none', null, null, 'block'],
    mtScale: "m"
  }, React.createElement(Subheading, {
    gridColumn: variant.featuredProjectGridColumns,
    mbScale: "s",
    color: "gray.70"
  }, "Featured projects"), React.createElement(Grid, {
    gridColumn: variant.featuredProjectGridColumns,
    gridGapScale: "l",
    gridTemplateColumns: ['1fr', 'repeat(3, 1fr)']
  }, children))), React.createElement(Box, {
    gridColumn: variant.textGridColumns,
    pyScale: [0, 'm', 0]
  }, title && React.createElement(Heading, {
    fontSizeScale: "l",
    fontWeight: "light",
    mbScale: "m",
    color: "blue.40"
  }, title), React.createElement(HTMLContent, {
    html: description
  }), buttonLink && React.createElement(Button, {
    as: a(Link),
    href: buttonLink,
    mtScale: "m"
  }, buttonText)), React.createElement(Box, {
    gridColumn: variant.featuredProjectGridColumns,
    display: ['block', null, null, 'none']
  }, React.createElement(Subheading, {
    mbScale: "s",
    color: "gray.70"
  }, "Featured projects"), React.createElement(Grid, {
    gridGapScale: "l",
    gridTemplateColumns: ['1fr', 'repeat(3, 1fr)']
  }, children)), spacerHR && React.createElement(Box, {
    borderBottom: ['1px solid', '2px solid'],
    borderColor: ['gray.70', 'gray.70'],
    ptScale: [0, 'm'],
    gridColumn: ['1 / -1', null, null, '1 / -1']
  })));
};
PageBodyService.FeaturedProject = ProjectCard;

PageBodyService.mapDataToProps = function (_ref2) {
  var _primary$title, _primary$button_text, _primary$button_link, _primary$featured_ima, _primary$featured_ima2, _primary$featured_ima3, _data$items, _data$items$map;

  var data = _ref2.data,
      nextContext = _ref2.nextContext;
  var primary = data === null || data === void 0 ? void 0 : data.primary;
  return {
    textAlign: primary === null || primary === void 0 ? void 0 : primary.text_align,
    title: primary === null || primary === void 0 ? void 0 : (_primary$title = primary.title1) === null || _primary$title === void 0 ? void 0 : _primary$title.text,
    description: getRichText(primary === null || primary === void 0 ? void 0 : primary.description),
    buttonText: primary === null || primary === void 0 ? void 0 : (_primary$button_text = primary.button_text) === null || _primary$button_text === void 0 ? void 0 : _primary$button_text.text,
    buttonLink: primary === null || primary === void 0 ? void 0 : (_primary$button_link = primary.button_link) === null || _primary$button_link === void 0 ? void 0 : _primary$button_link.url,
    featuredImageURL: primary === null || primary === void 0 ? void 0 : (_primary$featured_ima = primary.featured_image) === null || _primary$featured_ima === void 0 ? void 0 : _primary$featured_ima.url,
    featuredImageAlt: primary === null || primary === void 0 ? void 0 : (_primary$featured_ima2 = primary.featured_image) === null || _primary$featured_ima2 === void 0 ? void 0 : _primary$featured_ima2.alt,
    featuredImageFluid: primary === null || primary === void 0 ? void 0 : (_primary$featured_ima3 = primary.featured_image) === null || _primary$featured_ima3 === void 0 ? void 0 : _primary$featured_ima3.fluid,
    spacerHR: nextContext === null || nextContext === void 0 ? void 0 : nextContext.spacerHR,
    children: data === null || data === void 0 ? void 0 : (_data$items = data.items) === null || _data$items === void 0 ? void 0 : (_data$items$map = _data$items.map) === null || _data$items$map === void 0 ? void 0 : _data$items$map.call(_data$items, function (item) {
      var _item$featured_projec, _featuredProjectDoc$d, _featuredProjectDoc$d2, _metadata$items, _metadata$items$, _metadata$items$$proj, _metadata$items$$proj2, _metadata$primary, _metadata$primary$fea, _metadata$primary2, _metadata$primary2$fe, _metadata$primary3, _metadata$primary3$fe, _metadata$primary4, _metadata$primary4$lo, _featuredProjectDoc$d3, _featuredProjectDoc$d4;

      var featuredProjectDoc = item === null || item === void 0 ? void 0 : (_item$featured_projec = item.featured_projects) === null || _item$featured_projec === void 0 ? void 0 : _item$featured_projec.document;
      var metadata = getMetadata('PrismicPageMainProject', featuredProjectDoc);
      return React.createElement(PageBodyService.FeaturedProject, {
        key: featuredProjectDoc === null || featuredProjectDoc === void 0 ? void 0 : (_featuredProjectDoc$d = featuredProjectDoc.data) === null || _featuredProjectDoc$d === void 0 ? void 0 : (_featuredProjectDoc$d2 = _featuredProjectDoc$d.title) === null || _featuredProjectDoc$d2 === void 0 ? void 0 : _featuredProjectDoc$d2.text,
        variant: "wideSmall",
        categoryUID: metadata === null || metadata === void 0 ? void 0 : (_metadata$items = metadata.items) === null || _metadata$items === void 0 ? void 0 : (_metadata$items$ = _metadata$items[0]) === null || _metadata$items$ === void 0 ? void 0 : (_metadata$items$$proj = _metadata$items$.project_category) === null || _metadata$items$$proj === void 0 ? void 0 : (_metadata$items$$proj2 = _metadata$items$$proj.document) === null || _metadata$items$$proj2 === void 0 ? void 0 : _metadata$items$$proj2.uid,
        href: featuredProjectDoc === null || featuredProjectDoc === void 0 ? void 0 : featuredProjectDoc.url,
        imageAlt: notEmpty(metadata === null || metadata === void 0 ? void 0 : (_metadata$primary = metadata.primary) === null || _metadata$primary === void 0 ? void 0 : (_metadata$primary$fea = _metadata$primary.featured_image) === null || _metadata$primary$fea === void 0 ? void 0 : _metadata$primary$fea.alt),
        imageFluid: metadata === null || metadata === void 0 ? void 0 : (_metadata$primary2 = metadata.primary) === null || _metadata$primary2 === void 0 ? void 0 : (_metadata$primary2$fe = _metadata$primary2.featured_image) === null || _metadata$primary2$fe === void 0 ? void 0 : _metadata$primary2$fe.fluid,
        imageURL: metadata === null || metadata === void 0 ? void 0 : (_metadata$primary3 = metadata.primary) === null || _metadata$primary3 === void 0 ? void 0 : (_metadata$primary3$fe = _metadata$primary3.featured_image) === null || _metadata$primary3$fe === void 0 ? void 0 : _metadata$primary3$fe.url,
        location: metadata === null || metadata === void 0 ? void 0 : (_metadata$primary4 = metadata.primary) === null || _metadata$primary4 === void 0 ? void 0 : (_metadata$primary4$lo = _metadata$primary4.location) === null || _metadata$primary4$lo === void 0 ? void 0 : _metadata$primary4$lo.text,
        name: featuredProjectDoc === null || featuredProjectDoc === void 0 ? void 0 : (_featuredProjectDoc$d3 = featuredProjectDoc.data) === null || _featuredProjectDoc$d3 === void 0 ? void 0 : (_featuredProjectDoc$d4 = _featuredProjectDoc$d3.title) === null || _featuredProjectDoc$d4 === void 0 ? void 0 : _featuredProjectDoc$d4.text
      });
    })
  };
};

PageBodyService.mapDataToContext = function () {
  return {
    bg: 'white',
    spacerHR: true
  };
};

export var fragment = "3403321418";

var example = function example(props) {
  return React.createElement(PageBodyService, Object.assign({
    title: "Architecture",
    spacerHR: true,
    description: "<p>Peter Vincent Architects provides the full range of architectural services including design services for new buildings and homes, tenant build-out, remodeling and interior architecture.</p><p>Peter Vincent Architects is registered in California, Nevada, Colorado, Hawaii, Massachusetts, and Japan and is certified by the National Council of Architectural Registration Boards (NCARB).</p>",
    textAlign: "left"
  }, props), React.createElement(PageBodyService.FeaturedProject, {
    variant: "wideSmall",
    categoryUID: "office-healthcare",
    href: true,
    imageURL: "https://pvadev.cdn.prismic.io/pvadev/e1d47f8f8ffe39330c19d3a01ccc34b4c59bb4b4_0-bank-of-hawaii.jpg",
    location: "Honolulu, Hawaii",
    name: "Bank of Hawaii"
  }), React.createElement(PageBodyService.FeaturedProject, {
    variant: "wideSmall",
    categoryUID: "residential",
    href: true,
    imageURL: "https://pvadev.cdn.prismic.io/pvadev/572844f3e2e1fadb33dabd0253b031d48ce32c36_0-makiki-modern-makeover.jpg",
    location: "Honolulu, Hawaii",
    name: "Makiki Modern Makeover"
  }), React.createElement(PageBodyService.FeaturedProject, {
    variant: "wideSmall",
    categoryUID: "hospitality-leisure",
    href: true,
    imageURL: "https://pvadev.cdn.prismic.io/pvadev/dae0d171c7d1f15f5d309c504fec3488b8907178_0-kapolei-golf-course-clubhouse-renovation.jpg",
    location: "Honolulu, Hawaii",
    name: "Kapolei Golfcourse Clubhouse Renovation"
  }));
};

PageBodyService.docs = {
  name: 'Service',
  examples: [{
    name: 'Image on Right',
    render: function render() {
      return example({
        featuredImageURL: 'https://pvadev.cdn.prismic.io/pvadev/91184ab98a472d86b418264c12d683d58a6eb44d_e212ba4a167b5f008e0b26191968198d7804e9ea.png'
      });
    }
  }, {
    name: 'Image on Left',
    render: function render() {
      return example({
        textAlign: 'right',
        featuredImageURL: 'https://images.prismic.io/pvadev/8337691f72f5dd6901b6f5f1a5510b5555111132_pva_alstonhome2166kitchenone_web.jpg?auto=compress,format'
      });
    }
  }]
};