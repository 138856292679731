import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.array.index-of";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React from 'react';
import { getRichText, notEmpty, propPairsEq, getPrimaryMetadataType } from 'helpers';
import { Grid, Box } from 'system';
import { BoundedBox, HTMLContent, CaptionImage } from 'src/components';
var variants = {
  normal: {
    contentGridColumn: [null, null, '1 / span 6', "3 / -3"],
    textGridColumn: [null, null, 'span 2']
  },
  wide: {
    contentGridColumn: [null, null, '1 / span 6', "2 / -3"],
    textGridColumn: [null, null, 'span 2']
  },
  news: {
    contentGridColumn: [null, null, '2 / span 4', "2 / -4", "3 / span 3"],
    textGridColumn: [null, null, 'span 2', null, 'span 1']
  }
};
export var PageBodyImageWithText = function PageBodyImageWithText(_ref) {
  var _ref$variant = _ref.variant,
      variantName = _ref$variant === void 0 ? 'normal' : _ref$variant,
      imageAlt = _ref.imageAlt,
      imageURL = _ref.imageURL,
      imageFluid = _ref.imageFluid,
      captionHTML = _ref.captionHTML,
      textHTML = _ref.textHTML,
      props = _objectWithoutPropertiesLoose(_ref, ["variant", "imageAlt", "imageURL", "imageFluid", "captionHTML", "textHTML"]);

  var variant = variants[variantName];
  return React.createElement(BoundedBox, Object.assign({
    as: "section"
  }, props), React.createElement(Grid, {
    gridTemplateColumns: ['1fr', null, 'repeat(8, 1fr)'],
    gridGapScale: "l"
  }, (imageFluid || imageURL) && React.createElement(CaptionImage, {
    src: imageURL,
    alt: imageAlt,
    fluid: imageFluid,
    captionHTML: captionHTML,
    gridColumn: variant === null || variant === void 0 ? void 0 : variant.contentGridColumn
  }), React.createElement(HTMLContent, {
    html: textHTML,
    gridColumn: variant === null || variant === void 0 ? void 0 : variant.textGridColumn,
    componentOverrides: {
      h1: function h1(Comp) {
        return function (props) {
          return React.createElement(React.Fragment, null, React.createElement(Box, {
            boxStyle: "firstLastNoMargin",
            width: "2.5rem",
            borderBottom: "2px solid",
            borderColor: "blue.40",
            mtScale: "xl",
            mbScale: "t"
          }), React.createElement(Comp, Object.assign({
            fontWeight: "bolder",
            fontSizeScale: "b",
            fontFamily: "sans",
            color: "blue.40",
            mbScale: "s"
          }, props)));
        };
      },
      p: function p(Comp) {
        return function (props) {
          return React.createElement(Comp, Object.assign({}, props, {
            mbScale: "s",
            boxStyle: "lastNoMargin"
          }));
        };
      }
    }
  })));
};

PageBodyImageWithText.mapDataToProps = function (_ref2) {
  var _primary$image, _primary$image2, _primary$image3;

  var data = _ref2.data,
      meta = _ref2.meta,
      context = _ref2.context,
      nextContext = _ref2.nextContext;
  var primary = data === null || data === void 0 ? void 0 : data.primary;
  var primaryMetadataType = getPrimaryMetadataType(meta === null || meta === void 0 ? void 0 : meta.page);
  var variantsMap = {
    PrismicPageMainNews: 'news',
    PrismicPageMainProject: 'wide'
  };
  var variant = variantsMap[primaryMetadataType];
  return {
    nextSharesBg: propPairsEq('bg', context, nextContext),
    variant: variant,
    imageAlt: notEmpty(primary === null || primary === void 0 ? void 0 : (_primary$image = primary.image) === null || _primary$image === void 0 ? void 0 : _primary$image.alt),
    imageURL: primary === null || primary === void 0 ? void 0 : (_primary$image2 = primary.image) === null || _primary$image2 === void 0 ? void 0 : _primary$image2.url,
    imageFluid: primary === null || primary === void 0 ? void 0 : (_primary$image3 = primary.image) === null || _primary$image3 === void 0 ? void 0 : _primary$image3.fluid,
    captionHTML: getRichText(primary === null || primary === void 0 ? void 0 : primary.caption),
    textHTML: getRichText(primary === null || primary === void 0 ? void 0 : primary.text)
  };
};

PageBodyImageWithText.mapDataToContext = function () {
  return {
    bg: 'white'
  };
};

export var fragment = "1184305599";
PageBodyImageWithText.docs = {
  name: 'Image With Text',
  examples: [{
    name: 'Image With Text',
    render: function render() {
      return React.createElement(PageBodyImageWithText, {
        imageURL: "https://pvadev.cdn.prismic.io/pvadev/3e13e4322ac9be327900de228f0e684bdf579403_4-hale-palekaiko.jpg",
        imageAlt: "Example slice.",
        textHTML: "<p>After nearly 15 years, the project was completed in early 2015. The design created a contemporary Hawaiian-style cluster of buildings featuring a two-story main house, guesthouse, pool, BBQ pavilion, and auto court. The layout successfully maximizes natural ventilation and views, while also buffering the living, lanai, and outdoor spaces from the trade winds and optimizing areas for outdoor living.</p>"
      });
    }
  }]
};