import "core-js/modules/es6.array.map";
import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.array.index-of";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React from 'react';
import { getRichText, propPairsEq, notEmpty } from 'helpers';
import { Grid, Box } from 'system';
import { BoundedBox, HTMLContent, Heading, Subheading } from 'src/components';
export var PageBodyCareerOpenings = function PageBodyCareerOpenings(_ref) {
  var evergreenTextHTML = _ref.evergreenTextHTML,
      children = _ref.children,
      props = _objectWithoutPropertiesLoose(_ref, ["evergreenTextHTML", "children"]);

  return React.createElement(BoundedBox, Object.assign({
    as: "section"
  }, props), React.createElement(Grid, {
    gridTemplateColumns: ['1fr', null, 'repeat(8, 1fr)'],
    gridGapScale: "l"
  }, React.createElement(Subheading, {
    textStyle: "none",
    gridColumn: ['1 / -1', null, '3 / span 6'],
    mbScale: "-s",
    display: ['none', 'block']
  }, "Current Openings"), children, evergreenTextHTML && React.createElement(Grid, {
    gridColumn: ['1 / -1', null, '3 / span 6'],
    gridTemplateColumns: ['1fr', null, null, 'repeat(3, 1fr)', '1fr 1fr .5fr'],
    pScale: "xl",
    bg: "gray.90"
  }, React.createElement(HTMLContent, {
    html: evergreenTextHTML,
    gridColumn: "span 2"
  }))));
};

PageBodyCareerOpenings.CareerOpening = function (_ref2) {
  var positionTitle = _ref2.positionTitle,
      descriptionHTML = _ref2.descriptionHTML,
      qualificationsHTML = _ref2.qualificationsHTML,
      toApplyHTML = _ref2.toApplyHTML,
      props = _objectWithoutPropertiesLoose(_ref2, ["positionTitle", "descriptionHTML", "qualificationsHTML", "toApplyHTML"]);

  return React.createElement(Grid, Object.assign({
    gridColumn: ['1 / -1', null, '3 / span 6'],
    bg: "gray.90",
    pScale: "xl",
    gridTemplateColumns: ['1fr', null, null, 'repeat(3, 1fr)', '1fr 1fr .5fr'],
    gridColumnGapScale: "xl",
    gridRowGapScale: "l"
  }, props), positionTitle && React.createElement(Heading, {
    color: "blue.40",
    gridColumn: "1 / -1"
  }, positionTitle), descriptionHTML && React.createElement(Box, null, React.createElement(Subheading, {
    color: "gray.70",
    mbScale: "t"
  }, "Description"), React.createElement(HTMLContent, {
    html: descriptionHTML
  })), qualificationsHTML && React.createElement(Box, null, React.createElement(Subheading, {
    color: "gray.70",
    mbScale: "t"
  }, "Qualifications"), React.createElement(HTMLContent, {
    html: qualificationsHTML
  })), toApplyHTML && React.createElement(Box, null, React.createElement(Subheading, {
    color: "gray.70",
    mbScale: "t"
  }, "To Apply"), React.createElement(HTMLContent, {
    html: toApplyHTML
  })));
};

PageBodyCareerOpenings.mapDataToProps = function (_ref3) {
  var _data$primary, _data$items, _data$items$map;

  var data = _ref3.data,
      context = _ref3.context,
      nextContext = _ref3.nextContext;
  return {
    nextSharesBg: propPairsEq('bg', context, nextContext),
    evergreenTextHTML: getRichText(data === null || data === void 0 ? void 0 : (_data$primary = data.primary) === null || _data$primary === void 0 ? void 0 : _data$primary.evergreen_text),
    children: data === null || data === void 0 ? void 0 : (_data$items = data.items) === null || _data$items === void 0 ? void 0 : (_data$items$map = _data$items.map) === null || _data$items$map === void 0 ? void 0 : _data$items$map.call(_data$items, function (item) {
      var _notEmpty, _item$position_title;

      return React.createElement(PageBodyCareerOpenings.CareerOpening, {
        positionTitle: (_notEmpty = notEmpty(item === null || item === void 0 ? void 0 : (_item$position_title = item.position_title) === null || _item$position_title === void 0 ? void 0 : _item$position_title.text)) === null || _notEmpty === void 0 ? void 0 : _notEmpty.toLowerCase(),
        toApplyHTML: getRichText(item === null || item === void 0 ? void 0 : item.to_apply_text),
        qualificationsHTML: getRichText(item === null || item === void 0 ? void 0 : item.qualifications),
        descriptionHTML: getRichText(item === null || item === void 0 ? void 0 : item.description)
      });
    })
  };
};

PageBodyCareerOpenings.mapDataToContext = function () {
  return {
    bg: 'white'
  };
};

PageBodyCareerOpenings.docs = {
  name: 'Career Openings',
  examples: [{
    name: 'With one opening',
    render: function render() {
      return React.createElement(PageBodyCareerOpenings, {
        evergreenTextHTML: "<p><strong>We're always looking for exceptional people to join our team.</strong></p><p>If you wish to inquire about employment opportunities but don't see an open position matching your experience level, we invite you to submit your r\xE9sum\xE9 and portfolio to <a>HR@pva.com</a>.</p>"
      }, React.createElement(PageBodyCareerOpenings.CareerOpening, {
        positionTitle: "project architect",
        qualificationsHTML: "<ul><li>Degree in Architecture</li><li>5-10 years of architectural design experience</li><li>Proficiency in Microsoft Office and ArchiCAD required</li><li>An architectural license and high-end residential and commercial market experience preferred.</li></ul>",
        descriptionHTML: "<p>PVA is seeking a Project Architect to join our team! The ideal candidate should be self-motivated, a team player with strong technical, organizational and communication skills.</p>",
        toApplyHTML: "<p>Submit your r\xE9sum\xE9 and portfolio to HR@pva.com.</p>"
      }));
    }
  }, {
    name: 'With two opening, no evergreen text',
    render: function render() {
      return React.createElement(PageBodyCareerOpenings, null, React.createElement(PageBodyCareerOpenings.CareerOpening, {
        positionTitle: "project architect",
        qualificationsHTML: "<ul><li>Degree in Architecture</li><li>5-10 years of architectural design experience</li><li>Proficiency in Microsoft Office and ArchiCAD required</li><li>An architectural license and high-end residential and commercial market experience preferred.</li></ul>",
        descriptionHTML: "<p>PVA is seeking a Project Architect to join our team! The ideal candidate should be self-motivated, a team player with strong technical, organizational and communication skills.</p>",
        toApplyHTML: "<p>Submit your r\xE9sum\xE9 and portfolio to HR@pva.com.</p>"
      }), React.createElement(PageBodyCareerOpenings.CareerOpening, {
        positionTitle: "project architect",
        qualificationsHTML: "<ul><li>Degree in Architecture</li><li>5-10 years of architectural design experience</li><li>Proficiency in Microsoft Office and ArchiCAD required</li><li>An architectural license and high-end residential and commercial market experience preferred.</li></ul>",
        descriptionHTML: "<p>PVA is seeking a Project Architect to join our team! The ideal candidate should be self-motivated, a team player with strong technical, organizational and communication skills.</p>",
        toApplyHTML: "<p>Submit your r\xE9sum\xE9 and portfolio to HR@pva.com.</p>"
      }));
    }
  }]
};
export var fragment = "3949330454";