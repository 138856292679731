import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.array.index-of";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React, { useState } from 'react';
import { useGesture } from 'react-use-gesture';
import { linearScale } from 'styled-system-scale';
import { getPrimaryMetadataType } from 'helpers';
import { Grid, SVG, Link, Text } from 'system';
import { BoundedBox, Subheading } from 'src/components';
import { ReactComponent as AssetIconArrowRightSVG } from 'src/assets/icon-arrow-right.svg';
import { ReactComponent as AssetIconArrowLeftSVG } from 'src/assets/icon-arrow-left.svg';
var variants = {
  normal: {
    contentGridColumn: [null, null, '1 / -1', "3 / -1"]
  },
  wide: {
    contentGridColumn: [null, null, '1 / -1', "2 / -1"]
  },
  news: {
    contentGridColumn: ['1 / -1', null, '2 / -2', "2 / -2", "3 / -3"]
  }
};

var PaginationButton = function PaginationButton(_ref) {
  var href = _ref.href,
      children = _ref.children,
      _ref$variant = _ref.variant,
      variantName = _ref$variant === void 0 ? 'prev' : _ref$variant,
      props = _objectWithoutPropertiesLoose(_ref, ["href", "children", "variant"]);

  var variants = {
    prev: {
      svg: AssetIconArrowLeftSVG,
      label: 'Previous'
    },
    next: {
      svg: AssetIconArrowRightSVG,
      label: 'Next'
    }
  };
  var variant = variants[variantName];

  var _useState = useState(false),
      isFocused = _useState[0],
      setIsFocused = _useState[1];

  var handleFocus = function handleFocus(isFocused) {
    return setIsFocused(function () {
      return Boolean(isFocused);
    });
  };

  var bind = useGesture({
    onHover: function onHover(_ref2) {
      var hovering = _ref2.hovering;
      return handleFocus(hovering);
    },
    onDrag: function onDrag(_ref3) {
      var down = _ref3.down;
      return handleFocus(down);
    }
  });
  return React.createElement(Link, Object.assign({
    href: href,
    color: isFocused ? 'blue.40' : 'green.30',
    outline: 0,
    onBlur: function onBlur() {
      return handleFocus(false);
    },
    onFocus: function onFocus() {
      return handleFocus(true);
    },
    bg: "gray.90",
    pScale: "s"
  }, bind(), props), React.createElement(SVG, {
    svg: variant === null || variant === void 0 ? void 0 : variant.svg,
    x: 21,
    y: 23,
    width: linearScale('14px', '19px', {
      count: 5
    }),
    mbScale: "t",
    color: "currentColor",
    transitionProperty: "color"
  }), React.createElement(Subheading, {
    fontSizeScale: "s-",
    color: "currentColor",
    transitionProperty: "color"
  }, variant === null || variant === void 0 ? void 0 : variant.label), children && React.createElement(Text, {
    as: "p",
    mtScale: "t-",
    color: isFocused ? 'currentColor' : 'black',
    transitionProperty: "color"
  }, children));
};

export var PageBodyPrevNext = function PageBodyPrevNext(_ref4) {
  var _ref4$variant = _ref4.variant,
      variantName = _ref4$variant === void 0 ? 'normal' : _ref4$variant,
      prevHref = _ref4.prevHref,
      prevLabel = _ref4.prevLabel,
      nextHref = _ref4.nextHref,
      nextLabel = _ref4.nextLabel,
      props = _objectWithoutPropertiesLoose(_ref4, ["variant", "prevHref", "prevLabel", "nextHref", "nextLabel"]);

  var variant = variants[variantName];
  return React.createElement(BoundedBox, Object.assign({
    as: "section"
  }, props), React.createElement(Grid, {
    gridTemplateColumns: "repeat(8, 1fr)",
    gridGapScale: "l"
  }, React.createElement(Grid, {
    gridTemplateColumns: "repeat(2, 1fr)",
    gridGapScale: "l",
    gridColumn: variant === null || variant === void 0 ? void 0 : variant.contentGridColumn
  }, prevHref && React.createElement(PaginationButton, {
    href: prevHref,
    variant: "prev",
    gridColumn: "1"
  }, prevLabel), nextHref && React.createElement(PaginationButton, {
    href: nextHref,
    variant: "next",
    gridColumn: "2"
  }, nextLabel))));
};

PageBodyPrevNext.mapDataToProps = function (_ref5) {
  var _data$primary, _data$primary$prev_li, _data$primary2, _data$primary2$prev_l, _data$primary3, _data$primary3$next_l, _data$primary4, _data$primary4$next_l;

  var data = _ref5.data,
      meta = _ref5.meta,
      context = _ref5.context,
      nextContext = _ref5.nextContext;
  var primaryMetadataType = getPrimaryMetadataType(meta === null || meta === void 0 ? void 0 : meta.page);
  var variantsMap = {
    PrismicPageMainNews: 'news'
  };
  var variant = variantsMap[primaryMetadataType];
  return {
    // Note: nextSharesBg is purposely left off here to add space below text slices.
    // nextSharesBg: propPairsEq('bg', context, nextContext),
    variant: variant,
    prevHref: data === null || data === void 0 ? void 0 : (_data$primary = data.primary) === null || _data$primary === void 0 ? void 0 : (_data$primary$prev_li = _data$primary.prev_link) === null || _data$primary$prev_li === void 0 ? void 0 : _data$primary$prev_li.url,
    prevLabel: data === null || data === void 0 ? void 0 : (_data$primary2 = data.primary) === null || _data$primary2 === void 0 ? void 0 : (_data$primary2$prev_l = _data$primary2.prev_label) === null || _data$primary2$prev_l === void 0 ? void 0 : _data$primary2$prev_l.text,
    nextHref: data === null || data === void 0 ? void 0 : (_data$primary3 = data.primary) === null || _data$primary3 === void 0 ? void 0 : (_data$primary3$next_l = _data$primary3.next_link) === null || _data$primary3$next_l === void 0 ? void 0 : _data$primary3$next_l.url,
    nextLabel: data === null || data === void 0 ? void 0 : (_data$primary4 = data.primary) === null || _data$primary4 === void 0 ? void 0 : (_data$primary4$next_l = _data$primary4.next_label) === null || _data$primary4$next_l === void 0 ? void 0 : _data$primary4$next_l.text
  };
};

PageBodyPrevNext.mapDataToContext = function () {
  return {
    bg: 'white'
  };
};

PageBodyPrevNext.docs = {
  name: 'Prev Next',
  description: 'Links to a previous and/or next page with a title.',
  preset: true,
  examples: [{
    name: 'With prev and next',
    render: function render() {
      return React.createElement(PageBodyPrevNext, {
        prevLabel: "Pacific Business News Cover Story: Welcome to the Club [House]",
        prevHref: true,
        nextLabel: "PVA wins big at AIA Honolulu Awards",
        nextHref: true
      });
    }
  }, {
    name: 'On news post page',
    render: function render() {
      return React.createElement(PageBodyPrevNext, {
        prevLabel: "Pacific Business News Cover Story: Welcome to the Club [House]",
        prevHref: true,
        nextLabel: "PVA wins big at AIA Honolulu Awards",
        nextHref: true,
        variant: "news"
      });
    }
  }]
};