import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.regexp.search";
import "core-js/modules/es6.string.trim";
import "core-js/modules/es6.array.index-of";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.regexp.search";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.regexp.search";
import _styled from "styled-components";

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React from 'react';
import { linearScale } from 'styled-system-scale';
import { getSearchQuery } from 'helpers';
import { useLocation } from 'hooks';
import { Box, Grid, Flex, Input, SVG } from 'system';
import { BoundedBox } from 'src/components';
import { ReactComponent as AssetIconSearchSVG } from 'src/assets/icon-search.svg';

var _StyledInput = _styled(Input).withConfig({
  displayName: "PageBodySearchBar___StyledInput",
  componentId: "sc-1rkc7i6-0"
})(["", ""], function (p) {
  return {
    '&::placeholder': {
      color: p.theme.colors.gray[70],
      textTransform: 'lowercase',
      opacity: 0.7
    }
  };
});

export var PageBodySearchBar = function PageBodySearchBar(_ref) {
  var onSubmit = _ref.onSubmit,
      props = _objectWithoutPropertiesLoose(_ref, ["onSubmit"]);

  var location = useLocation();

  var handleSubmit = function handleSubmit(e) {
    e.preventDefault();
    onSubmit({
      query: e.target.search.value.trim()
    });
  };

  return React.createElement(BoundedBox, Object.assign({
    as: "section",
    bg: "gray.90",
    pyScale: ['xl', null, 'xxl']
  }, props), React.createElement(Grid, {
    as: "form",
    onSubmit: handleSubmit,
    gridTemplateColumns: "repeat(8, 1fr)",
    gridColumnGapScale: "l"
  }, React.createElement(Flex, {
    alignItems: "center",
    gridColumn: ['1 / span 4', null, null, null, '1 / span 2']
  }, React.createElement(SVG, {
    x: 1,
    y: 1,
    svg: AssetIconSearchSVG,
    width: linearScale('29px', '67px', {
      count: 5
    }),
    mrScale: "xl",
    flexShrink: "0",
    color: "gray.10"
  }), React.createElement(Box, {
    position: "relative"
  }, React.createElement(_StyledInput, {
    spellCheck: false,
    autoComplete: false,
    name: "search",
    type: "search",
    color: "blue.40",
    flexGrow: "1",
    fontFamily: "sansExtended",
    fontSizeScale: "xl",
    fontWeight: "thin",
    outline: "0",
    placeholder: "Search",
    width: "100%",
    height: "100%",
    pbScale: "t-",
    defaultValue: getSearchQuery('query', location)
  }), React.createElement(Box, {
    height: ['1px', null, null, '2px'],
    bg: "gray.10",
    position: "absolute",
    transformOrigin: "left",
    left: 0,
    right: 0,
    bottom: 0
  })))));
};

PageBodySearchBar.mapDataToProps = function (_ref2) {
  var data = _ref2.data;
  return {
    onSubmit: data === null || data === void 0 ? void 0 : data.onSubmit
  };
};

PageBodySearchBar.mapDataToContext = function () {
  return {
    bg: 'gray.90'
  };
};