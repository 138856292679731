import "core-js/modules/es6.array.fill";
import "core-js/modules/es6.array.map";
import "core-js/modules/es6.array.filter";
import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.array.index-of";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.array.fill";
import "core-js/modules/es6.object.assign";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.array.fill";
import "core-js/modules/es6.object.assign";

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React from 'react';
import { notEmpty, getMetadata, propPairsEq } from 'helpers';
import { Grid } from 'system';
import { BoundedBox, ProjectCard } from 'src/components';
export var PageBodyFeaturedProjects = function PageBodyFeaturedProjects(_ref) {
  var children = _ref.children,
      props = _objectWithoutPropertiesLoose(_ref, ["children"]);

  return React.createElement(BoundedBox, Object.assign({
    as: "section"
  }, props), React.createElement(Grid, {
    gridTemplateColumns: ['1fr', null, 'repeat(2, 1fr)'],
    gridGapScale: "l"
  }, children));
};
PageBodyFeaturedProjects.Project = ProjectCard;

PageBodyFeaturedProjects.mapDataToProps = function (_ref2) {
  var _projectDocs$map;

  var data = _ref2.data,
      context = _ref2.context,
      nextContext = _ref2.nextContext;
  var projectDocs = Array(2).fill().map(function (_, i) {
    var _data$primary, _data$primary$;

    return data === null || data === void 0 ? void 0 : (_data$primary = data.primary) === null || _data$primary === void 0 ? void 0 : (_data$primary$ = _data$primary["project_" + (i + 1)]) === null || _data$primary$ === void 0 ? void 0 : _data$primary$.document;
  }).filter(Boolean);
  return {
    nextSharesBg: propPairsEq('bg', context, nextContext),
    children: projectDocs === null || projectDocs === void 0 ? void 0 : (_projectDocs$map = projectDocs.map) === null || _projectDocs$map === void 0 ? void 0 : _projectDocs$map.call(projectDocs, function (projectDoc) {
      var _projectDoc$data, _projectDoc$data$titl, _metadata$items, _metadata$items$, _metadata$items$$proj, _metadata$items$$proj2, _metadata$primary, _metadata$primary$fea, _metadata$primary2, _metadata$primary2$fe, _metadata$primary3, _metadata$primary3$fe, _metadata$primary4, _metadata$primary4$lo, _projectDoc$data2, _projectDoc$data2$tit;

      var metadata = getMetadata('PrismicPageMainProject', projectDoc);
      return React.createElement(PageBodyFeaturedProjects.Project, {
        variant: "large",
        key: projectDoc === null || projectDoc === void 0 ? void 0 : (_projectDoc$data = projectDoc.data) === null || _projectDoc$data === void 0 ? void 0 : (_projectDoc$data$titl = _projectDoc$data.title) === null || _projectDoc$data$titl === void 0 ? void 0 : _projectDoc$data$titl.text,
        categoryUID: metadata === null || metadata === void 0 ? void 0 : (_metadata$items = metadata.items) === null || _metadata$items === void 0 ? void 0 : (_metadata$items$ = _metadata$items[0]) === null || _metadata$items$ === void 0 ? void 0 : (_metadata$items$$proj = _metadata$items$.project_category) === null || _metadata$items$$proj === void 0 ? void 0 : (_metadata$items$$proj2 = _metadata$items$$proj.document) === null || _metadata$items$$proj2 === void 0 ? void 0 : _metadata$items$$proj2.uid,
        href: projectDoc === null || projectDoc === void 0 ? void 0 : projectDoc.url,
        imageAlt: notEmpty(metadata === null || metadata === void 0 ? void 0 : (_metadata$primary = metadata.primary) === null || _metadata$primary === void 0 ? void 0 : (_metadata$primary$fea = _metadata$primary.featured_image) === null || _metadata$primary$fea === void 0 ? void 0 : _metadata$primary$fea.alt),
        imageFluid: metadata === null || metadata === void 0 ? void 0 : (_metadata$primary2 = metadata.primary) === null || _metadata$primary2 === void 0 ? void 0 : (_metadata$primary2$fe = _metadata$primary2.featured_image) === null || _metadata$primary2$fe === void 0 ? void 0 : _metadata$primary2$fe.fluid,
        imageURL: metadata === null || metadata === void 0 ? void 0 : (_metadata$primary3 = metadata.primary) === null || _metadata$primary3 === void 0 ? void 0 : (_metadata$primary3$fe = _metadata$primary3.featured_image) === null || _metadata$primary3$fe === void 0 ? void 0 : _metadata$primary3$fe.url,
        location: metadata === null || metadata === void 0 ? void 0 : (_metadata$primary4 = metadata.primary) === null || _metadata$primary4 === void 0 ? void 0 : (_metadata$primary4$lo = _metadata$primary4.location) === null || _metadata$primary4$lo === void 0 ? void 0 : _metadata$primary4$lo.text,
        name: projectDoc === null || projectDoc === void 0 ? void 0 : (_projectDoc$data2 = projectDoc.data) === null || _projectDoc$data2 === void 0 ? void 0 : (_projectDoc$data2$tit = _projectDoc$data2.title) === null || _projectDoc$data2$tit === void 0 ? void 0 : _projectDoc$data2$tit.text
      });
    })
  };
};

PageBodyFeaturedProjects.mapDataToContext = function () {
  return {
    bg: 'white'
  };
};

export var fragment = "602057215";
PageBodyFeaturedProjects.docs = {
  name: 'Featured Projects',
  examples: [{
    name: 'Featured Projects',
    render: function render() {
      return React.createElement(PageBodyFeaturedProjects, null, React.createElement(PageBodyFeaturedProjects.Project, {
        variant: "large",
        imageURL: "https://pvadev.cdn.prismic.io/pvadev/541704ba04a3f58caa4a37dd325d793d351dbd1d_0-flower-avenue.jpg",
        imageAlt: "Example.",
        location: "Honolulu, Hawaii",
        categoryUID: "residential",
        name: "Flower Avenue",
        href: true
      }), React.createElement(PageBodyFeaturedProjects.Project, {
        variant: "large",
        imageURL: "https://pvadev.cdn.prismic.io/pvadev/62369a1947659de26225f0ba5cbf58c35ed47cb9_0-sushi-izakaya-dining--shinn.jpg",
        imageAlt: "Example.",
        location: "Honolulu, Hawaii",
        categoryUID: "retail-restaurants",
        name: "Sushi Izakaya Dining: Shinn",
        href: true
      }));
    }
  }]
};