import "core-js/modules/es6.function.name";
import "core-js/modules/es6.string.link";
import "core-js/modules/es6.array.map";
import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.regexp.split";
import "core-js/modules/es6.array.index-of";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.function.name";
import "core-js/modules/es6.string.link";
import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.regexp.split";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.function.name";
import "core-js/modules/es6.string.link";
import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.regexp.split";
import _styled4 from "styled-components";
import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React, { useRef } from 'react';
import ConditionalWrap from 'conditional-wrap';
import { themeGet } from '@styled-system/theme-get';
import { linearScale } from 'styled-system-scale';
import { propPairsEq } from 'helpers';
import { last } from 'tiny-compose-fns/noFp';
import { Box, Grid, Flex, Link } from 'system';
import { BoundedBox, Heading, Subheading } from 'src/components';

var _StyledLink = _styled(Link).withConfig({
  displayName: "PageBodyFloatingNavigation___StyledLink",
  componentId: "v9gsby-0"
})(["", ""], function (p) {
  return {
    '&:hover, &:focus': {
      color: p.theme.colors.gray[10]
    }
  };
});

var Item = React.forwardRef(function (_ref, containerRef) {
  var href = _ref.href,
      target = _ref.target,
      children = _ref.children,
      isFirst = _ref.isFirst,
      props = _objectWithoutPropertiesLoose(_ref, ["href", "target", "children", "isFirst"]);

  var handleScroll = function handleScroll(e) {
    e.preventDefault();
    var container = containerRef.current;
    var domId = last(href.split('#'));
    var node = document.getElementById(domId);
    if (node && container) window.scrollTo({
      top: node.offsetTop - container.clientHeight
    });
  };

  return React.createElement(Box, Object.assign({
    as: "li",
    borderColor: "white",
    borderLeft: isFirst ? 'none' : '2px solid',
    mlScale: isFirst ? 0 : 's',
    plScale: isFirst ? 0 : 's'
  }, props), React.createElement(Subheading, {
    lineHeight: "solid"
  }, React.createElement(_StyledLink, {
    href: href,
    target: target,
    transitionProperty: "color",
    onClick: handleScroll
  }, children)));
});

var _StyledFlex = _styled(Flex).withConfig({
  displayName: "PageBodyFloatingNavigation___StyledFlex",
  componentId: "v9gsby-1"
})({
  whiteSpace: 'nowrap'
});

var _StyledBox = _styled(Box).withConfig({
  displayName: "PageBodyFloatingNavigation___StyledBox",
  componentId: "v9gsby-2"
})(["", ""], function (p) {
  return {
    backgroundImage: "linear-gradient(to right, " + p.theme.colors.gray['70transparent'] + ", " + p.theme.colors.gray[70] + ")"
  };
});

var FloatingNavigation = React.forwardRef(function (_ref2, ref) {
  var children = _ref2.children,
      props = _objectWithoutPropertiesLoose(_ref2, ["children"]);

  return React.createElement(Box, {
    as: "nav",
    bg: "gray.70",
    color: "white",
    position: "sticky",
    ref: ref,
    style: {
      WebkitOverflowScrolling: 'touch'
    },
    top: 0,
    zIndex: "floatingNavigation"
  }, React.createElement(Box, {
    overflowX: "auto",
    prScale: "xl"
  }, React.createElement(BoundedBox, Object.assign({
    pyScale: "m-",
    width: "min-content"
  }, props), React.createElement(_StyledFlex, {
    as: "ul"
  }, React.Children.map(children, function (child) {
    return React.cloneElement(child, {
      ref: ref
    });
  })))), React.createElement(_StyledBox, {
    width: linearScale('20px', '50px', {
      count: 5
    }),
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0
  }));
});

var _StyledLink2 = _styled(Link).withConfig({
  displayName: "PageBodyFloatingNavigation___StyledLink2",
  componentId: "v9gsby-3"
})(["white-space:nowrap;&:hover,&:focus{color:", ";}"], function (p) {
  return p._css;
});

export var PageBodyFloatingNavigation = function PageBodyFloatingNavigation(_ref3) {
  var children = _ref3.children,
      title = _ref3.title,
      rootHref = _ref3.rootHref,
      rootTarget = _ref3.rootTarget,
      props = _objectWithoutPropertiesLoose(_ref3, ["children", "title", "rootHref", "rootTarget"]);

  var headerRef = useRef();
  return React.createElement(React.Fragment, null, React.createElement(BoundedBox, Object.assign({
    as: "section",
    bg: "gray.90",
    ptScale: "m-",
    pbScale: "m-",
    zIndex: "floatingNavigation",
    top: 0
  }, props), React.createElement(Grid, {
    gridTemplateColumns: "auto 1fr",
    alignItems: "baseline",
    gridColumnGapScale: "xl"
  }, React.createElement(Heading, {
    textStyle: "lowercase",
    color: "blue.40"
  }, React.createElement(ConditionalWrap, {
    condition: rootHref,
    wrap: function wrap(children) {
      return React.createElement(_StyledLink2, {
        href: rootHref,
        target: rootTarget,
        transitionProperty: "color",
        _css: themeGet('colors.blue.40')
      }, children);
    }
  }, title)))), React.createElement(FloatingNavigation, {
    ref: headerRef
  }, React.Children.map(children, function (child, index) {
    return child && React.cloneElement(child, {
      isFirst: index === 0
    });
  })));
};
PageBodyFloatingNavigation.Item = Item;

PageBodyFloatingNavigation.mapDataToProps = function (_ref4) {
  var _data$primary, _data$primary$navigat, _navigationDoc$data, _navigationDoc$data2, _navigationDoc$data2$, _navigationDoc$data3, _navigationDoc$data3$, _navigationDoc$data4, _navigationDoc$data4$, _items$map;

  var data = _ref4.data,
      context = _ref4.context,
      nextContext = _ref4.nextContext;
  var navigationDoc = data === null || data === void 0 ? void 0 : (_data$primary = data.primary) === null || _data$primary === void 0 ? void 0 : (_data$primary$navigat = _data$primary.navigation) === null || _data$primary$navigat === void 0 ? void 0 : _data$primary$navigat.document;
  var items = navigationDoc === null || navigationDoc === void 0 ? void 0 : (_navigationDoc$data = navigationDoc.data) === null || _navigationDoc$data === void 0 ? void 0 : _navigationDoc$data.primary;
  return {
    nextSharesBg: propPairsEq('bg', context, nextContext),
    title: navigationDoc === null || navigationDoc === void 0 ? void 0 : (_navigationDoc$data2 = navigationDoc.data) === null || _navigationDoc$data2 === void 0 ? void 0 : (_navigationDoc$data2$ = _navigationDoc$data2.title) === null || _navigationDoc$data2$ === void 0 ? void 0 : _navigationDoc$data2$.text,
    rootHref: navigationDoc === null || navigationDoc === void 0 ? void 0 : (_navigationDoc$data3 = navigationDoc.data) === null || _navigationDoc$data3 === void 0 ? void 0 : (_navigationDoc$data3$ = _navigationDoc$data3.root) === null || _navigationDoc$data3$ === void 0 ? void 0 : _navigationDoc$data3$.url,
    rootTarget: navigationDoc === null || navigationDoc === void 0 ? void 0 : (_navigationDoc$data4 = navigationDoc.data) === null || _navigationDoc$data4 === void 0 ? void 0 : (_navigationDoc$data4$ = _navigationDoc$data4.root) === null || _navigationDoc$data4$ === void 0 ? void 0 : _navigationDoc$data4$.target,
    children: items === null || items === void 0 ? void 0 : (_items$map = items.map) === null || _items$map === void 0 ? void 0 : _items$map.call(items, function (item) {
      var _item$primary, _item$primary$link, _item$primary2, _item$primary2$link, _item$primary3;

      return React.createElement(Item, {
        key: item === null || item === void 0 ? void 0 : item.id,
        href: item === null || item === void 0 ? void 0 : (_item$primary = item.primary) === null || _item$primary === void 0 ? void 0 : (_item$primary$link = _item$primary.link) === null || _item$primary$link === void 0 ? void 0 : _item$primary$link.url,
        target: item === null || item === void 0 ? void 0 : (_item$primary2 = item.primary) === null || _item$primary2 === void 0 ? void 0 : (_item$primary2$link = _item$primary2.link) === null || _item$primary2$link === void 0 ? void 0 : _item$primary2$link.target
      }, item === null || item === void 0 ? void 0 : (_item$primary3 = item.primary) === null || _item$primary3 === void 0 ? void 0 : _item$primary3.name);
    })
  };
};

PageBodyFloatingNavigation.mapDataToContext = function () {
  return {
    bg: 'gray.90'
  };
};

PageBodyFloatingNavigation.docs = {
  name: 'Floating Navigation',
  preset: true,
  examples: [{
    name: 'Floating Navigation',
    render: function render() {
      return React.createElement(PageBodyFloatingNavigation, {
        rootHref: "/",
        title: "services",
        nextSharesBg: [false]
      }, React.createElement(Item, {
        href: false,
        isFirst: true
      }, "Architecture"), React.createElement(Item, {
        href: false
      }, "Interior Design"), React.createElement(Item, {
        href: false
      }, "Signage + Graphics"), React.createElement(Item, {
        href: false,
        isLast: true
      }, "Consulting"));
    }
  }]
};
export var fragment = "3092305537";