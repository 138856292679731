import "core-js/modules/es6.array.map";
import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.array.index-of";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";
import _styled from "styled-components";

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React from 'react';
import { useGesture } from 'react-use-gesture';
import { a, useSpring } from 'react-spring';
import { getRichText, propPairsEq, notEmpty } from 'helpers';
import { camelCase } from 'tiny-compose-fns';
import { Box, Flex, Grid, Image, Text, ImageContainer, AspectRatio, Link, SVG } from 'system';
import { BoundedBox, HTMLContent, Heading } from 'src/components';
import { ReactComponent as AssetIconArrowRightSVG } from 'src/assets/icon-arrow-right.svg';

var _StyledBox = _styled(Box).withConfig({
  displayName: "PageBodyAwards___StyledBox",
  componentId: "sc-15xbu2m-0"
})({
  pointerEvents: 'none'
});

var Project = function Project(_ref) {
  var projectTitle = _ref.projectTitle,
      projectHref = _ref.projectHref,
      projectImageAlt = _ref.projectImageAlt,
      projectImageURL = _ref.projectImageURL,
      projectImageFluid = _ref.projectImageFluid,
      projectImageSide = _ref.projectImageSide;

  var _useSpring = useSpring(function () {
    return {
      transform: 'translate3d(0%, 0, 0)'
    };
  }),
      style = _useSpring[0],
      set = _useSpring[1];

  var handleFocus = function handleFocus(isFocused) {
    return set({
      transform: isFocused ? 'translate3d(20%, 0, 0)' : 'translate3d(0%, 0, 0)'
    });
  };

  var bind = useGesture({
    onHover: function onHover(_ref2) {
      var hovering = _ref2.hovering;
      return handleFocus(hovering);
    },
    onDrag: function onDrag(_ref3) {
      var down = _ref3.down;
      return handleFocus(down);
    }
  });
  var isImageOnLeft = projectImageSide === 'left';
  if (!(projectImageURL || projectImageFluid || projectHref)) return null;
  return React.createElement(Link, Object.assign({
    href: projectHref,
    onBlur: function onBlur() {
      return handleFocus(false);
    },
    onFocus: function onFocus() {
      return handleFocus(true);
    },
    outline: 0,
    position: "relative",
    gridColumn: ['1 / -1', null, isImageOnLeft ? '1 / span 4' : '5 / -1']
  }, bind()), React.createElement(AspectRatio, {
    x: 16,
    y: 9,
    mxScale: ['-xl', null, 0],
    mtScale: ['-l+', null, 0]
  }, React.createElement(Image, {
    src: projectImageURL,
    alt: projectImageAlt,
    fluid: projectImageFluid,
    bg: "gray.10"
  }), projectTitle && React.createElement(Box, {
    position: "absolute",
    bottom: "1rem",
    left: "1rem",
    right: 0,
    zIndex: 1
  }, React.createElement(Flex, {
    display: "inline-flex",
    alignItems: "center"
  }, React.createElement(Heading, {
    color: "white",
    fontWeight: "light",
    fontSizeScale: "b+",
    textShadow: "0 2px 4px rgba(0, 0, 0, 0.75)",
    mrScale: "t"
  }, projectTitle), React.createElement(Box, {
    as: a.div,
    style: style,
    flexShrink: "0",
    width: "1rem"
  }, React.createElement(SVG, {
    svg: AssetIconArrowRightSVG,
    x: 21,
    y: 23,
    color: "white",
    width: "1rem"
  }))))), React.createElement(_StyledBox, {
    position: "absolute",
    bottom: 0,
    leftScale: ['-xl', null, 0],
    rightScale: ['-xl', null, 0],
    height: "8rem",
    backgroundImage: "linear-gradient(to top, rgba(0, 0, 0, .45) 0%, rgba(0, 0, 0, .15) 50%, rgba(0, 0, 0, 0) 100%)"
  }));
};

var Award = function Award(_ref4) {
  var year = _ref4.year,
      awardsHTML = _ref4.awardsHTML;
  return React.createElement(React.Fragment, null, React.createElement(Text, {
    gridColumn: 1
  }, year), React.createElement(HTMLContent, {
    gridColumn: "2 / end",
    html: awardsHTML,
    componentOverrides: {
      p: function p(Comp) {
        return function (props) {
          return React.createElement(Comp, Object.assign({
            mbScale: "t",
            boxStyle: "lastNoMargin"
          }, props));
        };
      }
    }
  }));
};

export var PageBodyAwards = function PageBodyAwards(_ref5) {
  var orgImageAlt = _ref5.orgImageAlt,
      orgImageURL = _ref5.orgImageURL,
      orgImageFluid = _ref5.orgImageFluid,
      projectTitle = _ref5.projectTitle,
      projectHref = _ref5.projectHref,
      projectImageAlt = _ref5.projectImageAlt,
      projectImageURL = _ref5.projectImageURL,
      projectImageFluid = _ref5.projectImageFluid,
      projectImageSide = _ref5.projectImageSide,
      heading = _ref5.heading,
      withDivider = _ref5.withDivider,
      children = _ref5.children,
      props = _objectWithoutPropertiesLoose(_ref5, ["orgImageAlt", "orgImageURL", "orgImageFluid", "projectTitle", "projectHref", "projectImageAlt", "projectImageURL", "projectImageFluid", "projectImageSide", "heading", "withDivider", "children"]);

  var isImageOnLeft = projectImageSide === 'left';
  return React.createElement(BoundedBox, Object.assign({
    as: "section",
    position: "relative"
  }, props), React.createElement(Grid, {
    gridTemplateColumns: ['1fr', null, 'repeat(8, 1fr)'],
    gridColumnGapScale: "xxl",
    gridRowGapScale: "l",
    alignItems: "start",
    gridAutoFlow: "row dense"
  }, React.createElement(Project, {
    projectImageSide: projectImageSide,
    projectTitle: projectTitle,
    projectHref: projectHref,
    projectImageAlt: projectImageAlt,
    projectImageURL: projectImageURL,
    projectImageFluid: projectImageFluid
  }), React.createElement(Grid, {
    gridColumn: ['1 / -1', null, isImageOnLeft ? '5 / -1' : '1 / span 4'],
    gridTemplateColumns: ['repeat(6, 1fr)', null, 'auto repeat(7, 1fr)'],
    gridColumnGapScale: "m",
    gridRowGapScale: "s",
    alignItems: "start",
    bg: ['gray.90', null, 'white'],
    pScale: ['l', null, 0]
  }, (orgImageURL || orgImageFluid) && React.createElement(ImageContainer, {
    gridColumn: 1,
    width: [null, null, '4rem']
  }, React.createElement(Image, {
    src: orgImageURL,
    alt: orgImageAlt,
    fluid: orgImageFluid,
    height: "auto"
  })), heading && React.createElement(Heading, {
    fontSizeScale: "l",
    mbScale: "s",
    gridColumn: "2 / -1",
    alignSelf: [null, null, 'center'],
    maxWidth: "30ch"
  }, heading), children)), withDivider && React.createElement(Box, {
    display: ['none', null, 'block'],
    position: "absolute",
    bottom: 0,
    height: "1px",
    bg: "gray.10",
    leftScale: "xl",
    rightScale: "xl"
  }));
};
PageBodyAwards.Award = Award;

PageBodyAwards.mapDataToProps = function (_ref6) {
  var _primary$example_proj, _projectDoc$data, _projectDoc$data$main, _projectDoc$data$main2, _primary$organization, _primary$organization2, _primary$organization3, _primary$organization4, _projectDoc$data2, _projectDoc$data2$tit, _projectPrimary$featu, _projectPrimary$featu2, _projectPrimary$featu3, _items$map;

  var data = _ref6.data,
      context = _ref6.context,
      nextContext = _ref6.nextContext,
      nextType = _ref6.nextType;
  var items = data === null || data === void 0 ? void 0 : data.items;
  var primary = data === null || data === void 0 ? void 0 : data.primary;
  var withDivider = nextType === 'PageBodyAwards';
  var projectDoc = primary === null || primary === void 0 ? void 0 : (_primary$example_proj = primary.example_project) === null || _primary$example_proj === void 0 ? void 0 : _primary$example_proj.document;
  var projectPrimary = projectDoc === null || projectDoc === void 0 ? void 0 : (_projectDoc$data = projectDoc.data) === null || _projectDoc$data === void 0 ? void 0 : (_projectDoc$data$main = _projectDoc$data.main) === null || _projectDoc$data$main === void 0 ? void 0 : (_projectDoc$data$main2 = _projectDoc$data$main[0]) === null || _projectDoc$data$main2 === void 0 ? void 0 : _projectDoc$data$main2.primary;
  return {
    nextSharesBg: [!withDivider] || propPairsEq('bg', context, nextContext),
    heading: notEmpty(primary === null || primary === void 0 ? void 0 : (_primary$organization = primary.organization) === null || _primary$organization === void 0 ? void 0 : _primary$organization.text),
    orgImageAlt: primary === null || primary === void 0 ? void 0 : (_primary$organization2 = primary.organization_image) === null || _primary$organization2 === void 0 ? void 0 : _primary$organization2.alt,
    orgImageURL: primary === null || primary === void 0 ? void 0 : (_primary$organization3 = primary.organization_image) === null || _primary$organization3 === void 0 ? void 0 : _primary$organization3.url,
    orgImageFluid: primary === null || primary === void 0 ? void 0 : (_primary$organization4 = primary.organization_image) === null || _primary$organization4 === void 0 ? void 0 : _primary$organization4.fluid,
    projectTitle: projectDoc === null || projectDoc === void 0 ? void 0 : (_projectDoc$data2 = projectDoc.data) === null || _projectDoc$data2 === void 0 ? void 0 : (_projectDoc$data2$tit = _projectDoc$data2.title) === null || _projectDoc$data2$tit === void 0 ? void 0 : _projectDoc$data2$tit.text,
    projectHref: projectDoc === null || projectDoc === void 0 ? void 0 : projectDoc.url,
    projectImageAlt: projectPrimary === null || projectPrimary === void 0 ? void 0 : (_projectPrimary$featu = projectPrimary.featured_image) === null || _projectPrimary$featu === void 0 ? void 0 : _projectPrimary$featu.alt,
    projectImageURL: projectPrimary === null || projectPrimary === void 0 ? void 0 : (_projectPrimary$featu2 = projectPrimary.featured_image) === null || _projectPrimary$featu2 === void 0 ? void 0 : _projectPrimary$featu2.url,
    projectImageFluid: projectPrimary === null || projectPrimary === void 0 ? void 0 : (_projectPrimary$featu3 = projectPrimary.featured_image) === null || _projectPrimary$featu3 === void 0 ? void 0 : _projectPrimary$featu3.fluid,
    projectImageSide: notEmpty(camelCase(primary === null || primary === void 0 ? void 0 : primary.project_image_side)),
    withDivider: withDivider,
    children: items === null || items === void 0 ? void 0 : (_items$map = items.map) === null || _items$map === void 0 ? void 0 : _items$map.call(items, function (item) {
      var _item$year;

      var awardsHTML = getRichText(item === null || item === void 0 ? void 0 : item.awards);
      var year = notEmpty(item === null || item === void 0 ? void 0 : (_item$year = item.year) === null || _item$year === void 0 ? void 0 : _item$year.text);
      return React.createElement(Award, {
        key: year,
        year: year,
        awardsHTML: awardsHTML
      });
    })
  };
};

PageBodyAwards.mapDataToContext = function () {
  return {
    bg: 'white'
  };
};

export var fragment = "860831633";

var example = function example(props) {
  return React.createElement(PageBodyAwards, Object.assign({
    heading: "AIA, American Institute of Architects / Honolulu Chapter",
    orgImageURL: "https://pvadev.cdn.prismic.io/pvadev/a44e80e173d52a6f118ef4ff5981fd601cdd9077_aia-bw-logo.png"
  }, props), React.createElement(PageBodyAwards.Award, {
    year: "2019",
    awardsHTML: "<strong>Hawaii Energy Award For Excellence in Energy-Efficiency Design </strong>- Hoakalei Golf Clubhouse</p>"
  }), React.createElement(PageBodyAwards.Award, {
    year: "2017",
    awardsHTML: "<strong>Honorable Mention </strong>- Hale Palekaiko</p>"
  }), React.createElement(PageBodyAwards.Award, {
    year: "2013",
    awardsHTML: "<strong>Award of Merit </strong>- Modern Makeover</p>"
  }), React.createElement(PageBodyAwards.Award, {
    year: "2011",
    awardsHTML: "<strong>Award of Merit </strong>- Sushi Izakaya Dining: Shinn</p>"
  }), React.createElement(PageBodyAwards.Award, {
    year: "2010",
    awardsHTML: "<strong>Award of Merit </strong>- Ko'a Kea Hotel & Resort at Po'ipu Beach</p>"
  }));
};

PageBodyAwards.docs = {
  name: 'Awards',
  examples: [{
    name: 'Image on Left',
    render: function render() {
      return example({
        projectImageSide: 'left',
        withDivider: true,
        projectImageURL: 'https://images.prismic.io/pvadev/541704ba04a3f58caa4a37dd325d793d351dbd1d_0-flower-avenue.jpg?auto=compress,format',
        projectTitle: 'Flower Avenue'
      });
    }
  }, {
    name: 'Image on Right',
    render: function render() {
      return example({
        projectImageSide: 'right',
        withDivider: true,
        projectImageURL: 'https://images.prismic.io/pvadev/191e732cfadc33b9f50899c846065d4526556c30_2-kahala-thai-leed-platinum-residence.jpg?auto=compress,format',
        projectTitle: 'Kahala Thai Leed Platinum Residence'
      });
    }
  }]
};