import "core-js/modules/es6.array.map";
import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.array.index-of";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React from 'react';
import { linearScale } from 'styled-system-scale';
import { getRichText, notEmpty, propPairsEq, getPrimaryMetadataType } from 'helpers';
import { Grid } from 'system';
import { Image, ImageContainer } from 'system';
import { BoundedBox, HTMLContent } from 'src/components';
var variants = {
  normal: {
    contentGridColumn: [null, null, '1 / -1', "3 / -1"]
  },
  wide: {
    contentGridColumn: [null, null, '1 / -1', "2 / -1"]
  },
  news: {
    contentGridColumn: [null, null, '2 / span 6', "2 / span 6", "3 / span 4"]
  }
};
export var PageBodyImages = function PageBodyImages(_ref) {
  var children = _ref.children,
      _ref$variant = _ref.variant,
      variantName = _ref$variant === void 0 ? 'normal' : _ref$variant,
      props = _objectWithoutPropertiesLoose(_ref, ["children", "variant"]);

  var numChildren = React.Children.count(children) >= 2 ? 2 : 1;
  var variant = variants[variantName];
  return React.createElement(BoundedBox, Object.assign({
    as: "section"
  }, props), React.createElement(Grid, {
    gridTemplateColumns: ['1fr', null, 'repeat(8, 1fr)'],
    gridGapScale: "l"
  }, React.createElement(Grid, {
    gridTemplateColumns: ['1fr', null, "repeat(" + numChildren + ", 1fr)"],
    gridColumnGapScale: "l",
    gridRowGapScale: "t",
    gridColumn: variant === null || variant === void 0 ? void 0 : variant.contentGridColumn
  }, children)));
};

PageBodyImages.Image = function (_ref2) {
  var imageFluid = _ref2.imageFluid,
      imageURL = _ref2.imageURL,
      imageAlt = _ref2.imageAlt,
      captionHTML = _ref2.captionHTML;
  return React.createElement(React.Fragment, null, React.createElement(ImageContainer, {
    height: "100%",
    bg: "gray.10",
    gridRow: [null, null, '1'],
    maxHeight: linearScale('30rem', '50rem', {
      count: 5
    }),
    overflow: "hidden"
  }, (imageFluid || imageURL) && React.createElement(Image, {
    fluid: imageFluid,
    src: imageURL,
    alt: imageAlt,
    height: "auto"
  })), captionHTML && React.createElement(HTMLContent, {
    html: captionHTML,
    fontSizeScale: "s",
    fontWeight: "light",
    color: "gray.70",
    gridRow: [null, null, '2']
  }));
};

PageBodyImages.mapDataToProps = function (_ref3) {
  var _data$items;

  var data = _ref3.data,
      meta = _ref3.meta,
      context = _ref3.context,
      nextContext = _ref3.nextContext;
  var primaryMetadataType = getPrimaryMetadataType(meta === null || meta === void 0 ? void 0 : meta.page);
  var variantsMap = {
    PrismicPageMainNews: 'news',
    PrismicPageMainProject: 'wide'
  };
  var variant = variantsMap[primaryMetadataType];
  return {
    nextSharesBg: propPairsEq('bg', context, nextContext),
    variant: variant,
    children: data === null || data === void 0 ? void 0 : (_data$items = data.items) === null || _data$items === void 0 ? void 0 : _data$items.map(function (item, i) {
      var _item$image, _item$image2, _item$image3;

      return React.createElement(PageBodyImages.Image, {
        key: i,
        imageFluid: item === null || item === void 0 ? void 0 : (_item$image = item.image) === null || _item$image === void 0 ? void 0 : _item$image.fluid,
        imageURL: item === null || item === void 0 ? void 0 : (_item$image2 = item.image) === null || _item$image2 === void 0 ? void 0 : _item$image2.url,
        imageAlt: notEmpty(item === null || item === void 0 ? void 0 : (_item$image3 = item.image) === null || _item$image3 === void 0 ? void 0 : _item$image3.alt),
        captionHTML: getRichText(item === null || item === void 0 ? void 0 : item.caption)
      });
    })
  };
};

PageBodyImages.mapDataToContext = function () {
  return {
    bg: 'white'
  };
};

export var fragment = "2312727173";
PageBodyImages.docs = {
  name: 'Images',
  examples: [{
    name: 'One Image',
    render: function render() {
      return React.createElement(PageBodyImages, null, React.createElement(PageBodyImages.Image, {
        imageURL: "https://pvadev.cdn.prismic.io/pvadev/0d77aa8b835a93c8eeb0525b7f530c021cb6c073_2-flower-avenue.jpg",
        imageAlt: "Example image.",
        captionHTML: "<p>This is a caption.</p>"
      }));
    }
  }, {
    name: 'Two Images and Captions',
    render: function render() {
      return React.createElement(PageBodyImages, null, React.createElement(PageBodyImages.Image, {
        imageURL: "https://pvadev.cdn.prismic.io/pvadev/412d102996c3a439dcd2802a714e9b9ccdbf53af_3-flower-avenue.jpg",
        imageAlt: "Example image.",
        captionHTML: "<p>This is a caption.</p>"
      }), React.createElement(PageBodyImages.Image, {
        imageURL: "https://pvadev.cdn.prismic.io/pvadev/aea6744aca4680ac3e6e1abea875bb505858b89f_4-flower-avenue.jpg",
        imageAlt: "Example image.",
        captionHTML: "<p>This is a caption.</p>"
      }));
    }
  }]
};