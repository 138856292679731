import "core-js/modules/es6.string.link";
import "core-js/modules/es6.function.name";
import "core-js/modules/es6.array.map";
import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.array.index-of";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.string.link";
import "core-js/modules/es6.function.name";
import "core-js/modules/es6.object.assign";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.string.link";
import "core-js/modules/es6.function.name";
import "core-js/modules/es6.object.assign";
import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React from 'react';
import { themeGet } from '@styled-system/theme-get';
import { usePrimaryNavigationData, useSettingsData } from 'src/hooks';
import { Text, Box, Flex, Grid, Link, SVG } from 'system';
import { BoundedBox, Logo, BackToTopButton } from 'src/components';
import { ReactComponent as AssetIconTwitter } from 'src/assets/icon-twitter.svg';
import { ReactComponent as AssetIconFacebook } from 'src/assets/icon-facebook.svg';
import { ReactComponent as AssetIconLinkedin } from 'src/assets/icon-linkedin.svg';

var _StyledLink = _styled(Link).withConfig({
  displayName: "PageBodyFooter___StyledLink",
  componentId: "sc-116tc7m-0"
})(["", ""], function (p) {
  return {
    '&:hover, &:focus': {
      color: p.theme.colors.blue[40]
    }
  };
});

var SocialIcon = function SocialIcon(_ref) {
  var svg = _ref.svg,
      href = _ref.href,
      props = _objectWithoutPropertiesLoose(_ref, ["svg", "href"]);

  return React.createElement(_StyledLink, Object.assign({
    href: href,
    target: "_blank",
    boxStyle: "lastNoMargin",
    color: "green.30",
    display: "block",
    mrScale: "t",
    transitionProperty: "color",
    outline: "0"
  }, props), React.createElement(SVG, {
    x: 1,
    y: 1,
    svg: svg,
    width: ['1rem', null, '1.15rem'],
    color: "inherit"
  }));
};

var _StyledLink2 = _styled(Link).withConfig({
  displayName: "PageBodyFooter___StyledLink2",
  componentId: "sc-116tc7m-1"
})(["text-transform:lowercase;&:hover,&:focus{color:", ";}"], function (p) {
  return p._css;
});

var _StyledLink3 = _styled(Link).withConfig({
  displayName: "PageBodyFooter___StyledLink3",
  componentId: "sc-116tc7m-2"
})(["", ""], function (p) {
  return {
    '&:hover, &:focus': {
      color: p.theme.colors.blue[40]
    }
  };
});

export var PageBodyFooter = function PageBodyFooter(_ref2) {
  var _settingsData$site_co, _settingsData$faceboo, _settingsData$twitter, _settingsData$linkedi;

  var _ref2$withBackToTop = _ref2.withBackToTop,
      withBackToTop = _ref2$withBackToTop === void 0 ? true : _ref2$withBackToTop;
  var primaryNavData = usePrimaryNavigationData();
  var settingsData = useSettingsData(); // We need to do this to have the nav items appear in the correct sequence
  // with grid auto placement.

  var numNavItems = primaryNavData.length;
  var numNavItemRows = Math.ceil(numNavItems / 2);
  var copyrightText = settingsData === null || settingsData === void 0 ? void 0 : (_settingsData$site_co = settingsData.site_copyright) === null || _settingsData$site_co === void 0 ? void 0 : _settingsData$site_co.text;
  return React.createElement(React.Fragment, null, withBackToTop && React.createElement(BoundedBox, {
    pbScale: 0,
    ptScale: "s",
    mbScale: "l"
  }, React.createElement(Flex, {
    justifyContent: "flex-end"
  }, React.createElement(BackToTopButton, null))), React.createElement(BoundedBox, {
    as: "footer",
    bg: "brown.30",
    color: "white",
    ptScale: ['xl', 'l+', null, 'm'],
    pbScale: ['xl', 'l+', null, 'm']
  }, React.createElement(Grid, {
    alignItems: ['start', null, 'center'],
    gridAutoFlow: [null, null, 'column'],
    gridColumnGapScale: "l",
    gridRowGapScale: "l",
    gridTemplateColumns: ['1fr 2fr', null, 'auto auto 1fr']
  }, React.createElement(Link, {
    href: "/"
  }, React.createElement(Logo, {
    colorScheme: "light",
    width: "5rem"
  })), React.createElement(Grid, {
    as: "ul",
    gridTemplateColumns: ['1fr 1fr', null, "repeat(" + (numNavItems - 1) + ", auto) 1fr"],
    gridTemplateRows: ["repeat(" + numNavItemRows + ", auto)", null, 'none'],
    gridColumnGapScale: "l",
    gridRowGapScale: "t-",
    gridAutoFlow: "column"
  }, primaryNavData.map(function (item) {
    var _item$primary, _item$primary2, _item$primary2$link, _item$primary3, _item$primary3$link, _item$primary4;

    return React.createElement(Box, {
      as: "li",
      key: item === null || item === void 0 ? void 0 : (_item$primary = item.primary) === null || _item$primary === void 0 ? void 0 : _item$primary.name
    }, React.createElement(_StyledLink2, {
      href: item === null || item === void 0 ? void 0 : (_item$primary2 = item.primary) === null || _item$primary2 === void 0 ? void 0 : (_item$primary2$link = _item$primary2.link) === null || _item$primary2$link === void 0 ? void 0 : _item$primary2$link.url,
      target: item === null || item === void 0 ? void 0 : (_item$primary3 = item.primary) === null || _item$primary3 === void 0 ? void 0 : (_item$primary3$link = _item$primary3.link) === null || _item$primary3$link === void 0 ? void 0 : _item$primary3$link.target,
      fontSizeScale: "b",
      fontFamily: "sansExtended",
      fontWeight: "light",
      transitionProperty: "color",
      outline: "0",
      _css: themeGet('colors.blue.40')
    }, item === null || item === void 0 ? void 0 : (_item$primary4 = item.primary) === null || _item$primary4 === void 0 ? void 0 : _item$primary4.name));
  })), React.createElement(Grid, {
    display: ['grid', null, 'block'],
    gridTemplateColumns: "1fr 2fr",
    gridColumn: ['1/-1', null, 'auto'],
    gridColumnGapScale: "l"
  }, React.createElement(Grid, {
    display: ['grid', null, 'block'],
    gridTemplateColumns: "1fr 1fr",
    gridColumnGapScale: "l",
    gridColumn: 2
  }, React.createElement(Flex, {
    gridColumn: 2
  }, React.createElement(SocialIcon, {
    svg: AssetIconFacebook,
    href: "https://facebook.com/" + (settingsData === null || settingsData === void 0 ? void 0 : (_settingsData$faceboo = settingsData.facebook_handle) === null || _settingsData$faceboo === void 0 ? void 0 : _settingsData$faceboo.text)
  }), React.createElement(SocialIcon, {
    svg: AssetIconTwitter,
    href: "https://twitter.com/" + (settingsData === null || settingsData === void 0 ? void 0 : (_settingsData$twitter = settingsData.twitter_handle) === null || _settingsData$twitter === void 0 ? void 0 : _settingsData$twitter.text)
  }), React.createElement(SocialIcon, {
    svg: AssetIconLinkedin,
    href: "https://linkedin.com/company/" + (settingsData === null || settingsData === void 0 ? void 0 : (_settingsData$linkedi = settingsData.linkedin_handle) === null || _settingsData$linkedi === void 0 ? void 0 : _settingsData$linkedi.text)
  })))), React.createElement(Text, {
    display: [null, null, null, null, 'flex'],
    as: "small",
    color: "white",
    fontSizeScale: "s",
    fontWeight: "light",
    gridColumn: ['1 / -1', null, 'auto'],
    justifySelf: [null, null, 'end'],
    textAlign: ['left', null, 'right']
  }, copyrightText && React.createElement(Box, {
    mr: [null, null, null, null, '4px']
  }, copyrightText), React.createElement(Box, null, "Site designed & developed by", ' ', React.createElement(_StyledLink3, {
    href: "https://walltowall.com",
    target: "_blank",
    transitionProperty: "color"
  }, "Wall-to-Wall Studios"), ".")))));
};

PageBodyFooter.mapDataToProps = function (_ref3) {
  var _data$primary;

  var data = _ref3.data;
  return {
    withBackToTop: data === null || data === void 0 ? void 0 : (_data$primary = data.primary) === null || _data$primary === void 0 ? void 0 : _data$primary.withBackToTop
  };
};

PageBodyFooter.mapDataToContext = function () {
  return {
    bg: ['brown.30']
  };
};