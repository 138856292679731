import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.array.index-of";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React from 'react';
import { linearScale } from 'styled-system-scale';
import { getMetadata, notEmpty, propPairsEq, flatten } from 'helpers';
import { useNewsCategoriesData } from 'src/hooks';
import { Grid, Text, ImageContainer, Image, AspectRatio } from 'system';
import { BoundedBox, Heading, CategoryBreadCrumb } from 'src/components';
export var nodesPathFor = function nodesPathFor(node) {
  var _node$data, _node$data$parent;

  var parent = node === null || node === void 0 ? void 0 : (_node$data = node.data) === null || _node$data === void 0 ? void 0 : (_node$data$parent = _node$data.parent) === null || _node$data$parent === void 0 ? void 0 : _node$data$parent.document;
  if (parent) return flatten([nodesPathFor(parent), node]);
  return [node];
};
export var PageBodyNewsIntro = function PageBodyNewsIntro(_ref) {
  var _newsCategory$data, _newsCategory$data$ti;

  var title = _ref.title,
      categoryUID = _ref.categoryUID,
      description = _ref.description,
      date = _ref.date,
      imageFluid = _ref.imageFluid,
      imageURL = _ref.imageURL,
      imageAlt = _ref.imageAlt,
      parentName = _ref.parentName,
      parentHref = _ref.parentHref,
      props = _objectWithoutPropertiesLoose(_ref, ["title", "categoryUID", "description", "date", "imageFluid", "imageURL", "imageAlt", "parentName", "parentHref"]);

  var newsCategory = useNewsCategoriesData(categoryUID);
  var hasFeaturedImage = imageFluid || imageURL;
  return React.createElement(BoundedBox, Object.assign({
    as: "section",
    ptScale: hasFeaturedImage ? 0 : 'xxl'
  }, props), hasFeaturedImage && React.createElement(ImageContainer, {
    mbScale: "xxl",
    mxScale: "-xl",
    width: "auto",
    overflow: "hidden",
    bg: "gray.10"
  }, React.createElement(AspectRatio, {
    x: 1,
    y: 1,
    height: "80vh",
    maxHeight: linearScale('30rem', '70rem', {
      count: 5
    })
  }, React.createElement(Image, {
    fluid: imageFluid,
    src: imageURL,
    alt: imageAlt
  }))), React.createElement(Grid, {
    gridTemplateColumns: ['1fr', null, 'repeat(8, 1fr)'],
    gridColumnGapScale: "l"
  }, newsCategory && React.createElement(CategoryBreadCrumb, {
    categoryName: newsCategory === null || newsCategory === void 0 ? void 0 : (_newsCategory$data = newsCategory.data) === null || _newsCategory$data === void 0 ? void 0 : (_newsCategory$data$ti = _newsCategory$data.title) === null || _newsCategory$data$ti === void 0 ? void 0 : _newsCategory$data$ti.text,
    categoryHref: newsCategory === null || newsCategory === void 0 ? void 0 : newsCategory.url,
    parentName: parentName,
    parentHref: parentHref,
    color: "green.30",
    gridColumn: [null, null, '2 / span 6', '2 / -2', '3 / -3'],
    mbScale: "m-"
  }), title && React.createElement(Heading, {
    gridColumn: [null, null, '2 / span 6', '2 / -2', '3 / -3'],
    mbScale: "m-"
  }, title), date && React.createElement(Text, {
    as: "p",
    gridColumn: [null, null, '2 / span 6', '2 / -2', '3 / -3']
  }, date), description && React.createElement(Text, {
    as: "p",
    fontSizeScale: "m",
    fontWeight: "light",
    gridColumn: [null, null, '2 / span 6', '2 / -2', '3 / -3'],
    mtScale: "xxl"
  }, description)));
};

PageBodyNewsIntro.mapDataToProps = function (_ref2) {
  var _page$data, _page$data$parent, _page$data$parent$doc, _page$data$parent$doc2, _page$data$parent$doc3, _page$data2, _page$data2$parent, _page$data2$parent$do, _page$data3, _page$data3$title, _metadata$primary, _metadata$primary$new, _metadata$primary$new2, _metadata$primary2, _metadata$primary2$de, _metadata$primary3, _metadata$primary4, _metadata$primary4$fe, _metadata$primary5, _metadata$primary5$fe, _metadata$primary6, _metadata$primary6$fe;

  var meta = _ref2.meta,
      context = _ref2.context,
      nextContext = _ref2.nextContext;
  var page = meta === null || meta === void 0 ? void 0 : meta.page;
  var metadata = getMetadata('PrismicPageMainNews', page);
  return {
    nextSharesBg: propPairsEq('bg', context, nextContext),
    parentName: page === null || page === void 0 ? void 0 : (_page$data = page.data) === null || _page$data === void 0 ? void 0 : (_page$data$parent = _page$data.parent) === null || _page$data$parent === void 0 ? void 0 : (_page$data$parent$doc = _page$data$parent.document) === null || _page$data$parent$doc === void 0 ? void 0 : (_page$data$parent$doc2 = _page$data$parent$doc.data) === null || _page$data$parent$doc2 === void 0 ? void 0 : (_page$data$parent$doc3 = _page$data$parent$doc2.title) === null || _page$data$parent$doc3 === void 0 ? void 0 : _page$data$parent$doc3.text,
    parentHref: page === null || page === void 0 ? void 0 : (_page$data2 = page.data) === null || _page$data2 === void 0 ? void 0 : (_page$data2$parent = _page$data2.parent) === null || _page$data2$parent === void 0 ? void 0 : (_page$data2$parent$do = _page$data2$parent.document) === null || _page$data2$parent$do === void 0 ? void 0 : _page$data2$parent$do.url,
    title: page === null || page === void 0 ? void 0 : (_page$data3 = page.data) === null || _page$data3 === void 0 ? void 0 : (_page$data3$title = _page$data3.title) === null || _page$data3$title === void 0 ? void 0 : _page$data3$title.text,
    categoryUID: metadata === null || metadata === void 0 ? void 0 : (_metadata$primary = metadata.primary) === null || _metadata$primary === void 0 ? void 0 : (_metadata$primary$new = _metadata$primary.news_category) === null || _metadata$primary$new === void 0 ? void 0 : (_metadata$primary$new2 = _metadata$primary$new.document) === null || _metadata$primary$new2 === void 0 ? void 0 : _metadata$primary$new2.uid,
    description: metadata === null || metadata === void 0 ? void 0 : (_metadata$primary2 = metadata.primary) === null || _metadata$primary2 === void 0 ? void 0 : (_metadata$primary2$de = _metadata$primary2.description) === null || _metadata$primary2$de === void 0 ? void 0 : _metadata$primary2$de.text,
    date: metadata === null || metadata === void 0 ? void 0 : (_metadata$primary3 = metadata.primary) === null || _metadata$primary3 === void 0 ? void 0 : _metadata$primary3.date,
    imageFluid: metadata === null || metadata === void 0 ? void 0 : (_metadata$primary4 = metadata.primary) === null || _metadata$primary4 === void 0 ? void 0 : (_metadata$primary4$fe = _metadata$primary4.featured_image) === null || _metadata$primary4$fe === void 0 ? void 0 : _metadata$primary4$fe.fluid,
    imageURL: metadata === null || metadata === void 0 ? void 0 : (_metadata$primary5 = metadata.primary) === null || _metadata$primary5 === void 0 ? void 0 : (_metadata$primary5$fe = _metadata$primary5.featured_image) === null || _metadata$primary5$fe === void 0 ? void 0 : _metadata$primary5$fe.url,
    imageAlt: notEmpty(metadata === null || metadata === void 0 ? void 0 : (_metadata$primary6 = metadata.primary) === null || _metadata$primary6 === void 0 ? void 0 : (_metadata$primary6$fe = _metadata$primary6.featured_image) === null || _metadata$primary6$fe === void 0 ? void 0 : _metadata$primary6$fe.alt)
  };
};

PageBodyNewsIntro.mapDataToContext = function (_ref3) {
  var _metadata$primary7, _metadata$primary7$fe, _metadata$primary8, _metadata$primary8$fe;

  var meta = _ref3.meta;
  var page = meta === null || meta === void 0 ? void 0 : meta.page;
  var metadata = getMetadata('PrismicPageMainNews', page);
  var hasFeaturedImage = Boolean((metadata === null || metadata === void 0 ? void 0 : (_metadata$primary7 = metadata.primary) === null || _metadata$primary7 === void 0 ? void 0 : (_metadata$primary7$fe = _metadata$primary7.featured_image) === null || _metadata$primary7$fe === void 0 ? void 0 : _metadata$primary7$fe.fluid) || (metadata === null || metadata === void 0 ? void 0 : (_metadata$primary8 = metadata.primary) === null || _metadata$primary8 === void 0 ? void 0 : (_metadata$primary8$fe = _metadata$primary8.featured_image) === null || _metadata$primary8$fe === void 0 ? void 0 : _metadata$primary8$fe.url));
  return {
    bg: 'white',
    pullsDownHeader: hasFeaturedImage
  };
};

PageBodyNewsIntro.docs = {
  name: 'News Intro',
  description: 'News post title, description, and large intro image to introduce the article to the reader.',
  preset: true,
  examples: [{
    name: 'With featured image',
    render: function render() {
      return React.createElement(PageBodyNewsIntro, {
        title: "Kahala Crossroads featured in Hawaii Home + Remodeling",
        categoryUID: "pva-in-the-news",
        description: "Creating Calm \u2013 A Kahala property is reimagined, evoking a sense of peace through symmetrical design, water elements and fire features.",
        date: "July 2019",
        imageURL: "https://images.prismic.io/pvadev/623baaf1d0ee103ec93dae80dcb49cc51fc104db_0-kahala-crossing.jpg?auto=compress,format",
        parentName: "News"
      });
    }
  }, {
    name: 'Without featured image',
    render: function render() {
      return React.createElement(PageBodyNewsIntro, {
        title: "Kahala Crossroads featured in Hawaii Home + Remodeling",
        categoryUID: "pva-in-the-news",
        description: "Creating Calm \u2013 A Kahala property is reimagined, evoking a sense of peace through symmetrical design, water elements and fire features.",
        date: "July 2019",
        parentName: "News"
      });
    }
  }]
};
export var query = "2051791442";