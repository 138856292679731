import "core-js/modules/es6.array.fill";
import "core-js/modules/es6.array.map";
import "core-js/modules/es6.array.filter";
import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.array.index-of";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.array.fill";
import "core-js/modules/es6.object.assign";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.array.fill";
import "core-js/modules/es6.object.assign";

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React from 'react';
import { notEmpty, getMetadata, propPairsEq } from 'helpers';
import { camelCase } from 'tiny-compose-fns';
import { Grid } from 'system';
import { BoundedBox, ProjectCard } from 'src/components';
export var PageBodyMixedSizedProjects = function PageBodyMixedSizedProjects(_ref) {
  var children = _ref.children,
      props = _objectWithoutPropertiesLoose(_ref, ["children"]);

  return React.createElement(BoundedBox, Object.assign({
    as: "section"
  }, props), React.createElement(Grid, {
    gridTemplateColumns: ['1fr', 'repeat(2, 1fr)', 'repeat(4, 1fr)'],
    gridGapScale: "l"
  }, children));
};
PageBodyMixedSizedProjects.Project = ProjectCard;

PageBodyMixedSizedProjects.mapDataToProps = function (_ref2) {
  var _data$primary, _data$primary2, _data$primary2$large_, _largeProjectDoc$data, _largeProjectDoc$data2, _largeProjectMetadata, _largeProjectMetadata2, _largeProjectMetadata3, _largeProjectMetadata4, _largeProjectMetadata5, _largeProjectMetadata6, _largeProjectMetadata7, _largeProjectMetadata8, _largeProjectMetadata9, _largeProjectMetadata10, _largeProjectMetadata11, _largeProjectMetadata12, _largeProjectDoc$data3, _largeProjectDoc$data4, _smallProjectDocs$map;

  var data = _ref2.data,
      context = _ref2.context,
      nextContext = _ref2.nextContext;
  var largeProjectSide = notEmpty(camelCase(data === null || data === void 0 ? void 0 : (_data$primary = data.primary) === null || _data$primary === void 0 ? void 0 : _data$primary.large_project_side));
  var largeProjectDoc = data === null || data === void 0 ? void 0 : (_data$primary2 = data.primary) === null || _data$primary2 === void 0 ? void 0 : (_data$primary2$large_ = _data$primary2.large_project_1) === null || _data$primary2$large_ === void 0 ? void 0 : _data$primary2$large_.document;
  var largeProjectMetadata = getMetadata('PrismicPageMainProject', largeProjectDoc);
  var smallProjectDocs = Array(4).fill().map(function (_, i) {
    var _data$primary3, _data$primary3$;

    return data === null || data === void 0 ? void 0 : (_data$primary3 = data.primary) === null || _data$primary3 === void 0 ? void 0 : (_data$primary3$ = _data$primary3["small_project_" + (i + 1)]) === null || _data$primary3$ === void 0 ? void 0 : _data$primary3$.document;
  }).filter(Boolean);
  var projectsCount = smallProjectDocs.length + (largeProjectDoc ? 1 : 0);
  return {
    nextSharesBg: propPairsEq('bg', context, nextContext),
    children: React.createElement(React.Fragment, {
      key: largeProjectDoc === null || largeProjectDoc === void 0 ? void 0 : (_largeProjectDoc$data = largeProjectDoc.data) === null || _largeProjectDoc$data === void 0 ? void 0 : (_largeProjectDoc$data2 = _largeProjectDoc$data.title) === null || _largeProjectDoc$data2 === void 0 ? void 0 : _largeProjectDoc$data2.text
    }, React.createElement(PageBodyMixedSizedProjects.Project, {
      variant: "large",
      categoryUID: largeProjectMetadata === null || largeProjectMetadata === void 0 ? void 0 : (_largeProjectMetadata = largeProjectMetadata.items) === null || _largeProjectMetadata === void 0 ? void 0 : (_largeProjectMetadata2 = _largeProjectMetadata[0]) === null || _largeProjectMetadata2 === void 0 ? void 0 : (_largeProjectMetadata3 = _largeProjectMetadata2.project_category) === null || _largeProjectMetadata3 === void 0 ? void 0 : (_largeProjectMetadata4 = _largeProjectMetadata3.document) === null || _largeProjectMetadata4 === void 0 ? void 0 : _largeProjectMetadata4.uid,
      href: largeProjectDoc === null || largeProjectDoc === void 0 ? void 0 : largeProjectDoc.url,
      imageAlt: notEmpty(largeProjectMetadata === null || largeProjectMetadata === void 0 ? void 0 : (_largeProjectMetadata5 = largeProjectMetadata.primary) === null || _largeProjectMetadata5 === void 0 ? void 0 : (_largeProjectMetadata6 = _largeProjectMetadata5.featured_image) === null || _largeProjectMetadata6 === void 0 ? void 0 : _largeProjectMetadata6.alt),
      imageFluid: largeProjectMetadata === null || largeProjectMetadata === void 0 ? void 0 : (_largeProjectMetadata7 = largeProjectMetadata.primary) === null || _largeProjectMetadata7 === void 0 ? void 0 : (_largeProjectMetadata8 = _largeProjectMetadata7.featured_image) === null || _largeProjectMetadata8 === void 0 ? void 0 : _largeProjectMetadata8.fluid,
      imageURL: largeProjectMetadata === null || largeProjectMetadata === void 0 ? void 0 : (_largeProjectMetadata9 = largeProjectMetadata.primary) === null || _largeProjectMetadata9 === void 0 ? void 0 : (_largeProjectMetadata10 = _largeProjectMetadata9.featured_image) === null || _largeProjectMetadata10 === void 0 ? void 0 : _largeProjectMetadata10.url,
      location: largeProjectMetadata === null || largeProjectMetadata === void 0 ? void 0 : (_largeProjectMetadata11 = largeProjectMetadata.primary) === null || _largeProjectMetadata11 === void 0 ? void 0 : (_largeProjectMetadata12 = _largeProjectMetadata11.location) === null || _largeProjectMetadata12 === void 0 ? void 0 : _largeProjectMetadata12.text,
      name: largeProjectDoc === null || largeProjectDoc === void 0 ? void 0 : (_largeProjectDoc$data3 = largeProjectDoc.data) === null || _largeProjectDoc$data3 === void 0 ? void 0 : (_largeProjectDoc$data4 = _largeProjectDoc$data3.title) === null || _largeProjectDoc$data4 === void 0 ? void 0 : _largeProjectDoc$data4.text,
      gridRow: [largeProjectSide === 'left' ? 1 : projectsCount, largeProjectSide === 'left' ? 1 : projectsCount - 2, '1 / span 2'],
      gridColumn: [null, largeProjectSide === 'left' ? '1 / span 2' : 'span 2 / -1'],
      innerProps: {
        height: '100%'
      }
    }), smallProjectDocs === null || smallProjectDocs === void 0 ? void 0 : (_smallProjectDocs$map = smallProjectDocs.map) === null || _smallProjectDocs$map === void 0 ? void 0 : _smallProjectDocs$map.call(smallProjectDocs, function (projectDoc) {
      var _projectDoc$data, _projectDoc$data$titl, _metadata$items, _metadata$items$, _metadata$items$$proj, _metadata$items$$proj2, _metadata$primary, _metadata$primary$fea, _metadata$primary2, _metadata$primary2$fe, _metadata$primary3, _metadata$primary3$fe, _metadata$primary4, _metadata$primary4$lo, _projectDoc$data2, _projectDoc$data2$tit;

      var metadata = getMetadata('PrismicPageMainProject', projectDoc);
      return React.createElement(PageBodyMixedSizedProjects.Project, {
        variant: "small",
        key: projectDoc === null || projectDoc === void 0 ? void 0 : (_projectDoc$data = projectDoc.data) === null || _projectDoc$data === void 0 ? void 0 : (_projectDoc$data$titl = _projectDoc$data.title) === null || _projectDoc$data$titl === void 0 ? void 0 : _projectDoc$data$titl.text,
        categoryUID: metadata === null || metadata === void 0 ? void 0 : (_metadata$items = metadata.items) === null || _metadata$items === void 0 ? void 0 : (_metadata$items$ = _metadata$items[0]) === null || _metadata$items$ === void 0 ? void 0 : (_metadata$items$$proj = _metadata$items$.project_category) === null || _metadata$items$$proj === void 0 ? void 0 : (_metadata$items$$proj2 = _metadata$items$$proj.document) === null || _metadata$items$$proj2 === void 0 ? void 0 : _metadata$items$$proj2.uid,
        href: projectDoc === null || projectDoc === void 0 ? void 0 : projectDoc.url,
        imageAlt: notEmpty(metadata === null || metadata === void 0 ? void 0 : (_metadata$primary = metadata.primary) === null || _metadata$primary === void 0 ? void 0 : (_metadata$primary$fea = _metadata$primary.featured_image) === null || _metadata$primary$fea === void 0 ? void 0 : _metadata$primary$fea.alt),
        imageFluid: metadata === null || metadata === void 0 ? void 0 : (_metadata$primary2 = metadata.primary) === null || _metadata$primary2 === void 0 ? void 0 : (_metadata$primary2$fe = _metadata$primary2.featured_image) === null || _metadata$primary2$fe === void 0 ? void 0 : _metadata$primary2$fe.fluid,
        imageURL: metadata === null || metadata === void 0 ? void 0 : (_metadata$primary3 = metadata.primary) === null || _metadata$primary3 === void 0 ? void 0 : (_metadata$primary3$fe = _metadata$primary3.featured_image) === null || _metadata$primary3$fe === void 0 ? void 0 : _metadata$primary3$fe.url,
        location: metadata === null || metadata === void 0 ? void 0 : (_metadata$primary4 = metadata.primary) === null || _metadata$primary4 === void 0 ? void 0 : (_metadata$primary4$lo = _metadata$primary4.location) === null || _metadata$primary4$lo === void 0 ? void 0 : _metadata$primary4$lo.text,
        name: projectDoc === null || projectDoc === void 0 ? void 0 : (_projectDoc$data2 = projectDoc.data) === null || _projectDoc$data2 === void 0 ? void 0 : (_projectDoc$data2$tit = _projectDoc$data2.title) === null || _projectDoc$data2$tit === void 0 ? void 0 : _projectDoc$data2$tit.text
      });
    }))
  };
};

PageBodyMixedSizedProjects.mapDataToContext = function () {
  return {
    bg: 'white'
  };
};

export var fragment = "3108420751";
PageBodyMixedSizedProjects.docs = {
  name: 'Mixed Sized Projects',
  examples: [{
    name: 'Large on left',
    render: function render() {
      return React.createElement(PageBodyMixedSizedProjects, null, React.createElement(PageBodyMixedSizedProjects.Project, {
        variant: "large",
        imageURL: "https://pvadev.cdn.prismic.io/pvadev/6bcaca475792334832ae3d835e00524e8c0ddb2c_0-benihana-of-tokyo-at-hilton-hawaiian-village.jpg",
        imageAlt: "Example.",
        location: "Honolulu, Hawaii",
        categoryUID: "residential",
        name: "Flower Avenue",
        href: true,
        gridRow: [1, 1, '1 / span 2'],
        gridColumn: [null, '1 / span 2'],
        innerProps: {
          height: '100%'
        }
      }), React.createElement(PageBodyMixedSizedProjects.Project, {
        variant: "small",
        imageURL: "https://pvadev.cdn.prismic.io/pvadev/62369a1947659de26225f0ba5cbf58c35ed47cb9_0-sushi-izakaya-dining--shinn.jpg",
        imageAlt: "Example.",
        location: "Honolulu, Hawaii",
        categoryUID: "retail-restaurants",
        name: "Sushi Izakaya Dining: Shinn",
        href: true
      }), React.createElement(PageBodyMixedSizedProjects.Project, {
        variant: "small",
        imageURL: "https://pvadev.cdn.prismic.io/pvadev/0beb8c86ebf30eda62f806418c822ef711871eea_0-hale-palekaiko.jpg",
        imageAlt: "Example.",
        location: "Honolulu, Hawaii",
        categoryUID: "residential",
        name: "Hale Palekaiko",
        href: true
      }), React.createElement(PageBodyMixedSizedProjects.Project, {
        variant: "small",
        imageURL: "https://pvadev.cdn.prismic.io/pvadev/dba11a47caa75670e5d319fac411d112d5823ada_0-mokulua-hillside-renovation.jpg",
        imageAlt: "Example.",
        location: "Honolulu, Hawaii",
        categoryUID: "residential",
        name: "Mokulua Hillside Renovation",
        href: true
      }), React.createElement(PageBodyMixedSizedProjects.Project, {
        variant: "small",
        imageURL: "https://pvadev.cdn.prismic.io/pvadev/20309d3ee037fcd91451100fc6a57954af20caad_0-assaggio-ristorante.jpg",
        imageAlt: "Example.",
        location: "Honolulu, Hawaii",
        categoryUID: "retail-restaurants",
        name: "Assaggio Ristorante",
        href: true
      }));
    }
  }, {
    name: 'Large on right',
    render: function render() {
      return React.createElement(PageBodyMixedSizedProjects, null, React.createElement(PageBodyMixedSizedProjects.Project, {
        variant: "large",
        imageURL: "https://pvadev.cdn.prismic.io/pvadev/6bcaca475792334832ae3d835e00524e8c0ddb2c_0-benihana-of-tokyo-at-hilton-hawaiian-village.jpg",
        imageAlt: "Example.",
        location: "Honolulu, Hawaii",
        categoryUID: "residential",
        name: "Flower Avenue",
        href: true,
        gridRow: [1, 1, '1 / span 2'],
        gridColumn: [null, '3 / span 2'],
        innerProps: {
          height: '100%'
        }
      }), React.createElement(PageBodyMixedSizedProjects.Project, {
        variant: "small",
        imageURL: "https://pvadev.cdn.prismic.io/pvadev/62369a1947659de26225f0ba5cbf58c35ed47cb9_0-sushi-izakaya-dining--shinn.jpg",
        imageAlt: "Example.",
        location: "Honolulu, Hawaii",
        categoryUID: "retail-restaurants",
        name: "Sushi Izakaya Dining: Shinn",
        href: true
      }), React.createElement(PageBodyMixedSizedProjects.Project, {
        variant: "small",
        imageURL: "https://pvadev.cdn.prismic.io/pvadev/0beb8c86ebf30eda62f806418c822ef711871eea_0-hale-palekaiko.jpg",
        imageAlt: "Example.",
        location: "Honolulu, Hawaii",
        categoryUID: "residential",
        name: "Hale Palekaiko",
        href: true
      }), React.createElement(PageBodyMixedSizedProjects.Project, {
        variant: "small",
        imageURL: "https://pvadev.cdn.prismic.io/pvadev/dba11a47caa75670e5d319fac411d112d5823ada_0-mokulua-hillside-renovation.jpg",
        imageAlt: "Example.",
        location: "Honolulu, Hawaii",
        categoryUID: "residential",
        name: "Mokulua Hillside Renovation",
        href: true
      }), React.createElement(PageBodyMixedSizedProjects.Project, {
        variant: "small",
        imageURL: "https://pvadev.cdn.prismic.io/pvadev/20309d3ee037fcd91451100fc6a57954af20caad_0-assaggio-ristorante.jpg",
        imageAlt: "Example.",
        location: "Honolulu, Hawaii",
        categoryUID: "retail-restaurants",
        name: "Assaggio Ristorante",
        href: true
      }));
    }
  }]
};