import "core-js/modules/es6.function.name";
import "core-js/modules/es6.string.link";
import "core-js/modules/es6.array.map";
import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.array.index-of";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.function.name";
import "core-js/modules/es6.string.link";
import "core-js/modules/es6.object.assign";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.function.name";
import "core-js/modules/es6.string.link";
import "core-js/modules/es6.object.assign";
import _styled from "styled-components";

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React from 'react';
import ConditionalWrap from 'conditional-wrap';
import { themeGet } from '@styled-system/theme-get';
import { propPairsEq } from 'helpers';
import { Box, Grid, Flex, Link } from 'system';
import { BoundedBox, Heading, NavigationItem, ButtonNavigationItem } from 'src/components';

var _StyledLink = _styled(Link).withConfig({
  displayName: "PageBodyButtonNavigation___StyledLink",
  componentId: "ipwqr3-0"
})(["&:hover,&:focus{color:", ";}"], function (p) {
  return p._css;
});

export var PageBodyButtonNavigation = function PageBodyButtonNavigation(_ref) {
  var children = _ref.children,
      title = _ref.title,
      rootHref = _ref.rootHref,
      rootTarget = _ref.rootTarget,
      buttons = _ref.buttons,
      props = _objectWithoutPropertiesLoose(_ref, ["children", "title", "rootHref", "rootTarget", "buttons"]);

  return React.createElement(BoundedBox, Object.assign({
    as: "section",
    ptScale: "s+",
    pbScale: "m-",
    bg: "gray.90"
  }, props), React.createElement(Grid, {
    gridTemplateColumns: ['1fr', null, '1fr 1fr'],
    alignItems: "end"
  }, React.createElement(Grid, {
    as: "nav",
    gridGapScale: "xl",
    gridTemplateColumns: ['1fr 1fr', null, 'auto 1fr'],
    alignItems: "baseline",
    mbScale: ['l', null, 0]
  }, React.createElement(Heading, {
    textStyle: "lowercase",
    color: "blue.40"
  }, React.createElement(ConditionalWrap, {
    condition: rootHref,
    wrap: function wrap(children) {
      return React.createElement(_StyledLink, {
        href: rootHref,
        target: rootTarget,
        transitionProperty: "color",
        _css: themeGet('colors.blue.40')
      }, children);
    }
  }, title)), React.createElement(Flex, {
    as: "ul",
    flexWrap: "wrap",
    mbScale: "-t-",
    mrScale: [0, '-l']
  }, children)), React.createElement(Box, {
    as: "nav",
    justifySelf: [null, null, 'end'],
    position: "relative",
    ptScale: ['s', null, 0]
  }, React.createElement(Flex, {
    as: "ul",
    position: "relative",
    zIndex: 1,
    justifyContent: "start",
    flexWrap: "wrap"
  }, buttons), React.createElement(Box, {
    display: ['block', null, 'none'],
    position: "absolute",
    topScale: "0",
    bottomScale: "-l+",
    leftScale: "-xl",
    rightScale: "-xl",
    bg: "white",
    zIndex: 0
  }))));
};
PageBodyButtonNavigation.Item = NavigationItem;
PageBodyButtonNavigation.ButtonItem = ButtonNavigationItem;

PageBodyButtonNavigation.mapDataToProps = function (_ref2) {
  var _data$primary, _data$primary$navigat, _data$primary2, _data$primary2$button, _navigationDoc$data, _navigationDoc$data$t, _navigationDoc$data2, _navigationDoc$data2$, _navigationDoc$data3, _navigationDoc$data3$, _buttonNavigationDoc$, _buttonNavigationDoc$2, _buttonNavigationDoc$3, _navigationDoc$data4, _navigationDoc$data4$, _navigationDoc$data4$2;

  var data = _ref2.data,
      context = _ref2.context,
      nextContext = _ref2.nextContext;
  var navigationDoc = data === null || data === void 0 ? void 0 : (_data$primary = data.primary) === null || _data$primary === void 0 ? void 0 : (_data$primary$navigat = _data$primary.navigation) === null || _data$primary$navigat === void 0 ? void 0 : _data$primary$navigat.document;
  var buttonNavigationDoc = data === null || data === void 0 ? void 0 : (_data$primary2 = data.primary) === null || _data$primary2 === void 0 ? void 0 : (_data$primary2$button = _data$primary2.button_navigation) === null || _data$primary2$button === void 0 ? void 0 : _data$primary2$button.document;
  return {
    nextSharesBg: propPairsEq('bg', context, nextContext),
    title: navigationDoc === null || navigationDoc === void 0 ? void 0 : (_navigationDoc$data = navigationDoc.data) === null || _navigationDoc$data === void 0 ? void 0 : (_navigationDoc$data$t = _navigationDoc$data.title) === null || _navigationDoc$data$t === void 0 ? void 0 : _navigationDoc$data$t.text,
    rootHref: navigationDoc === null || navigationDoc === void 0 ? void 0 : (_navigationDoc$data2 = navigationDoc.data) === null || _navigationDoc$data2 === void 0 ? void 0 : (_navigationDoc$data2$ = _navigationDoc$data2.root) === null || _navigationDoc$data2$ === void 0 ? void 0 : _navigationDoc$data2$.url,
    rootTarget: navigationDoc === null || navigationDoc === void 0 ? void 0 : (_navigationDoc$data3 = navigationDoc.data) === null || _navigationDoc$data3 === void 0 ? void 0 : (_navigationDoc$data3$ = _navigationDoc$data3.root) === null || _navigationDoc$data3$ === void 0 ? void 0 : _navigationDoc$data3$.target,
    buttons: buttonNavigationDoc === null || buttonNavigationDoc === void 0 ? void 0 : (_buttonNavigationDoc$ = buttonNavigationDoc.data) === null || _buttonNavigationDoc$ === void 0 ? void 0 : (_buttonNavigationDoc$2 = _buttonNavigationDoc$.primary) === null || _buttonNavigationDoc$2 === void 0 ? void 0 : (_buttonNavigationDoc$3 = _buttonNavigationDoc$2.map) === null || _buttonNavigationDoc$3 === void 0 ? void 0 : _buttonNavigationDoc$3.call(_buttonNavigationDoc$2, function (item) {
      var _item$primary, _item$primary$link, _item$primary2, _item$primary2$link, _item$primary3;

      return React.createElement(PageBodyButtonNavigation.ButtonItem, {
        key: item === null || item === void 0 ? void 0 : item.id,
        href: item === null || item === void 0 ? void 0 : (_item$primary = item.primary) === null || _item$primary === void 0 ? void 0 : (_item$primary$link = _item$primary.link) === null || _item$primary$link === void 0 ? void 0 : _item$primary$link.url,
        target: item === null || item === void 0 ? void 0 : (_item$primary2 = item.primary) === null || _item$primary2 === void 0 ? void 0 : (_item$primary2$link = _item$primary2.link) === null || _item$primary2$link === void 0 ? void 0 : _item$primary2$link.target
      }, item === null || item === void 0 ? void 0 : (_item$primary3 = item.primary) === null || _item$primary3 === void 0 ? void 0 : _item$primary3.name);
    }),
    children: navigationDoc === null || navigationDoc === void 0 ? void 0 : (_navigationDoc$data4 = navigationDoc.data) === null || _navigationDoc$data4 === void 0 ? void 0 : (_navigationDoc$data4$ = _navigationDoc$data4.primary) === null || _navigationDoc$data4$ === void 0 ? void 0 : (_navigationDoc$data4$2 = _navigationDoc$data4$.map) === null || _navigationDoc$data4$2 === void 0 ? void 0 : _navigationDoc$data4$2.call(_navigationDoc$data4$, function (item) {
      var _item$primary4, _item$primary4$link, _item$primary5, _item$primary5$link, _item$primary6;

      return React.createElement(PageBodyButtonNavigation.Item, {
        key: item === null || item === void 0 ? void 0 : item.id,
        href: item === null || item === void 0 ? void 0 : (_item$primary4 = item.primary) === null || _item$primary4 === void 0 ? void 0 : (_item$primary4$link = _item$primary4.link) === null || _item$primary4$link === void 0 ? void 0 : _item$primary4$link.url,
        target: item === null || item === void 0 ? void 0 : (_item$primary5 = item.primary) === null || _item$primary5 === void 0 ? void 0 : (_item$primary5$link = _item$primary5.link) === null || _item$primary5$link === void 0 ? void 0 : _item$primary5$link.target
      }, item === null || item === void 0 ? void 0 : (_item$primary6 = item.primary) === null || _item$primary6 === void 0 ? void 0 : _item$primary6.name);
    })
  };
};

PageBodyButtonNavigation.mapDataToContext = function () {
  return {
    bg: 'gray.90'
  };
};

export var fragment = "1488954620";
PageBodyButtonNavigation.docs = {
  name: 'Button Navigation',
  preset: true,
  examples: [{
    name: 'Button Navigation',
    render: function render() {
      return React.createElement(PageBodyButtonNavigation, {
        title: "contact",
        rootHref: "/",
        nextSharesBg: [true],
        buttons: [React.createElement(PageBodyButtonNavigation.ButtonItem, {
          href: "/",
          key: 0
        }, "English"), React.createElement(PageBodyButtonNavigation.ButtonItem, {
          href: "/",
          key: 1
        }, "\u65E5\u672C\u8A9E"), React.createElement(PageBodyButtonNavigation.ButtonItem, {
          href: "/",
          key: 2
        }, "Mandarin")]
      }, React.createElement(PageBodyButtonNavigation.Item, {
        href: "/"
      }, "Contact"), React.createElement(PageBodyButtonNavigation.Item, {
        href: "/"
      }, "Careers"));
    }
  }]
};