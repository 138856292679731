import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.array.index-of";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React from 'react';
import { camelCase } from 'tiny-compose-fns';
import { propPairsEq, getRichText, getMetadata } from 'helpers';
import { Grid } from 'system';
import { BoundedBox, BrandedCallout, FeaturedNewsCard } from 'src/components';
export var PageBodyHomeAccessories = function PageBodyHomeAccessories(_ref) {
  var calloutImageURL = _ref.calloutImageURL,
      calloutImageAlt = _ref.calloutImageAlt,
      calloutImageFluid = _ref.calloutImageFluid,
      calloutColorScheme = _ref.calloutColorScheme,
      calloutTextHTML = _ref.calloutTextHTML,
      calloutLink = _ref.calloutLink,
      calloutTarget = _ref.calloutTarget,
      calloutButtonText = _ref.calloutButtonText,
      featuredNewsImageURL = _ref.featuredNewsImageURL,
      featuredNewsImageAlt = _ref.featuredNewsImageAlt,
      featuredNewsImageFluid = _ref.featuredNewsImageFluid,
      featuredNewsTitle = _ref.featuredNewsTitle,
      featuredNewsCategoryUID = _ref.featuredNewsCategoryUID,
      featuredNewsDate = _ref.featuredNewsDate,
      featuredNewsHref = _ref.featuredNewsHref,
      props = _objectWithoutPropertiesLoose(_ref, ["calloutImageURL", "calloutImageAlt", "calloutImageFluid", "calloutColorScheme", "calloutTextHTML", "calloutLink", "calloutTarget", "calloutButtonText", "featuredNewsImageURL", "featuredNewsImageAlt", "featuredNewsImageFluid", "featuredNewsTitle", "featuredNewsCategoryUID", "featuredNewsDate", "featuredNewsHref"]);

  return React.createElement(BoundedBox, Object.assign({
    as: "section"
  }, props), React.createElement(Grid, {
    gridTemplateColumns: ['1fr', null, '1fr 1fr'],
    gridColumnGapScale: "m",
    gridRowGapScale: "m"
  }, React.createElement(BrandedCallout, {
    textHTML: calloutTextHTML,
    buttonHref: calloutLink,
    buttonTarget: calloutTarget,
    buttonText: calloutButtonText,
    textWidth: [1, 1, 1, 1, 7 / 8],
    variant: calloutColorScheme,
    backgroundImageFluid: calloutImageFluid,
    backgroundImageAlt: calloutImageAlt,
    backgroundImageUrl: calloutImageURL
  }), React.createElement(FeaturedNewsCard, {
    href: featuredNewsHref,
    imageURL: featuredNewsImageURL,
    imageAlt: featuredNewsImageAlt,
    imageFluid: featuredNewsImageFluid,
    title: featuredNewsTitle,
    categoryUID: featuredNewsCategoryUID,
    date: featuredNewsDate
  })));
};

PageBodyHomeAccessories.mapDataToProps = function (_ref2) {
  var _meta$page, _meta$page$data, _meta$page$data$main, _primary$featured_new, _featuredNewsMetadata, _featuredNewsMetadata2, _primary$callout_back, _primary$callout_back2, _primary$callout_back3, _primary$callout_butt, _primary$callout_butt2, _primary$callout_butt3, _featuredNewsDoc$data, _featuredNewsDoc$data2, _featuredNewsMetadata3, _featuredNewsMetadata4, _featuredNewsMetadata5, _featuredNewsMetadata6, _featuredNewsMetadata7, _featuredNewsMetadata8, _featuredNewsMetadata9;

  var meta = _ref2.meta,
      context = _ref2.context,
      nextContext = _ref2.nextContext;
  var slice = meta === null || meta === void 0 ? void 0 : (_meta$page = meta.page) === null || _meta$page === void 0 ? void 0 : (_meta$page$data = _meta$page.data) === null || _meta$page$data === void 0 ? void 0 : (_meta$page$data$main = _meta$page$data.main) === null || _meta$page$data$main === void 0 ? void 0 : _meta$page$data$main[0];
  var primary = slice === null || slice === void 0 ? void 0 : slice.primary;
  var featuredNewsDoc = primary === null || primary === void 0 ? void 0 : (_primary$featured_new = primary.featured_news) === null || _primary$featured_new === void 0 ? void 0 : _primary$featured_new.document;
  var featuredNewsMetadata = getMetadata('PrismicPageMainNews', featuredNewsDoc);
  var featuredNewsCategoryDoc = featuredNewsMetadata === null || featuredNewsMetadata === void 0 ? void 0 : (_featuredNewsMetadata = featuredNewsMetadata.primary) === null || _featuredNewsMetadata === void 0 ? void 0 : (_featuredNewsMetadata2 = _featuredNewsMetadata.news_category) === null || _featuredNewsMetadata2 === void 0 ? void 0 : _featuredNewsMetadata2.document;
  return {
    nextSharesBg: propPairsEq('bg', context, nextContext),
    calloutColorScheme: camelCase(primary === null || primary === void 0 ? void 0 : primary.callout_color_scheme),
    calloutImageURL: primary === null || primary === void 0 ? void 0 : (_primary$callout_back = primary.callout_background_image) === null || _primary$callout_back === void 0 ? void 0 : _primary$callout_back.url,
    calloutImageAlt: primary === null || primary === void 0 ? void 0 : (_primary$callout_back2 = primary.callout_background_image) === null || _primary$callout_back2 === void 0 ? void 0 : _primary$callout_back2.alt,
    calloutImageFluid: primary === null || primary === void 0 ? void 0 : (_primary$callout_back3 = primary.callout_background_image) === null || _primary$callout_back3 === void 0 ? void 0 : _primary$callout_back3.fluid,
    calloutLink: primary === null || primary === void 0 ? void 0 : (_primary$callout_butt = primary.callout_button_link) === null || _primary$callout_butt === void 0 ? void 0 : _primary$callout_butt.url,
    calloutTarget: primary === null || primary === void 0 ? void 0 : (_primary$callout_butt2 = primary.callout_button_link) === null || _primary$callout_butt2 === void 0 ? void 0 : _primary$callout_butt2.target,
    calloutTextHTML: getRichText(primary === null || primary === void 0 ? void 0 : primary.callout_text),
    calloutButtonText: primary === null || primary === void 0 ? void 0 : (_primary$callout_butt3 = primary.callout_button_text) === null || _primary$callout_butt3 === void 0 ? void 0 : _primary$callout_butt3.text,
    featuredNewsTitle: featuredNewsDoc === null || featuredNewsDoc === void 0 ? void 0 : (_featuredNewsDoc$data = featuredNewsDoc.data) === null || _featuredNewsDoc$data === void 0 ? void 0 : (_featuredNewsDoc$data2 = _featuredNewsDoc$data.title) === null || _featuredNewsDoc$data2 === void 0 ? void 0 : _featuredNewsDoc$data2.text,
    featuredNewsImageURL: featuredNewsMetadata === null || featuredNewsMetadata === void 0 ? void 0 : (_featuredNewsMetadata3 = featuredNewsMetadata.primary) === null || _featuredNewsMetadata3 === void 0 ? void 0 : (_featuredNewsMetadata4 = _featuredNewsMetadata3.featured_image) === null || _featuredNewsMetadata4 === void 0 ? void 0 : _featuredNewsMetadata4.url,
    featuredNewsImageAlt: featuredNewsMetadata === null || featuredNewsMetadata === void 0 ? void 0 : (_featuredNewsMetadata5 = featuredNewsMetadata.primary) === null || _featuredNewsMetadata5 === void 0 ? void 0 : (_featuredNewsMetadata6 = _featuredNewsMetadata5.featured_image) === null || _featuredNewsMetadata6 === void 0 ? void 0 : _featuredNewsMetadata6.alt,
    featuredNewsImageFluid: featuredNewsMetadata === null || featuredNewsMetadata === void 0 ? void 0 : (_featuredNewsMetadata7 = featuredNewsMetadata.primary) === null || _featuredNewsMetadata7 === void 0 ? void 0 : (_featuredNewsMetadata8 = _featuredNewsMetadata7.featured_image) === null || _featuredNewsMetadata8 === void 0 ? void 0 : _featuredNewsMetadata8.fluid,
    featuredNewsHref: featuredNewsDoc === null || featuredNewsDoc === void 0 ? void 0 : featuredNewsDoc.url,
    featuredNewsCategoryUID: featuredNewsCategoryDoc === null || featuredNewsCategoryDoc === void 0 ? void 0 : featuredNewsCategoryDoc.uid,
    featuredNewsDate: featuredNewsMetadata === null || featuredNewsMetadata === void 0 ? void 0 : (_featuredNewsMetadata9 = featuredNewsMetadata.primary) === null || _featuredNewsMetadata9 === void 0 ? void 0 : _featuredNewsMetadata9.date
  };
};

PageBodyHomeAccessories.mapDataToContext = function () {
  return {
    bg: 'white'
  };
};

PageBodyHomeAccessories.docs = {
  name: 'Home Accessories',
  preset: true,
  examples: [{
    name: 'Docs',
    render: function render() {
      return React.createElement(PageBodyHomeAccessories, {
        calloutLink: true,
        calloutTextHTML: "<h1>Over 25 years of experience as one of Hawaii's most highly regarded architecture firms.</h1>",
        calloutButtonText: "See our work",
        featuredNewsTitle: "PVA wins Hawaii Energy Award For Excellence in Energy-Efficiency Design and honorable mention at the AIA Honolulu Awards Gala",
        featuredNewsImageURL: "https://pvadev.cdn.prismic.io/pvadev/3bfcc5a0d7915f76cfa895ea780fa68b8dd8f30c_news-award.jpg",
        featuredNewsCategory: "Company Updates",
        featuredNewsHref: true,
        featuredNewsDate: "September 24th, 2019"
      });
    }
  }]
};
export var query = "3732523014";