import { PageBody404 } from './PageBody404';
import { PageBodyAllSmallProjects } from './PageBodyAllSmallProjects';
import { PageBodyAwards } from './PageBodyAwards';
import { PageBodyBrandedCallout } from './PageBodyBrandedCallout';
import { PageBodyButtonNavigation } from './PageBodyButtonNavigation';
import { PageBodyCalloutWithNews } from './PageBodyCalloutWithNews';
import { PageBodyCareerOpenings } from './PageBodyCareerOpenings';
import { PageBodyFeaturedNewsPost } from './PageBodyFeaturedNewsPost';
import { PageBodyFeaturedProjects } from './PageBodyFeaturedProjects';
import { PageBodyFloatingNavigation } from './PageBodyFloatingNavigation';
import { PageBodyFooter } from './PageBodyFooter';
import { PageBodyHeader } from './PageBodyHeader';
import { PageBodyHomeAccessories } from './PageBodyHomeAccessories';
import { PageBodyHomeHero } from './PageBodyHomeHero';
import { PageBodyHorizontalNavigation } from './PageBodyHorizontalNavigation';
import { PageBodyImageWithText } from './PageBodyImageWithText';
import { PageBodyImages } from './PageBodyImages';
import { PageBodyIntroText } from './PageBodyIntroText';
import { PageBodyLatestNewsPosts } from './PageBodyLatestNewsPosts';
import { PageBodyMap } from './PageBodyMap';
import { PageBodyMixedSizedProjects } from './PageBodyMixedSizedProjects';
import { PageBodyNewsIntro } from './PageBodyNewsIntro';
import { PageBodyPrevNext } from './PageBodyPrevNext';
import { PageBodyProjectCards } from './PageBodyProjectCards';
import { PageBodyProjectIntro } from './PageBodyProjectIntro';
import { PageBodyService } from './PageBodyService';
import { PageBodySmallImage } from './PageBodySmallImage';
import { PageBodySmallProjects } from './PageBodySmallProjects';
import { PageBodyTeamMembers } from './PageBodyTeamMembers';
import { PageBodyText } from './PageBodyText';
import { PageBodySearchBar } from './PageBodySearchBar';
import { PageBodySearchResults } from './PageBodySearchResults'; // 2. Add your slice

export var slicesMap = {
  PageBody404: PageBody404,
  PageBodyAllSmallProjects: PageBodyAllSmallProjects,
  PageBodyAwards: PageBodyAwards,
  PageBodyBrandedCallout: PageBodyBrandedCallout,
  PageBodyButtonNavigation: PageBodyButtonNavigation,
  PageBodyCalloutWithNews: PageBodyCalloutWithNews,
  PageBodyCareerOpenings: PageBodyCareerOpenings,
  PageBodyFeaturedNewsPost: PageBodyFeaturedNewsPost,
  PageBodyFeaturedProjects: PageBodyFeaturedProjects,
  PageBodyFloatingNavigation: PageBodyFloatingNavigation,
  PageBodyFooter: PageBodyFooter,
  PageBodyHeader: PageBodyHeader,
  PageBodyHomeAccessories: PageBodyHomeAccessories,
  PageBodyHomeHero: PageBodyHomeHero,
  PageBodyHorizontalNavigation: PageBodyHorizontalNavigation,
  PageBodyImageWithText: PageBodyImageWithText,
  PageBodyImages: PageBodyImages,
  PageBodyIntroText: PageBodyIntroText,
  PageBodyLatestNewsPosts: PageBodyLatestNewsPosts,
  PageBodyMap: PageBodyMap,
  PageBodyMixedSizedProjects: PageBodyMixedSizedProjects,
  PageBodyNewsIntro: PageBodyNewsIntro,
  PageBodyPrevNext: PageBodyPrevNext,
  PageBodyProjectCards: PageBodyProjectCards,
  PageBodyProjectIntro: PageBodyProjectIntro,
  PageBodyService: PageBodyService,
  PageBodySmallImage: PageBodySmallImage,
  PageBodySmallProjects: PageBodySmallProjects,
  PageBodyTeamMembers: PageBodyTeamMembers,
  PageBodyText: PageBodyText,
  PageBodySearchBar: PageBodySearchBar,
  PageBodySearchResults: PageBodySearchResults
}; // 3. Add your slice fragment

export var fragment = "528388392";