import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.array.index-of";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React from 'react';
import { getRichText, getMetadata } from 'helpers';
import { Grid } from 'system';
import { BoundedBox, HTMLContent } from 'src/components';
export var PageBodyIntroText = function PageBodyIntroText(_ref) {
  var introText = _ref.introText,
      props = _objectWithoutPropertiesLoose(_ref, ["introText"]);

  return React.createElement(BoundedBox, Object.assign({
    as: "section"
  }, props, {
    pyScale: "0",
    position: "relative"
  }), React.createElement(Grid, {
    position: [null, null, null, 'absolute'],
    mtScale: "l",
    gridGapScale: "l",
    gridTemplateColumns: ['1fr', null, 'repeat(8, 1fr)']
  }, React.createElement(HTMLContent, {
    html: introText,
    gridColumn: ['1 / -1', null, '1 / span 6', '1 / span 2'],
    fontSizeScale: "m",
    fontWeight: "light"
  })));
};

PageBodyIntroText.mapDataToProps = function (_ref2) {
  var _metadata$primary, _metadata$primary$nav, _navigationDoc$data, _metadata$primary2;

  var meta = _ref2.meta;
  var page = meta === null || meta === void 0 ? void 0 : meta.page;
  var metadata = getMetadata('PrismicPageMainIntroText', page);
  var navigationDoc = metadata === null || metadata === void 0 ? void 0 : (_metadata$primary = metadata.primary) === null || _metadata$primary === void 0 ? void 0 : (_metadata$primary$nav = _metadata$primary.navigation) === null || _metadata$primary$nav === void 0 ? void 0 : _metadata$primary$nav.document;
  var navItems = navigationDoc === null || navigationDoc === void 0 ? void 0 : (_navigationDoc$data = navigationDoc.data) === null || _navigationDoc$data === void 0 ? void 0 : _navigationDoc$data.primary;
  var introText = getRichText(metadata === null || metadata === void 0 ? void 0 : (_metadata$primary2 = metadata.primary) === null || _metadata$primary2 === void 0 ? void 0 : _metadata$primary2.text);
  return {
    navItems: navItems,
    introText: introText
  };
};

PageBodyIntroText.mapDataToContext = function () {
  return {
    bg: 'white'
  };
};

PageBodyIntroText.docs = {
  name: 'Intro Text',
  description: 'Introductory text at the top of a page.',
  preset: true
};
export var fragment = "985755939";