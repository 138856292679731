import "core-js/modules/es6.array.map";
import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.array.index-of";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React from 'react';
import { getRichText, getPrimaryMetadataType } from 'helpers';
import { Grid, Box } from 'system';
import { BoundedBox, HTMLContent } from 'src/components';
var variants = {
  normal: {
    contentGridColumn: [null, null, '1 / span 6', "3 / -3"]
  },
  wide: {
    contentGridColumn: [null, null, '1 / span 6', "2 / -3"]
  },
  news: {
    contentGridColumn: [null, null, '2 / span 6', "2 / span 6", "3 / span 4"]
  }
};
export var PageBodyText = function PageBodyText(_ref) {
  var _previousIsArticleTyp;

  var children = _ref.children,
      _ref$variant = _ref.variant,
      variantName = _ref$variant === void 0 ? 'normal' : _ref$variant,
      textHTML = _ref.textHTML,
      _ref$previousIsArticl = _ref.previousIsArticleTypeWithSameBackground,
      previousIsArticleTypeWithSameBackground = _ref$previousIsArticl === void 0 ? [false] : _ref$previousIsArticl,
      props = _objectWithoutPropertiesLoose(_ref, ["children", "variant", "textHTML", "previousIsArticleTypeWithSameBackground"]);

  var variant = variants[variantName];
  return React.createElement(BoundedBox, Object.assign({
    as: "section",
    ptScale: previousIsArticleTypeWithSameBackground === null || previousIsArticleTypeWithSameBackground === void 0 ? void 0 : (_previousIsArticleTyp = previousIsArticleTypeWithSameBackground.map) === null || _previousIsArticleTyp === void 0 ? void 0 : _previousIsArticleTyp.call(previousIsArticleTypeWithSameBackground, function (cond) {
      return cond ? 0 : 'l+';
    })
  }, props), React.createElement(Grid, {
    gridTemplateColumns: ['1fr', null, 'repeat(8, 1fr)'],
    gridGapScale: "l"
  }, children || textHTML && React.createElement(HTMLContent, {
    html: textHTML,
    gridColumn: variant === null || variant === void 0 ? void 0 : variant.contentGridColumn,
    componentOverrides: {
      h1: function h1(Comp) {
        return function (props) {
          return React.createElement(React.Fragment, null, React.createElement(Box, {
            boxStyle: "firstLastNoMargin",
            width: "2.5rem",
            borderBottom: "2px solid",
            borderColor: "blue.40",
            mtScale: "xl",
            mbScale: "t"
          }), React.createElement(Comp, Object.assign({
            fontWeight: "bolder",
            fontSizeScale: "b",
            fontFamily: "sans",
            color: "blue.40",
            mbScale: "s"
          }, props)));
        };
      }
    }
  })));
};

PageBodyText.mapDataToProps = function (_ref2) {
  var _data$primary;

  var data = _ref2.data,
      meta = _ref2.meta;
  var primaryMetadataType = getPrimaryMetadataType(meta === null || meta === void 0 ? void 0 : meta.page);
  var variantsMap = {
    PrismicPageMainNews: 'news',
    PrismicPageMainProject: 'wide'
  };
  var variant = variantsMap[primaryMetadataType];
  return {
    // Note: nextSharesBg is purposely left off here to add space below text slices.
    // nextSharesBg: propPairsEq('bg', context, nextContext),
    variant: variant,
    textHTML: getRichText(data === null || data === void 0 ? void 0 : (_data$primary = data.primary) === null || _data$primary === void 0 ? void 0 : _data$primary.text)
  };
};

PageBodyText.mapDataToContext = function () {
  return {
    bg: 'white',
    isArticleType: true
  };
};

export var fragment = "4094728908";
PageBodyText.docs = {
  name: 'Text',
  examples: [{
    name: 'With Heading',
    render: function render() {
      return React.createElement(PageBodyText, {
        textHTML: "<h1>Our History</h1><p>Peter Vincent, FAIA founded Peter Vincent Architects in 1992, and was joined by partners Michael Subiaga in 2007 and Max Guenther in 2009. The firm has steadily grown in size and expertise, and is recognized as one of Hawaii's most highly regarded architecture firms.</p><p>PVA's experience has also expanded globally, with projects stretching from Hawaii and the Pacific Rim to North America, Europe, the Middle East and Asia. Together, we have assembled a team of architects and interior designers that is both collaborative and visionary.</p>"
      });
    }
  }, {
    name: 'Without heading',
    render: function render() {
      return React.createElement(PageBodyText, {
        textHTML: "<p>The original owners\u2019 vision was to create a strong indoor-outdoor relationship while capturing the stunning property views in as many ways possible. The new owners loved the overall design and wanted to build upon it, updating and unifying the various stages of the interiors and renovating areas where necessary.</p>"
      });
    }
  }]
};