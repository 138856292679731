import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.array.index-of";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";
import _styled2 from "styled-components";
import _styled from "styled-components";

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React, { useState, useEffect } from 'react';
import { linearScale } from 'styled-system-scale';
import { themeGet } from '@styled-system/theme-get';
import { isBrowser, propPairsEq } from 'helpers';
import FocusTrap from 'focus-trap-react/dist/focus-trap-react';
import { useKeyPress } from 'src/hooks';
import { Box, Flex, Text, Button, Link } from 'system';
import { BoundedBox, LogoText, HamburgerButton, SlidingNav, Overlay } from 'src/components';

var _StyledFlex = _styled(Flex).withConfig({
  displayName: "PageBodyHeader___StyledFlex",
  componentId: "zwvhpc-0"
})(["&:hover,&:focus{color:", ";}"], function (p) {
  return p._css;
});

var MenuButton = function MenuButton(_ref) {
  var toggleNav = _ref.toggleNav,
      isOpen = _ref.isOpen,
      isTransparent = _ref.isTransparent;
  return React.createElement(_StyledFlex, {
    as: Button,
    position: "absolute",
    topScale: ['l+', null, null, 'm'],
    rightScale: "xl",
    onClick: toggleNav,
    alignItems: "center",
    outline: "0",
    transitionProperty: "color",
    color: "white",
    zIndex: "menuButton",
    _css: themeGet(isTransparent ? 'colors.blue.40' : 'colors.gray.10')
  }, React.createElement(Text, {
    display: ['none', 'block'],
    fontFamily: "sansExtended",
    fontSizeScale: "m",
    fontWeight: "light",
    lineHeight: "solid",
    mrScale: "s",
    transform: "translateY(-9%)"
  }, "menu"), React.createElement(HamburgerButton, {
    as: Box,
    position: "relative",
    isOpen: isOpen,
    color: "currentColor"
  }));
};

var _StyledBox = _styled(Box).withConfig({
  displayName: "PageBodyHeader___StyledBox",
  componentId: "zwvhpc-1"
})({
  pointerEvents: 'none'
});

export var PageBodyHeader = function PageBodyHeader(_ref2) {
  var isTransparent = _ref2.isTransparent,
      props = _objectWithoutPropertiesLoose(_ref2, ["isTransparent"]);

  var _useState = useState(false),
      isOpen = _useState[0],
      setIsOpen = _useState[1];

  var openNav = function openNav() {
    if (isBrowser) window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    setIsOpen(true);
  };

  var closeNav = function closeNav() {
    return setIsOpen(false);
  };

  var toggleNav = isOpen ? closeNav : openNav;
  useKeyPress('Escape', closeNav);
  useEffect(function () {
    var _document;

    if (!isBrowser) return;
    var htmlEl = (_document = document) === null || _document === void 0 ? void 0 : _document.documentElement;
    if (!htmlEl) return;
    htmlEl.style.overflowY = isOpen ? 'hidden' : 'auto';
  }, [isOpen]);
  return React.createElement(FocusTrap, {
    active: isOpen
  }, React.createElement(Box, {
    as: "header"
  }, React.createElement(BoundedBox, Object.assign({
    position: isTransparent ? 'absolute' : 'relative',
    top: 0,
    left: 0,
    right: 0,
    zIndex: "header",
    transitionProperty: "opacity",
    transitionDuration: ".5s",
    bg: isTransparent ? 'transparent' : 'blue.40',
    ptScale: ['l+', null, null, 'm'],
    pbScale: ['l+', null, null, 'm']
  }, props), React.createElement(Box, null, React.createElement(Flex, {
    alignItems: "center",
    justifyContent: "space-between"
  }, React.createElement(Link, {
    href: "/",
    mrScale: "s"
  }, React.createElement(LogoText, {
    colorScheme: isTransparent ? 'blue' : 'light',
    width: linearScale('200px', '400px', {
      count: 5
    })
  }))))), isTransparent && React.createElement(_StyledBox, {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    height: "10rem",
    zIndex: "heroGradient",
    backgroundImage: "linear-gradient(to bottom, rgba(0, 0, 0, .45) 0%, rgba(0, 0, 0, .15) 50%, rgba(0, 0, 0, 0) 100%)"
  }), React.createElement(Overlay, {
    isActive: isOpen,
    onClick: closeNav
  }), React.createElement(SlidingNav, {
    isActive: isOpen,
    openNav: openNav,
    closeNav: closeNav
  }), React.createElement(MenuButton, {
    toggleNav: toggleNav,
    isOpen: isOpen,
    isTransparent: isTransparent
  })));
};

PageBodyHeader.mapDataToProps = function (_ref3) {
  var context = _ref3.context,
      nextContext = _ref3.nextContext;
  return {
    nextSharesBg: propPairsEq('bg', context, nextContext),
    isTransparent: nextContext === null || nextContext === void 0 ? void 0 : nextContext.pullsDownHeader
  };
};

PageBodyHeader.mapDataToContext = function () {
  return {
    bg: ['blue.40']
  };
};