import "core-js/modules/es6.array.map";
import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.array.index-of";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React from 'react';
import { linearScale } from 'styled-system-scale';
import { getRichText } from 'helpers';
import { Grid, Box, Text } from 'system';
import { BoundedBox, HTMLContent, Map } from 'src/components'; // The placeholder prop only exists due to an issue with rendering
// mapbox maps inside of iFrames for our documentation

export var PageBodyMap = function PageBodyMap(_ref) {
  var address = _ref.address,
      contactInformation = _ref.contactInformation,
      footnote = _ref.footnote,
      mapUID = _ref.mapUID,
      _ref$withPlaceholderM = _ref.withPlaceholderMap,
      withPlaceholderMap = _ref$withPlaceholderM === void 0 ? false : _ref$withPlaceholderM,
      props = _objectWithoutPropertiesLoose(_ref, ["address", "contactInformation", "footnote", "mapUID", "withPlaceholderMap"]);

  return React.createElement(BoundedBox, Object.assign({
    as: "section"
  }, props), React.createElement(Grid, {
    gridGapScale: "l",
    gridTemplateColumns: ['1fr', null, 'repeat(8, 1fr)'],
    gridAutoFlow: "row dense"
  }, React.createElement(Box, {
    gridColumn: ['1 / -1', null, null, '1 / span 2']
  }, React.createElement(HTMLContent, {
    mbScale: "s",
    html: address,
    fontSizeScale: "m",
    fontWeight: "light"
  }), React.createElement(HTMLContent, {
    mbScale: "m",
    html: contactInformation,
    fontSizeScale: "m",
    fontWeight: "light"
  }), React.createElement(HTMLContent, {
    html: footnote,
    componentOverrides: {
      h1: function h1() {
        return function (props) {
          return React.createElement(Text, Object.assign({
            as: "h3",
            fontWeight: "bold"
          }, props));
        };
      }
    }
  })), React.createElement(Box, {
    gridColumn: ['1 / -1', null, null, '3 / span 6'],
    height: withPlaceholderMap ? '700px' : '70vh',
    maxHeight: linearScale('30rem', '70rem', {
      count: 5
    }),
    position: "relative"
  }, !withPlaceholderMap ? React.createElement(Map, {
    uid: mapUID
  }) : React.createElement(Grid, {
    height: "100%",
    bg: "gray.70"
  }))));
};

PageBodyMap.mapDataToProps = function (_ref2) {
  var _data$primary, _data$primary2, _data$primary3, _data$primary4, _data$primary4$map, _data$primary4$map$do;

  var data = _ref2.data;
  return {
    address: getRichText(data === null || data === void 0 ? void 0 : (_data$primary = data.primary) === null || _data$primary === void 0 ? void 0 : _data$primary.address),
    contactInformation: getRichText(data === null || data === void 0 ? void 0 : (_data$primary2 = data.primary) === null || _data$primary2 === void 0 ? void 0 : _data$primary2.contact_information),
    footnote: getRichText(data === null || data === void 0 ? void 0 : (_data$primary3 = data.primary) === null || _data$primary3 === void 0 ? void 0 : _data$primary3.footnote),
    mapUID: data === null || data === void 0 ? void 0 : (_data$primary4 = data.primary) === null || _data$primary4 === void 0 ? void 0 : (_data$primary4$map = _data$primary4.map) === null || _data$primary4$map === void 0 ? void 0 : (_data$primary4$map$do = _data$primary4$map.document) === null || _data$primary4$map$do === void 0 ? void 0 : _data$primary4$map$do.uid
  };
};

PageBodyMap.mapDataToContext = function () {
  return {
    bg: 'white'
  };
};

PageBodyMap.docs = {
  name: 'Map',
  examples: [{
    name: 'Map',
    render: function render() {
      return React.createElement(PageBodyMap, {
        withPlaceholderMap: true,
        address: "\n            <p>\n              <strong>Peter Vincent Architects</strong></br>\n              1021 Smith Street, Penthouse</br>\n              Honolulu, Hawaii 96817\n            </p>\n          ",
        contactInformation: "\n            <p>\n              <strong>T </strong><a>808.524.8255</a></br>\n              <strong>F </strong><a>808.523.3419</a></br>\n              <strong>E </strong><a>info@pva.com</a></br>\n            </p>\n          ",
        footnote: "\n            <h1>Parking</h1>\n            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce dolor orci, suscipit vitae nulla nec, ultrices vestibulum ligula. Mauris et ipsum id purus mattis tempus quis sit amet turpis.</p>\n            <h1>Press Inquiries</h1>\n            <p>For more information about the firm or specific projects, please contact Cathy Bachl at <a>marketing@pva.com</a>.</p>\n          "
      });
    }
  }]
};
export var fragment = "1520163293";