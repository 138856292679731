import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.array.index-of";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";
import _styled from "styled-components";

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React, { useState } from 'react';
import { a } from 'react-spring';
import { useGesture } from 'react-use-gesture';
import { getMetadata, propPairsEq } from 'helpers';
import { useNewsCategoriesData } from 'src/hooks';
import { Flex, Grid, ImageContainer, Image, AspectRatio, Text, Link } from 'system';
import { BoundedBox, Heading, Subheading, Button } from 'src/components';

var _StyledLink = _styled(Link).withConfig({
  displayName: "PageBodyFeaturedNewsPost___StyledLink",
  componentId: "sc-18hhe1a-0"
})(["", ""], function (p) {
  return {
    '&:hover, &:focus': {
      color: p.theme.colors.blue[40]
    }
  };
});

export var PageBodyFeaturedNewsPost = function PageBodyFeaturedNewsPost(_ref) {
  var _category$data, _category$data$title;

  var title = _ref.title,
      description = _ref.description,
      imageURL = _ref.imageURL,
      imageAlt = _ref.imageAlt,
      imageFluid = _ref.imageFluid,
      href = _ref.href,
      categoryUID = _ref.categoryUID,
      date = _ref.date,
      props = _objectWithoutPropertiesLoose(_ref, ["title", "description", "imageURL", "imageAlt", "imageFluid", "href", "categoryUID", "date"]);

  var _useState = useState(false),
      isFocused = _useState[0],
      setIsFocused = _useState[1];

  var handleFocus = function handleFocus(isFocused) {
    return setIsFocused(function () {
      return Boolean(isFocused);
    });
  };

  var bind = useGesture({
    onHover: function onHover(_ref2) {
      var hovering = _ref2.hovering;
      return handleFocus(hovering);
    },
    onDrag: function onDrag(_ref3) {
      var down = _ref3.down;
      return handleFocus(down);
    }
  });
  var category = useNewsCategoriesData(categoryUID);
  return React.createElement(BoundedBox, Object.assign({
    as: "section"
  }, props), React.createElement(Grid, {
    gridTemplateColumns: ['1fr', null, 'repeat(2, 1fr)'],
    gridGapScale: "s"
  }, React.createElement(Link, Object.assign({
    href: href,
    outline: "0",
    gridColumn: [null, null, '1'],
    tabIndex: "-1"
  }, bind()), React.createElement(ImageContainer, {
    height: "100%",
    bg: "gray.10"
  }, React.createElement(AspectRatio, {
    x: 16,
    y: 9,
    height: "100%"
  }, (imageFluid || imageURL) && React.createElement(Image, {
    fluid: imageFluid,
    src: imageURL,
    alt: imageAlt
  })))), React.createElement(Flex, {
    flexDirection: "column",
    gridColumn: [null, null, '2'],
    pScale: [0, null, 'l']
  }, category && React.createElement(Subheading, {
    color: "green.30",
    mbScale: "m-"
  }, React.createElement(_StyledLink, {
    href: category === null || category === void 0 ? void 0 : category.url,
    transitionProperty: "color"
  }, category === null || category === void 0 ? void 0 : (_category$data = category.data) === null || _category$data === void 0 ? void 0 : (_category$data$title = _category$data.title) === null || _category$data$title === void 0 ? void 0 : _category$data$title.text)), title && React.createElement(Heading, {
    fontWeight: ['light', null, 'thin'],
    mbScale: "m-"
  }, React.createElement(Link, Object.assign({
    href: href,
    onBlur: function onBlur() {
      return handleFocus(false);
    },
    onFocus: function onFocus() {
      return handleFocus(true);
    },
    outline: 0,
    color: isFocused ? 'blue.40' : 'inherit',
    transitionProperty: "color"
  }, bind()), title)), date && React.createElement(Text, {
    as: "p",
    mbScale: "m-"
  }, date), description && React.createElement(Text, {
    as: "p",
    fontSizeScale: "m",
    fontWeight: "light"
  }, description), React.createElement(Flex, {
    flexDirection: "column",
    flexGrow: "1",
    alignItems: "flex-start",
    justifyContent: "flex-end",
    mtScale: "xxl"
  }, React.createElement(Button, {
    as: a(Link),
    href: href
  }, "Read more")))));
};

PageBodyFeaturedNewsPost.mapDataToProps = function (_ref4) {
  var _data$primary, _data$primary$news_po, _metadata$primary, _metadata$primary$new, _doc$data, _doc$data$title, _metadata$primary2, _metadata$primary2$de, _metadata$primary3, _metadata$primary3$fe, _metadata$primary4, _metadata$primary4$fe, _metadata$primary5, _metadata$primary5$fe, _metadata$primary6;

  var data = _ref4.data,
      context = _ref4.context,
      nextContext = _ref4.nextContext;
  var doc = data === null || data === void 0 ? void 0 : (_data$primary = data.primary) === null || _data$primary === void 0 ? void 0 : (_data$primary$news_po = _data$primary.news_post) === null || _data$primary$news_po === void 0 ? void 0 : _data$primary$news_po.document;
  var metadata = getMetadata('PrismicPageMainNews', doc);
  var categoryDoc = metadata === null || metadata === void 0 ? void 0 : (_metadata$primary = metadata.primary) === null || _metadata$primary === void 0 ? void 0 : (_metadata$primary$new = _metadata$primary.news_category) === null || _metadata$primary$new === void 0 ? void 0 : _metadata$primary$new.document;
  return {
    nextSharesBg: propPairsEq('bg', context, nextContext),
    title: doc === null || doc === void 0 ? void 0 : (_doc$data = doc.data) === null || _doc$data === void 0 ? void 0 : (_doc$data$title = _doc$data.title) === null || _doc$data$title === void 0 ? void 0 : _doc$data$title.text,
    description: metadata === null || metadata === void 0 ? void 0 : (_metadata$primary2 = metadata.primary) === null || _metadata$primary2 === void 0 ? void 0 : (_metadata$primary2$de = _metadata$primary2.description) === null || _metadata$primary2$de === void 0 ? void 0 : _metadata$primary2$de.text,
    imageURL: metadata === null || metadata === void 0 ? void 0 : (_metadata$primary3 = metadata.primary) === null || _metadata$primary3 === void 0 ? void 0 : (_metadata$primary3$fe = _metadata$primary3.featured_image) === null || _metadata$primary3$fe === void 0 ? void 0 : _metadata$primary3$fe.url,
    imageAlt: metadata === null || metadata === void 0 ? void 0 : (_metadata$primary4 = metadata.primary) === null || _metadata$primary4 === void 0 ? void 0 : (_metadata$primary4$fe = _metadata$primary4.featured_image) === null || _metadata$primary4$fe === void 0 ? void 0 : _metadata$primary4$fe.alt,
    imageFluid: metadata === null || metadata === void 0 ? void 0 : (_metadata$primary5 = metadata.primary) === null || _metadata$primary5 === void 0 ? void 0 : (_metadata$primary5$fe = _metadata$primary5.featured_image) === null || _metadata$primary5$fe === void 0 ? void 0 : _metadata$primary5$fe.fluid,
    href: doc === null || doc === void 0 ? void 0 : doc.url,
    categoryUID: categoryDoc === null || categoryDoc === void 0 ? void 0 : categoryDoc.uid,
    date: metadata === null || metadata === void 0 ? void 0 : (_metadata$primary6 = metadata.primary) === null || _metadata$primary6 === void 0 ? void 0 : _metadata$primary6.date
  };
};

PageBodyFeaturedNewsPost.mapDataToContext = function () {
  return {
    bg: 'white'
  };
};

PageBodyFeaturedNewsPost.docs = {
  name: 'Featured News Post',
  description: 'News post with a large image and introduction to the article.',
  examples: [{
    name: 'Default',
    render: function render() {
      return React.createElement(PageBodyFeaturedNewsPost, {
        title: "Kahala Crossings featured in Hawaii Home + Remodeling",
        description: "Creating Calm \u2013 A Kahala property is reimagined, evoking a sense of peace through symmetrical design, water elements and fire features.",
        imageURL: "https://images.prismic.io/pvadev/623baaf1d0ee103ec93dae80dcb49cc51fc104db_0-kahala-crossing.jpg?auto=compress,format",
        href: true,
        categoryUID: "pva-in-the-news",
        date: "July 2019"
      });
    }
  }]
};
export var fragment = "865409203";