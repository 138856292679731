import "core-js/modules/es6.array.filter";
import "core-js/modules/es6.function.name";
import "core-js/modules/es6.array.map";
import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.array.index-of";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.function.name";
import "core-js/modules/es6.object.assign";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.function.name";
import "core-js/modules/es6.object.assign";

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React from 'react';
import { propPairsEq } from 'helpers';
import { Box, Grid } from 'system';
import { BoundedBox, Heading, TeamMemberCard } from 'src/components';
export var PageBodyTeamMembers = function PageBodyTeamMembers(_ref) {
  var heading = _ref.heading,
      children = _ref.children,
      withDivider = _ref.withDivider,
      props = _objectWithoutPropertiesLoose(_ref, ["heading", "children", "withDivider"]);

  var childCount = React.Children.count(children);
  return React.createElement(BoundedBox, Object.assign({
    as: "section"
  }, props), React.createElement(Grid, {
    gridTemplateColumns: ['1fr', 'repeat(8, 1fr)'],
    gridGapScale: "l"
  }, heading && React.createElement(Heading, {
    textStyle: "lowercase",
    gridColumn: ['1 / -1', null, '1 / span 2'],
    gridRow: [null, null, "1 / " + (Math.ceil(childCount / 3) + 1)]
  }, heading), React.Children.map(children, function (child) {
    return child && React.cloneElement(child, {
      gridColumn: ['1 / -1', 'span 4', 'span 2']
    });
  })), withDivider && React.createElement(Box, {
    display: ['none', null, 'block'],
    height: "1px",
    bg: "gray.10",
    mtScale: "xxl"
  }));
};
PageBodyTeamMembers.TeamMember = TeamMemberCard;

PageBodyTeamMembers.mapDataToProps = function (_ref2) {
  var _data$primary, _data$primary$heading, _data$items, _data$items$map;

  var data = _ref2.data,
      nextType = _ref2.nextType,
      context = _ref2.context,
      nextContext = _ref2.nextContext;
  return {
    nextSharesBg: propPairsEq('bg', context, nextContext),
    withDivider: nextType === 'PageBodyTeamMembers',
    heading: data === null || data === void 0 ? void 0 : (_data$primary = data.primary) === null || _data$primary === void 0 ? void 0 : (_data$primary$heading = _data$primary.heading) === null || _data$primary$heading === void 0 ? void 0 : _data$primary$heading.text,
    children: data === null || data === void 0 ? void 0 : (_data$items = data.items) === null || _data$items === void 0 ? void 0 : (_data$items$map = _data$items.map) === null || _data$items$map === void 0 ? void 0 : _data$items$map.call(_data$items, function (item) {
      var _item$team_member, _memberDoc$data, _memberDoc$data$name, _memberDoc$data2, _memberDoc$data2$posi, _memberDoc$data3, _memberDoc$data3$head, _memberDoc$data4, _memberDoc$data4$head, _memberDoc$data5, _memberDoc$data5$head, _memberDoc$data6, _memberDoc$data6$cert, _memberDoc$data6$cert2, _memberDoc$data6$cert3, _memberDoc$data6$cert4, _memberDoc$data7, _memberDoc$data7$pers;

      var memberDoc = item === null || item === void 0 ? void 0 : (_item$team_member = item.team_member) === null || _item$team_member === void 0 ? void 0 : _item$team_member.document;
      return React.createElement(PageBodyTeamMembers.TeamMember, {
        name: memberDoc === null || memberDoc === void 0 ? void 0 : (_memberDoc$data = memberDoc.data) === null || _memberDoc$data === void 0 ? void 0 : (_memberDoc$data$name = _memberDoc$data.name) === null || _memberDoc$data$name === void 0 ? void 0 : _memberDoc$data$name.text,
        title: memberDoc === null || memberDoc === void 0 ? void 0 : (_memberDoc$data2 = memberDoc.data) === null || _memberDoc$data2 === void 0 ? void 0 : (_memberDoc$data2$posi = _memberDoc$data2.position_title) === null || _memberDoc$data2$posi === void 0 ? void 0 : _memberDoc$data2$posi.text,
        imageFluid: memberDoc === null || memberDoc === void 0 ? void 0 : (_memberDoc$data3 = memberDoc.data) === null || _memberDoc$data3 === void 0 ? void 0 : (_memberDoc$data3$head = _memberDoc$data3.headshot) === null || _memberDoc$data3$head === void 0 ? void 0 : _memberDoc$data3$head.fluid,
        imageAlt: memberDoc === null || memberDoc === void 0 ? void 0 : (_memberDoc$data4 = memberDoc.data) === null || _memberDoc$data4 === void 0 ? void 0 : (_memberDoc$data4$head = _memberDoc$data4.headshot) === null || _memberDoc$data4$head === void 0 ? void 0 : _memberDoc$data4$head.alt,
        imageURL: memberDoc === null || memberDoc === void 0 ? void 0 : (_memberDoc$data5 = memberDoc.data) === null || _memberDoc$data5 === void 0 ? void 0 : (_memberDoc$data5$head = _memberDoc$data5.headshot) === null || _memberDoc$data5$head === void 0 ? void 0 : _memberDoc$data5$head.url,
        certifications: memberDoc === null || memberDoc === void 0 ? void 0 : (_memberDoc$data6 = memberDoc.data) === null || _memberDoc$data6 === void 0 ? void 0 : (_memberDoc$data6$cert = _memberDoc$data6.certifications) === null || _memberDoc$data6$cert === void 0 ? void 0 : (_memberDoc$data6$cert2 = _memberDoc$data6$cert.map) === null || _memberDoc$data6$cert2 === void 0 ? void 0 : (_memberDoc$data6$cert3 = _memberDoc$data6$cert2.call(_memberDoc$data6$cert, function (x) {
          var _x$certification_name;

          return x === null || x === void 0 ? void 0 : (_x$certification_name = x.certification_name) === null || _x$certification_name === void 0 ? void 0 : _x$certification_name.text;
        })) === null || _memberDoc$data6$cert3 === void 0 ? void 0 : (_memberDoc$data6$cert4 = _memberDoc$data6$cert3.filter) === null || _memberDoc$data6$cert4 === void 0 ? void 0 : _memberDoc$data6$cert4.call(_memberDoc$data6$cert3, Boolean),
        tweet: memberDoc === null || memberDoc === void 0 ? void 0 : (_memberDoc$data7 = memberDoc.data) === null || _memberDoc$data7 === void 0 ? void 0 : (_memberDoc$data7$pers = _memberDoc$data7.personal_tweet) === null || _memberDoc$data7$pers === void 0 ? void 0 : _memberDoc$data7$pers.text
      });
    })
  };
};

PageBodyTeamMembers.mapDataToContext = function () {
  return {
    bg: 'white'
  };
};

PageBodyTeamMembers.docs = {
  name: 'Team Members',
  examples: [{
    name: 'Several Members',
    render: function render() {
      return React.createElement(PageBodyTeamMembers, {
        heading: "leadership"
      }, React.createElement(PageBodyTeamMembers.TeamMember, {
        name: "Peter N. Vincent",
        title: "Managing Partner",
        imageURL: "https://images.prismic.io/pvadev/d5391f4a-cd83-4a3a-a213-ee38023a643d_2000px_Peter-389A0353.jpg?auto=compress,format",
        certifications: ['FAIA', 'NCARB'],
        tweet: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi euismod odio sit amet sapien gravida auctor."
      }), React.createElement(PageBodyTeamMembers.TeamMember, {
        name: "Michael Subiaga",
        title: "Partner",
        imageURL: "https://images.prismic.io/pvadev/6e1dc21c-82ba-4947-9856-cc0ab9c625f1_2000px_Mike-389A0392.jpg?auto=compress,format",
        certifications: ['FAIA', 'NCARB'],
        tweet: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi euismod odio sit amet sapien gravida auctor."
      }), React.createElement(PageBodyTeamMembers.TeamMember, {
        name: "Brad Ladwig",
        title: "Senior Associate / Project Designer",
        imageURL: "https://images.prismic.io/pvadev/4e8490b6-a9ea-4830-b9d4-bdb9ac2db5b8_2000px_Brad-389A0072.jpg?auto=compress,format",
        certifications: ['AIA'],
        tweet: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi euismod odio sit amet sapien gravida auctor."
      }), React.createElement(PageBodyTeamMembers.TeamMember, {
        name: "Sherri Smith",
        title: "Associate / Design Technology Manager",
        imageURL: "https://images.prismic.io/pvadev/15c8b616-4548-46ac-86a2-76deeae1b785_2000px_Sherri-389A0149.jpg?auto=compress,format",
        certifications: ['AIA'],
        tweet: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi euismod odio sit amet sapien gravida auctor."
      }), React.createElement(PageBodyTeamMembers.TeamMember, {
        name: "Minatsu \"Mitz\" Toguchi",
        title: "Associate / Senior Interio Designer",
        imageURL: "https://images.prismic.io/pvadev/14ab68f0-7cfc-468d-a0ef-24d8903fcfc7_2000px_Mitz-389A0207.jpg?auto=compress,format",
        certifications: ['IIDA'],
        tweet: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi euismod odio sit amet sapien gravida auctor."
      }), React.createElement(PageBodyTeamMembers.TeamMember, {
        name: "Todd Hassler",
        title: "Senior Associate / Project Architect",
        imageURL: "https://images.prismic.io/pvadev/b3ce3fae-ca9f-42e0-9b95-40e54306bac0_2000px_Todd-389A0113.jpg?auto=compress,format",
        certifications: ['FAIA', 'NCARB', 'LEED AP'],
        tweet: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi euismod odio sit amet sapien gravida auctor."
      }));
    }
  }, {
    name: 'Few members',
    render: function render() {
      return React.createElement(PageBodyTeamMembers, {
        heading: "team"
      }, React.createElement(PageBodyTeamMembers.TeamMember, {
        name: "Chris",
        title: "Job Title",
        imageURL: "https://images.prismic.io/pvadev/419580b7-72ea-4ae1-be2e-01537d4c6597_2000px_Chris-389A0422.jpg?auto=compress,format",
        tweet: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi euismod odio sit amet sapien gravida auctor."
      }), React.createElement(PageBodyTeamMembers.TeamMember, {
        name: "James",
        title: "Job Title",
        imageURL: "https://images.prismic.io/pvadev/624a3cd3-904a-4f68-8c7c-803677ce149b_2000px_James-389A0184.jpg?auto=compress,format",
        tweet: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi euismod odio sit amet sapien gravida auctor."
      }), React.createElement(PageBodyTeamMembers.TeamMember, {
        name: "Lisa",
        title: "Job Title",
        imageURL: "https://images.prismic.io/pvadev/3869423d-c0d2-44b1-8d04-637c87219f75_2000px_Lisa-389A0022.jpg?auto=compress,format",
        tweet: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi euismod odio sit amet sapien gravida auctor."
      }));
    }
  }]
};
export var fragment = "157753998";