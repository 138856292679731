import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.array.index-of";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React from 'react';
import { notEmpty, getPrimaryMetadataType, propPairsEq } from 'helpers';
import { Grid, ImageContainer, Image } from 'system';
import { BoundedBox } from 'src/components';
var variants = {
  normal: {
    contentGridColumn: [null, null, '1 / -1', "3 / -2"]
  },
  wide: {
    contentGridColumn: [null, null, '1 / -1', "2 / -2"]
  },
  news: {
    contentGridColumn: [null, null, '2 / span 6', "2 / span 6", "3 / span 4"]
  }
};
export var PageBodySmallImage = function PageBodySmallImage(_ref) {
  var _ref$variant = _ref.variant,
      variantName = _ref$variant === void 0 ? 'normal' : _ref$variant,
      imageURL = _ref.imageURL,
      imageAlt = _ref.imageAlt,
      imageFluid = _ref.imageFluid,
      props = _objectWithoutPropertiesLoose(_ref, ["variant", "imageURL", "imageAlt", "imageFluid"]);

  var variant = variants[variantName];
  return React.createElement(BoundedBox, Object.assign({
    as: "section"
  }, props), React.createElement(Grid, {
    gridTemplateColumns: ['1fr', null, 'repeat(8, 1fr)'],
    gridGapScale: "l"
  }, (imageURL || imageFluid) && React.createElement(ImageContainer, {
    gridColumn: variant === null || variant === void 0 ? void 0 : variant.contentGridColumn,
    bg: "gray.10"
  }, React.createElement(Image, {
    src: imageURL,
    alt: imageAlt,
    fluid: imageFluid,
    height: "auto"
  }))));
};

PageBodySmallImage.mapDataToProps = function (_ref2) {
  var _data$primary, _data$primary$image, _data$primary2, _data$primary2$image, _data$primary3, _data$primary3$image;

  var data = _ref2.data,
      meta = _ref2.meta,
      context = _ref2.context,
      nextContext = _ref2.nextContext;
  var primaryMetadataType = getPrimaryMetadataType(meta === null || meta === void 0 ? void 0 : meta.page);
  var variantsMap = {
    PrismicPageMainNews: 'news',
    PrismicPageMainProject: 'wide'
  };
  var variant = variantsMap[primaryMetadataType];
  return {
    nextSharesBg: propPairsEq('bg', context, nextContext),
    variant: variant,
    imageURL: data === null || data === void 0 ? void 0 : (_data$primary = data.primary) === null || _data$primary === void 0 ? void 0 : (_data$primary$image = _data$primary.image) === null || _data$primary$image === void 0 ? void 0 : _data$primary$image.url,
    imageAlt: notEmpty(data === null || data === void 0 ? void 0 : (_data$primary2 = data.primary) === null || _data$primary2 === void 0 ? void 0 : (_data$primary2$image = _data$primary2.image) === null || _data$primary2$image === void 0 ? void 0 : _data$primary2$image.alt),
    imageFluid: data === null || data === void 0 ? void 0 : (_data$primary3 = data.primary) === null || _data$primary3 === void 0 ? void 0 : (_data$primary3$image = _data$primary3.image) === null || _data$primary3$image === void 0 ? void 0 : _data$primary3$image.fluid
  };
};

PageBodySmallImage.mapDataToContext = function () {
  return {
    bg: 'white'
  };
};

PageBodySmallImage.docs = {
  name: 'Small Image',
  examples: [{
    name: 'Normal',
    render: function render() {
      return React.createElement(PageBodySmallImage, {
        variant: "normal",
        imageURL: "https://pvadev.cdn.prismic.io/pvadev/5be1c5dd0bfc0884cc77231eb172416811c248f0_pva_wins_aia_awards.jpg"
      });
    }
  }, {
    name: 'Wide',
    render: function render() {
      return React.createElement(PageBodySmallImage, {
        variant: "wide",
        imageURL: "https://pvadev.cdn.prismic.io/pvadev/5be1c5dd0bfc0884cc77231eb172416811c248f0_pva_wins_aia_awards.jpg"
      });
    }
  }, {
    name: 'News',
    render: function render() {
      return React.createElement(PageBodySmallImage, {
        variant: "news",
        imageURL: "https://pvadev.cdn.prismic.io/pvadev/5be1c5dd0bfc0884cc77231eb172416811c248f0_pva_wins_aia_awards.jpg"
      });
    }
  }]
};
export var fragment = "3445269203";